<template>
  <div class="row">
    <div :class="isOnlyInfluencer ? 'col-sm-12' : 'col-sm-6'">
      <AccountGlobalCard :coach="coach" />
    </div>
    <div v-if="!isOnlyInfluencer" class="col-sm-6">
      <AccountStatsCard :coach="coach" />

      <AccountGeolocCard :coach="coach" />
    </div>
  </div>
</template>

<script>
import AccountGlobalCard from "../components/AccountGlobalCard";
import AccountStatsCard from "../components/AccountStatsCard";
import AccountGeolocCard from "../components/AccoutGeolocCard";

import CoachAccount from "../mixin/CoachAccount";

export default {
  components: {
    AccountGlobalCard,
    AccountStatsCard,
    AccountGeolocCard,
  },

  mixins: [CoachAccount],

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
};
</script>
