<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>

    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-4" v-if="period">
          <label>Début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-4" v-if="period">
          <label>Fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group col-sm-4">
          <label>Société</label>
          <v-select
            :options="merchantOptions"
            v-model="form.merchant"
          ></v-select>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <button type="button" class="btn btn-sm btn-secondary" @click="load()">
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    vSelect,
    Datepicker,
  },

  props: {
    period: {
      type: Boolean,
      default: false,
    },

    default: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      form: {
        merchant: null,
        begin: null,
        end: null,
      },
      merchantOptions: [],
    };
  },

  created() {
    this.getMerchants();

    if (this.default) {
      this.form = this.default;
    }
  },

  methods: {
    load() {
      this.$emit("load", this.form);
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },
  },
};
</script>
