<template>
  <div class="card card-inverse card-info">
    <div class="card-header" v-if="header">Geolocalisation</div>
    <div>
      <div id="geocoding-map" class="map" v-if="geoloc">
        <gmap-map
          :center="geoloc"
          :zoom="zoom"
          style="width: 100%; height: 350px"
        >
          <gmap-marker
            v-for="(m, index) in markers"
            :key="index"
            :position="m.position"
            :clickable="true"
            @click="center = m.position"
          ></gmap-marker>

          <gmap-circle
            v-if="range"
            :radius="range * 1000"
            :center="geoloc"
          ></gmap-circle>
        </gmap-map>
      </div>
    </div>
    <gmap-autocomplete
      v-if="displayAddress"
      id="geocoding-address"
      class="form-control"
      :placeholder="placeholder"
      :value="address"
      :componentRestrictions="geocodeRestrictions"
      :selectFirstOnEnter="true"
      @place_changed="placeChangedHandler"
      :disabled="disabled"
    >
    </gmap-autocomplete>
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBwheKRqS2Bm2c2dsbBl0KUX1f4CdybKgk",
    libraries: "places",
  },
  installComponents: true,
});

export default {
  props: {
    header: {
      type: Boolean,
      default: true,
    },
    geoloc: {
      type: Object,
      default: null,
    },
    displayAddress: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    zoom: {
      type: Number,
      default: 10,
    },
    range: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    markers() {
      return [{ position: this.geoloc }];
    },
    geocodeRestrictions() {
      return { country: ["fr", "re"] };
    },
  },
  methods: {
    placeChangedHandler(place) {
      var data = {
        address: null,
        city: null,
        zipcode: null,
        country: null,
        geoloc: [place.geometry.location.lng(), place.geometry.location.lat()],
      };

      for (var i in place.address_components) {
        var c = place.address_components[i];

        if (c.types.indexOf("street_number") !== -1) {
          data.address = c.long_name;
        } else if (c.types.indexOf("route") !== -1) {
          data.address = data.address
            ? data.address + " " + c.long_name
            : c.long_name;
        } else if (c.types.indexOf("country") !== -1) {
          data.country = c.long_name;
        } else if (c.types.indexOf("locality") !== -1) {
          data.city = c.long_name;
        } else if (c.types.indexOf("postal_code") !== -1) {
          data.zipcode = c.long_name;
        }
      }

      this.$emit("placeChanged", data);
    },
  },
};
</script>

<style type="text/css">
#geocoding-address {
  margin-top: 1px;
}
</style>
