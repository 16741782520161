<template>
  <div class="row">
    <div class="col-sm-7">
      <ClientMarketing :client="client" @updateMarketing="edit" />

      <RingoverCard
        v-if="client.phone && !client.deletedAt"
        :phone="client.phoneInternational || client.phone"
      />
    </div>
    <div class="col-sm-5">
      <ClientLogCard :client="client" />
    </div>
  </div>
</template>

<script>
import ClientMarketing from "../components/ClientMarketing";
import RingoverCard from "../components/RingoverCard.vue";
import ClientLogCard from "../components/ClientLogCard";

export default {
  components: {
    ClientMarketing,
    RingoverCard,
    ClientLogCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async edit({ name, value }) {
      this.$emit("edit", { name, value });
    },
  },
};
</script>
