<template>
  <div class="wrapper">
    <router-view></router-view>

    <Loader :is-visible="loading" />

    <div class="animated fadeIn">
      <template v-if="['orders'].indexOf($route.name) > -1">
        <OrdersFilters @change="applyFilters" />
      </template>

      <div
        class="card payments-custom-wrapper"
        v-if="['orders'].indexOf($route.name) > -1 && totals"
      >
        <div class="card-header">
          <i class="fa fa-align-justify"></i> Liste des commandes ({{
            ordersTotal
          }})
          <span class="total inline-block float-right">
            Total ({{ formatPrice(totals.total) || "0.00€" }})
          </span>
          <span class="total inline-block float-right">
            Avoirs ({{ formatPrice(totals.refunded) || "0.00€" }})
          </span>
          <span class="total inline-block float-right">
            Impayé traité ({{ formatPrice(totals.unpaid) || "0.00€" }})
          </span>
          <span class="total inline-block float-right">
            En attente ({{ formatPrice(totals.pending) || "0.00€" }})
          </span>
          <span class="total inline-block float-right">
            Partiel ({{ formatPrice(totals.partial) || "0.00€" }})
          </span>
          <span class="total inline-block float-right">
            Payé ({{ formatPrice(totals.payed) || "0.00€" }})
          </span>
          <div class="inline-block float-right">
            <span
              class="fa fa-info-circle"
              v-tooltip="{
                content:
                  'Totaux des commandes pour la période et le commercial donnés',
                placement: 'top',
              }"
            ></span>
          </div>
        </div>

        <div class="card-block">
          <div class="w-100 mw-100 overflow-auto">
            <Orders
              :orders="orders"
              @click="goToOrder"
              @showCommercialNotes="displayOrderCommercialNotes"
              @showNotes="displayOrderNotes"
              :showInvoiceFile="true"
            />
          </div>

          <paginate
            v-if="pageCount"
            v-model="page"
            :key="reRenderPagination"
            :page-count="pageCount"
            :click-handler="changePage"
            prev-text="Précédent"
            next-text="Suivant"
            container-class="pagination"
          ></paginate>
        </div>
      </div>
    </div>

    <modal
      v-if="modalUpdateOrderCommercialNotes.order"
      title="Notes Commercial"
      v-model="modalUpdateOrderCommercialNotes.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateOrderCommercialNotes()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateOrderCommercialNotes.order.commercialNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>

    <modal
      v-if="modalUpdateOrderManagerNotes.order"
      title="Notes (Manager)"
      v-model="modalUpdateOrderManagerNotes.display"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateOrderNotes()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateOrderManagerNotes.order.notes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Loader from "../components/Loader";
import Modal from "vue-strap/src/Modal";
import Orders from "../components/Orders";
import OrdersFilters from "../components/OrdersFilters";
import Paginate from "vuejs-paginate";

export default {
  components: {
    Loader,
    Modal,
    Orders,
    OrdersFilters,
    Paginate,
  },
  data() {
    return {
      loading: false,
      orders: [],
      ordersTotal: 0,
      offset: 0,
      limit: 25,
      page: 1,
      reRenderPagination: 0,
      count: true,
      filters: {},
      getTotals: true,
      totals: {
        payed: 0,
        partial: 0,
        pending: 0,
        unpaid: 0,
        refunded: 0,
        total: 0,
      },
      modalUpdateOrderCommercialNotes: {
        display: false,
        order: undefined,
      },
      modalUpdateOrderManagerNotes: {
        display: false,
        order: undefined,
      },
    };
  },

  computed: {
    pageCount() {
      return Math.ceil(this.ordersTotal / this.limit);
    },
  },

  methods: {
    async loadOrders() {
      try {
        this.loading = true;

        const { data } = await this.$api.get("/orders/list", {
          params: {
            ...this.filters,
            offset: this.offset,
            limit: this.limit,
            currentTotal: this.ordersTotal,
            count: this.count,
          },
        });

        this.orders = data.data;
        this.ordersTotal = data.total;

        if (this.getTotals) {
          await this.getPricesTotals();
        }

        this.count = false;
      } catch (e) {
        console.warn(e);
      } finally {
        this.loading = false;
      }
    },

    applyFilters(filters) {
      this.getTotals =
        !moment(this.filters.begin).isSame(moment(filters.begin), "day") ||
        !moment(this.filters.end).isSame(moment(filters.end), "day") ||
        this.filters.commercial !== filters.commercial;

      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.count = true;

      this.changePage(1);

      this.reRenderPagination++;
    },

    changePage(page) {
      this.page = page;
      this.offset = this.limit * page - this.limit;

      this.loadOrders();
    },

    async getPricesTotals() {
      const { begin, end, commercial } = this.filters;

      const { data } = await this.$api.get("/orders/prices-totals", {
        params: {
          begin,
          end,
          commercial,
        },
      });

      this.totals = { ...this.totals, ...data };
      this.getTotals = false;
    },

    goToOrder(order) {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },

    displayOrderCommercialNotes(order) {
      this.modalUpdateOrderCommercialNotes.display = true;
      this.modalUpdateOrderCommercialNotes.order = order;
    },

    async updateOrderCommercialNotes() {
      const { id, commercialNotes } =
        this.modalUpdateOrderCommercialNotes.order;

      await this.$api.put(`/orders/${id}`, {
        id,
        commercialNotes,
      });

      this.modalUpdateOrderCommercialNotes.display = false;
    },

    displayOrderNotes(order) {
      this.modalUpdateOrderManagerNotes.display = true;
      this.modalUpdateOrderManagerNotes.order = order;
    },

    async updateOrderNotes() {
      const { id, notes } = this.modalUpdateOrderManagerNotes.order;

      await this.$api.put(`/orders/${id}`, {
        id,
        notes,
      });

      this.modalUpdateOrderManagerNotes.display = false;
    },
  },
};
</script>
