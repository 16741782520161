<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row" v-if="showDate">
        <div class="form-group col-sm-6">
          <label>Date de début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-3">
          <label>Concept</label>
          <v-select :options="concepts" v-model="form.concept"></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Client</label>
          <v-select
            :options="clients"
            v-model="form.client"
            @search="searchClients"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Coach</label>
          <v-select
            :options="coachs"
            v-model="form.coach"
            @search="searchCoachs"
          ></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Statut paiement</label>
          <v-select :options="filterStatus" v-model="form.status"></v-select>
        </div>
        <div class="form-group col-sm-3">
          <label>Commercial</label>
          <v-select
            :options="filterCommercials"
            v-model="form.commercial"
          ></v-select>
        </div>
        <div v-if="ranks" class="form-group col-sm-3">
          <label>Type</label>
          <v-select :options="ranks" v-model="form.rank"></v-select>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="applyFilters()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import debounce from "lodash.debounce";
import { mapState } from "vuex";

export default {
  props: {
    showDate: {
      type: Boolean,
      default: false,
    },
    concepts: {
      type: Array,
      default: () => [],
    },
    commercials: {
      type: Array,
      default: () => [],
    },
    dateDefault: {
      type: Object,
      required: false,
    },
    ranks: {
      type: Array,
      default: null,
    },
  },
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      form: {
        begin: this.dateDefault ? this.dateDefault.begin : null,
        end: this.dateDefault ? this.dateDefault.end : null,
        concept: null,
        client: null,
        coach: null,
        commercial: null,
        status: null,
        rank: null,
      },
      filterStatus: [
        {
          label: "Payée",
          value: "payed",
        },
        {
          label: "Partiel",
          value: "partial",
        },
        {
          label: "En attente du paiement",
          value: "pending",
        },
        {
          label: "Offerte",
          value: "free",
        },
      ],
      filterInactive: [
        {
          label: "Depuis 1 mois",
          value: "1m",
        },
        {
          label: "Depuis 2 mois",
          value: "2m",
        },
        {
          label: "Depuis 3 mois",
          value: "3m",
        },
        {
          label: "Plus",
          value: "4m-more",
        },
      ],
      clients: [],
      coachs: [],
    };
  },
  computed: {
    filterCommercials() {
      return this.commercials;
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },

  methods: {
    applyFilters() {
      this.$emit("apply-filters", this.form);
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.name} (${c.usedEmail})`,
        firstName: c.firstName,
        lastName: c.lastName,
        company: c.company,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async searchCoachs(search, loading) {
      if (search !== "") {
        await this.fetchCoachs(search, loading, this);
      }
    },

    fetchCoachs: debounce(async (search, loading, vm) => {
      if (!search || search.lentgh < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/users/coachs/search", {
        terms: search,
        filterBy: ["firstName", "lastName", "email"],
      });

      vm.coachs = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),
  },

  watch: {
    commercials() {
      if (
        this.form.commercial === null &&
        this.commercials.length > 0 &&
        this.commercials.filter((c) => c.value === this.user.email).length >
          0 &&
        this.user.commercial
      ) {
        this.form.commercial = {
          value: this.user.email,
          label: this.user.commercial.pseudo || this.user.firstName,
        };
      }
    },
  },
};
</script>
