<template>
  <modal
    title="Ajouter un participant"
    v-model="show"
    cancelText="Annuler"
    okText="Ajouter"
    @ok="handleNewParticipant()"
    @cancel="clear()"
    effect="fade/zoom"
    :backdrop="false"
  >
    <div class="form-group">
      <label>Client</label>
      <div class="input-group">
        <input
          @click="onSearchDebounced"
          @keyup="onSearchDebounced"
          :disabled="selectedClient"
          v-model="query"
          class="form-control form-search"
          type="text"
          placeholder="Rechercher ..."
        />
        <button
          v-if="selectedClient"
          type="button"
          class="btn btn-light"
          @click="removeSelectedClient()"
        >
          <i class="fa fa-times"></i>
        </button>
      </div>
      <div class="search-results" v-if="displayResults">
        <div
          v-for="(item, index) of clientsList"
          :key="`clients-${index}`"
          class="search-result clients search-result-client"
          @click="selectClient(item.client)"
        >
          <div v-html="item.label"></div>
          <div class="result-type result-type-client">Client</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Commande</label>
      <v-select
        :options="clientOrders"
        label="label"
        v-model="order"
        size="mini"
        @input="clearError()"
      >
        <span v-if="!selectedClient" slot="no-options">
          Veuillez sélectionner un client
        </span>
        <span v-if="selectedClient && clientOrders.length === 0" slot="no-options">
          Pas de commande disponible pour ce client
        </span>
      </v-select>
      <div v-if="error" class="invalid-feedback text-danger">
        Veuillez sélectionner une commande
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from 'vue-strap/src/Modal'

import debounce from 'lodash.debounce'
import moment from 'moment-timezone'

export default {
  components: {
    Modal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      query: '',
      terms: null,
      onSearchDebounced: debounce(() => {
        if (this.selectedClient) {
          this.selectedClient = null
        }

        if (this.order) {
          this.order = null
        }
        
        if (this.query?.length > 2) {
          this.terms = this.query.split(' ')
          this.terms = this.terms.filter((term) => term !== '')

          this.startSearchClient(this.terms)
        } else if (!this.query || (this.query?.length < 2)) {
          this.reset()
        }
      }, 300),
      clientsList: [],
      selectedClient: null,
      order: null,
      error: false
    }
  },

  computed: {
    displayResults() {
      return this.clientsList.length > 0
    },

    clientOrders() {
      if (this.selectedClient?.orders?.length > 0) {
        const uncompletedOrders = this.selectedClient.orders.filter(order => (
          !order.completed
        ))

        const sortedOrders = uncompletedOrders.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })

        return sortedOrders.map(order => {
          return {
            label: `${moment(order.createdAt).format('DD/MM/YYYY')} - ${this.formatPack(order.session)}`,
            value: order.id
          }
        })
      }

      return []
    }
  },

  methods: {
    handleNewParticipant() {
      if (!this.order) {
        this.error = true
      } else {
        this.$emit('ok', this.order.value, this.selectedClient)
      }
    },

    clear() {
      this.query = ''
      this.terms = null
      this.clientsList = []
      this.selectedClient = null
      this.order = null
      this.error = false

      this.$emit('cancel')
    },

    async startSearchClient(terms) {
      const { data } = await this.$api.post('/clients/search', {
        terms,
        filterBy: [
          'firstName',
          'lastName',
          'company',
          'email',
        ]
      })

      if (data.length > 0) {
        this.clientsList = data.map(client => {
          let label = `${client.lastName ? client.lastName : ""} ${
            client.firstName ? client.firstName : ""
          } ${client.email}`;

          this.terms.forEach((term) => {
            label = label.toLowerCase().replace(term, `<strong>${term}</strong>`)
          })

          return { label, client };
        })
      }
    },

    reset() {
      if (this.displayResults) {
        this.query = ''
        this.terms = null
        this.clientsList = []
      }
    },

    selectClient(client) {
      this.selectedClient = client
      this.clientsList = []
      this.query = `${client.fullName} (${client.email})`
    },

    removeSelectedClient() {
      this.query = ''
      this.order = null
      this.selectedClient = null

      if (this.error) {
        this.error = false
      }
    },

    clearError() {
      if (this.error) {
        this.error = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-results {
  background-color: white;
  min-width: 100%;
  max-width: 100%;
  border: 1px solid #e1e6ef;
  border-top: inherit;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}

.search-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    background-color: #20a8d8;
    color: #fff;
  }

  .result-type {
    margin-left: 0.5rem;
    font-weight: bold;
  }
}

.search-result-client {
  background-color: #d1fae5;
  .result-type-client {
    color: #10b981;
  }
}
</style>
