<template>
  <div>
    <Loader :is-visible="loading" />

    <el-table :data="data" @row-click="editContent">
      <el-table-column prop="name" label="Nom"></el-table-column>
      <el-table-column prop="type" label="Type" align="center">
        <template slot-scope="scope">
          <span class="badge badge-info">{{ scope.row.type }}</span>
        </template>
      </el-table-column>
      <template v-if="currentTypeDoc && currentTypeDoc.metadata">
        <el-table-column
          v-for="(key, index) in currentTypeDoc.metadata"
          :key="`metadata-col-${index}-${key}`"
          :prop="`metadata.${key}`"
          :label="key"
          align="center"
        ></el-table-column>
      </template>
      <el-table-column prop="createdAt" label="Créé le" align="center">
        <template slot-scope="scope">
          {{ formatDate(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="hasPermission($store.state.user, 'DYNAMIC_CONTENT_WRITE')"
        align="right"
      >
        <template slot-scope="scope">
          <button
            class="btn btn-primary btn-sm mr-05"
            @click.stop="editContent(scope.row)"
          >
            <i class="fa fa-edit"></i>
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click.stop="handleDelete(scope.row)"
          >
            <i class="fa fa-trash-o"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <ModalDynamicContent
      :show="editShow"
      :dynamic-content="selectedContent"
      :types="types"
      :close="closeEditContent"
      @updated="updatedContent"
    />
  </div>
</template>

<script>
import ModalDynamicContent from "./ModalDynamicContent";
import Loader from "./Loader";

export default {
  components: {
    ModalDynamicContent,
    Loader,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    types: {
      type: Array,
      default: () => [],
    },

    currentType: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      editShow: false,
      selectedContent: null,
    };
  },

  computed: {
    currentTypeDoc() {
      return (
        this.currentType &&
        this.types.find(({ type }) => type === this.currentType)
      );
    },
  },

  methods: {
    editContent(content) {
      if (this.hasPermission(this.$store.state.user, "DYNAMIC_CONTENT_WRITE")) {
        this.selectedContent = { ...content };
        this.editShow = true;
      }
    },

    handleDelete(content) {
      if (!content || !content.id) {
        return;
      }

      this.$confirm({
        message: `Êtes-vous sûr.e de vouloir supprimer le contenu "${content.name}" ?`,
        onConfirm: () => this.deleteContent(content.id),
      });
    },

    async deleteContent(contentId) {
      try {
        this.loading = true;

        await this.$api.delete(`/dynamic-contents/${contentId}`);

        this.$emit("deleted", contentId);
        this.$successToast("Contenu supprimé");
      } catch (e) {
        this.$errorToast("Impossible de supprimer ce contenu");
      } finally {
        this.loading = false;
      }
    },

    closeEditContent() {
      this.editShow = false;
      this.selectedContent = null;
    },

    updatedContent(content) {
      this.$emit("updated", content);
    },
  },
};
</script>
