<template>
  <modal
    title="Rappel SMS"
    v-model="show"
    cancelText="Annuler"
    okText="Envoyer"
    effect="fade/zoom"
    @cancel="close"
  >
    <div
      v-show="showWarning"
      class="card card-inverse card-warning text-center"
      style="position: relative"
    >
      <div class="card-block">
        <blockquote class="card-blockquote">
          Avez-vous bien relancé par email auparavant ?
        </blockquote>
      </div>
      <i
        class="fa fa-times"
        style="position: absolute; top: 5px; right: 5px; cursor: pointer"
        @click="showWarning = false"
      ></i>
    </div>

    <div class="row mb-1">
      <div class="col-12">
        <el-switch
          v-model="select"
          active-text="Sélection"
          inactive-text="Tous"
        />
      </div>
    </div>

    <div v-if="select" class="row">
      <el-checkbox-group
        v-model="selected"
        class="col-12 d-flex flex-column"
        style="max-height: 500px; overflow-y: auto"
      >
        <el-checkbox
          v-for="option in coachOptions"
          :key="option.value"
          :label="option.value"
          >{{ option.label }}</el-checkbox
        >
      </el-checkbox-group>

      <div v-if="selected.length" class="col-12 mt-1">
        Envoyer un SMS de rappel aux coachs sélectionnés pour
        <span class="font-weight-bold">{{ totalPrice }}€</span> ?
      </div>
    </div>

    <div v-else class="row">
      <div class="col-12">
        Envoyer un SMS de rappel à tous les coachs "en attente de réponse" pour
        <span class="font-weight-bold">{{ totalPrice }}€</span> ? <br />
      </div>
      <div class="col-12" v-if="credit">
        Avoir de <span class="font-weight-bold">{{ credit }}€</span>
      </div>
    </div>

    <div slot="modal-footer" class="modal-footer">
      <button type="button" class="btn btn-default" @click="close">
        Annuler
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="sendReminder"
        :disabled="select && !selected.length"
      >
        Envoyer
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    coachs: {
      type: Array,
      default: () => [],
    },

    price: {
      type: Number,
      default: 0,
    },

    credit: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      showWarning: true,
      select: false,
      selected: [],
    };
  },

  computed: {
    coachOptions() {
      return this.coachs
        .map((coach) => ({
          value: coach.email,
          label: `${coach.coach?.name || coach.email} (${
            coach.distance ? coach.distance.distance : "Hors secteur"
          })`,
          sort: coach.distanceSort || 0,
        }))
        .sort((a, b) => {
          if (!this.coachs.some((coach) => coach.distance)) {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();

            if (nameA > nameB) return 1;

            if (nameB > nameA) return -1;

            return 0;
          } else {
            return b.sort - a.sort;
          }
        });
    },

    totalPrice() {
      const price = this.select
        ? this.price * this.selected.length
        : this.price * this.coachs.length;

      return price.toFixed(2);
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    sendReminder() {
      this.$emit("send", this.select && this.selected);
    },
  },
};
</script>
