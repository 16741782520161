<template>
  <div class="row">
    <div class="col-12">
      <AccountDocumentsCard :coach="coach" :settings="settings" :docs="docs" />
    </div>
  </div>
</template>

<script>
import AccountDocumentsCard from "../components/AccountDocumentsCard";

export default {
  components: {
    AccountDocumentsCard,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      settings: null,
      docs: {
        coach: [],
        former: [],
        influencer: [],
      },
    };
  },

  mounted() {
    this.fetchSettings();
  },

  methods: {
    async fetchSettings() {
      try {
        const { data } = await this.$api.get("/settings");

        this.settings = data;
        this.sortDocs();
      } catch (e) {
        this.settings = null;
      }
    },

    sortDocs() {
      this.settings.documents_sign.forEach((doc) => {
        if (doc.presta == "former") {
          this.docs.former.push(doc);
        } else if (doc.presta == "influencer") {
          this.docs.influencer.push(doc);
        } else {
          this.docs.coach.push(doc);
        }
      });
    },
  },
};
</script>
