export default {
  computed: {
    isOnlyInfluencer() {
      if (this.coach?.coach) {
        return (
          this.coach.coach.isInfluencer &&
          !this.coach.coach.isFormer &&
          !this.coach.coach.isCoach
        );
      }
      return false;
    },
  },
};
