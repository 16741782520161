var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between my-1"},[(_vm.totals)?_c('div',_vm._l((_vm.coachStatus),function(status,index){return _c('span',{key:index,staticClass:"badge text-white",style:(`padding: 5px; margin-right: 5px; backgroundColor: ${status.color};`)},[_vm._v(" "+_vm._s(_vm.totals[status.value])+" "+_vm._s(status.label)+" ")])}),0):_vm._e(),(_vm.inactive)?_c('div',[_c('button',{staticClass:"btn btn-sm btn-secondary",on:{"click":_vm.toggleArchived}},[(!_vm.displayArchived)?_c('span',[_c('i',{staticClass:"fa fa-user-times",attrs:{"aria-hidden":"true"}}),_vm._v(" Voir les archives ")]):_c('span',[_vm._v("Fermer les archives")])])]):_vm._e()]),_c('el-table',{attrs:{"data":_vm.coachs,"default-sort":_vm.defaultSort,"max-height":"1300"},on:{"sort-change":_vm.sortChangeHandler}},[_c('el-table-column',{attrs:{"label":"Nom","prop":"coach.lastName","sortable":_vm.customSort}}),_c('el-table-column',{attrs:{"label":"Prénom","prop":"coach.firstName","sortable":_vm.customSort}}),_c('el-table-column',{attrs:{"label":"Ville","prop":"coach.city","sortable":_vm.customSort}}),_c('el-table-column',{attrs:{"label":"CP","prop":"coach.zipcode","align":"center","sortable":_vm.customSort}}),_c('el-table-column',{attrs:{"label":"Fiche","align":"center","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{attrs:{"href":_vm.coachHref(scope.row.id),"target":"_blank"}},[_c('i',{staticClass:"icon-user"})])]}}])}),_c('el-table-column',{attrs:{"label":"Document","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.documents &&
            _vm.getRequiredDocument(scope.row.documents).length === 4
          )?_c('i',{staticClass:"fa fa-file",class:{ 'text-success': scope.row.validated }}):_vm._e()]}}])}),(_vm.displayEnrichedData)?_c('el-table-column',{attrs:{"label":"Concept","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((_vm.coachConcepts(scope.row)),function(concept,index){return _c('span',{key:index,domProps:{"innerHTML":_vm._s(concept)}})})}}],null,false,3971892258)}):_vm._e(),(_vm.displayEnrichedData)?_c('el-table-column',{attrs:{"label":"Fréquence","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (_vm.prestataireClientFrequency(scope.row.clients, scope.row.id))?[_vm._v(" "+_vm._s(_vm.prestataireClientFrequency(scope.row.clients, scope.row.id))+" ")]:undefined}}],null,true)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Statut","prop":"coach.status","align":"center","sortable":_vm.customSort},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"badge text-white",style:(`backgroundColor: ${_vm.getCoachStatusColor(
            scope.row.coach.status
          )};`)},[_vm._v(_vm._s(_vm.getCoachStatusLabel(scope.row.coach.status)))])]}}])}),_c('el-table-column',{attrs:{"label":"Date de création","prop":"createdAt","align":"center","width":"160","sortable":_vm.customSort},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.formatDateTime(scope.row.createdAt))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Commercial","prop":"refCommercialEmail","align":"center","width":"220","sortable":_vm.customSort},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.refCommercial)?[(scope.row.refCommercial.commercial)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-white rounded-3 text-nowrap",class:{
              'bg-primary': !scope.row.refCommercial.commercial.color,
            },staticStyle:{"padding":"0 5px"},style:(scope.row.refCommercial.commercial.color &&
              `background-color:${scope.row.refCommercial.commercial.color}`)},[_vm._v(" "+_vm._s(scope.row.refCommercial.commercial.pseudo || scope.row.refCommercial.email)+" ")])]):_vm._e()]:undefined}}],null,true)}),(_vm.showDistance)?_c('el-table-column',{attrs:{"label":"Distance","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.distance || scope.row.distance === 0)?_c('span',[_vm._v(" "+_vm._s(scope.row.distance.toFixed(2).replace(".", ","))+" km ")]):_vm._e()]}}],null,false,3117213324)}):_vm._e(),_c('el-table-column',{attrs:{"label":"Note","width":"220"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('NoteModal',{key:scope.row.id,attrs:{"adminNotes":scope.row.coach.commercialNotes,"permission":"USERS_READ"},on:{"showComment":function($event){return _vm.$emit('showComment', scope.row)}}})]}}])}),(_vm.inactive && !_vm.displayArchived)?_c('el-table-column',{attrs:{"label":"Archiver","align":"center","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('button',{staticClass:"btn btn-info",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('archiveUser', scope.row)}}},[_c('i',{staticClass:"icon icon-briefcase"})])]}}],null,false,1520437804)}):_vm._e()],1),(!_vm.noPagination && _vm.count)?_c('paginate',{key:_vm.paginateRender,staticClass:"mt-1",attrs:{"page-count":_vm.count,"click-handler":_vm.paginationHandler,"prev-text":"Précédent","next-text":"Suivant","container-class":"pagination"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }