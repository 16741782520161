var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn"},[_c('CARatiosFilters',{attrs:{"default":_vm.filters},on:{"update":_vm.handleFilters}}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._m(0),(_vm.totalCA)?_c('span',[_vm._v("CA période: "+_vm._s(_vm.formatPrice(_vm.totalCA)))]):_vm._e()])]),_c('div',{staticClass:"card-block"},[_c('tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('tab',{attrs:{"header":"Graph"}},[_c('div',{staticClass:"d-flex justify-content-around"},[_c('div',{},[_c('h3',[_vm._v(" Ratios des dépenses "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Les dépenses coachs sont la sommes des avoirs réglés sur la périodes, les autres dépenses sont celles renseigné dans le menu Marketing / Dépenses. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',
                    placement: 'bottom',
                  }),expression:"{\n                    content:\n                      'Les dépenses coachs sont la sommes des avoirs réglés sur la périodes, les autres dépenses sont celles renseigné dans le menu Marketing / Dépenses. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',\n                    placement: 'bottom',\n                  }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('Pie',{attrs:{"chart-data":_vm.chartData,"options":_vm.options}})],1),_c('div',{},[_c('h3',[_vm._v(" Ratios par concept "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                    content:
                      'Répartition de CA par concept. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',
                    placement: 'bottom',
                  }),expression:"{\n                    content:\n                      'Répartition de CA par concept. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',\n                    placement: 'bottom',\n                  }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('Pie',{attrs:{"chart-data":_vm.chartDataConcept,"options":_vm.options}})],1)])]),_c('tab',{attrs:{"header":"Carte Coachs"}},[_c('h3',[_vm._v(" Ratios par zone géographique "),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content:
                  'Les dépenses correspondent au montant des avoirs réglés aux coachs sur la période. Le CA correspond aux montants des commandes payés ou non sur la période (les remboursements sont déduits).',
                placement: 'bottom',
              }),expression:"{\n                content:\n                  'Les dépenses correspondent au montant des avoirs réglés aux coachs sur la période. Le CA correspond aux montants des commandes payés ou non sur la période (les remboursements sont déduits).',\n                placement: 'bottom',\n              }"}],staticClass:"fa fa-info-circle ml-05"})]),_c('StatsCARatiosFranceMap',{attrs:{"data":_vm.mapData,"color-scheme":"red"}})],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"icon-graph"}),_vm._v(" Coûts")])
}]

export { render, staticRenderFns }