<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-12">
          <i class="icon-people" style="margin-right: 0.5rem"></i>
          {{
            client.prospect && client.prospect.isProspect
              ? "Prospect"
              : "Client"
          }}: {{ client.email }} - N°
          {{ client.matricule }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button
            type="button"
            class="btn btn-sm btn-danger float-left mt-1"
            v-if="
              client.prospect &&
              client.prospect.isProspect &&
              hasPermission($store.state.user, 'PROSPECTS_DELETE')
            "
            @click="removeClient()"
          >
            Supprimer
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger float-left mt-1"
            v-else-if="
              hasPermission($store.state.user, 'CLIENTS_DELETE')
            "
            @click="removeClient()"
          >
            Supprimer
          </button>
        </div>
      </div>
    </div>

    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">Email de contact</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.contactEmail"
            size="mini"
            @change="editClient('contactEmail', $event)"
            :class="{ 'has-error': !client.contactEmail }"
            v-if="checkPermission"
          />
          <span v-else>{{ client.contactEmail }}</span>
        </dd>
        <dt class="col-sm-4">Prénom</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.firstName"
            size="mini"
            @change="editClient('firstName', $event)"
            :class="{ 'has-error': !client.firstName }"
            v-if="checkPermission"
          />
          <span v-else>{{ client.firstName }}</span>
        </dd>
        <dt class="col-sm-4">Nom</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.lastName"
            size="mini"
            @change="editClient('lastName', $event)"
            :class="{ 'has-error': !client.lastName }"
            v-if="checkPermission"
          />
          <span v-else>{{ client.lastName }}</span>
        </dd>
        <dt class="col-sm-4">Société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.company"
            size="mini"
            @change="editClient('company', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ client.company }}</span>
        </dd>
        <dt class="col-sm-4">Genre</dt>
        <dd class="col-sm-8">
          <el-select
            v-model="client.gender"
            size="mini"
            @change="editClient('gender', $event)"
            :class="{ 'has-error': !client.gender }"
            :disabled="!checkPermission"
          >
            <el-option value="Homme" />
            <el-option value="Femme" />
          </el-select>
        </dd>
        <dt class="col-sm-4">Préférence</dt>
        <dd class="col-sm-8">
          <el-select
            v-model="client.preferedCoachGender"
            size="mini"
            @change="editClient('preferedCoachGender', $event)"
            :disabled="!checkPermission"
          >
            <el-option value="Homme" />
            <el-option value="Femme" />
            <el-option value="Peu importe" />
          </el-select>
        </dd>
        <dt v-if="client.strictCoachGender" class="col-sm-4">
          Préférence stricte
        </dt>
        <dd v-if="client.strictCoachGender" class="col-sm-8">
          <el-input
            v-model="client.strictCoachGender"
            size="mini"
            @change="editClient('strictCoachGender', $event)"
            v-if="checkPermission"
          />
        </dd>
        <dt class="col-sm-4">Téléphone</dt>
        <dd class="col-sm-8">
          <PhoneNumberInput
            v-if="checkPermission"
            :phone-number="client.phone"
            :phone-code="client.phoneCode"
            size="mini"
            copy
            required
            @changeNumber="editClient('phone', $event)"
            @changeCode="editClient('phoneCode', $event)"
          />
          <span v-else
            ><span v-if="client.phoneCode">({{ client.phoneCode }}) </span
            >{{ client.phone }}</span
          >
        </dd>
        <dt class="col-sm-4">Date de naissance</dt>
        <dd class="col-sm-8">
          <el-date-picker
            v-model="client.birthDate"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            type="date"
            size="small"
            :class="{ 'has-error': !client.birthDate }"
            @change="editClient('birthDate', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ formatDate(client.birthDate) }}</span>
        </dd>
        <dt class="col-sm-4">Informations supplémentaires</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.otherInfo"
            type="textarea"
            @change="editClient('otherInfo', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ client.otherInfo }}</span>
        </dd>
        <dt class="col-sm-4">Lieux des séances</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.preferedPlace"
            size="mini"
            @change="editClient('preferedPlace', $event)"
            :class="{ 'has-error': !client.preferedPlace }"
            v-if="checkPermission"
          />
          <span v-else>{{ client.preferedPlace }}</span>
        </dd>
        <dt class="col-sm-4">Personnes / séances</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.preferedSession"
            size="mini"
            @change="editClient('preferedSession', $event)"
            :class="{ 'has-error': !client.preferedSession }"
            v-if="checkPermission"
          />
          <span v-else>{{ client.preferedSession }}</span>
        </dd>
        <dt class="col-sm-4">Pack souhaité</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.interestedPack"
            size="mini"
            @change="editClient('interestedPack', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ client.interestedPack }}</span>
        </dd>
        <dt class="col-sm-4">Découvert par</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="client.discoveredBy"
            size="mini"
            @change="editClient('discoveredBy', $event)"
            v-if="checkPermission"
          />
          <span v-else>{{ client.discoveredBy }}</span>
        </dd>
        <dt class="col-sm-4">Concepts</dt>
        <dd class="col-sm-8">
          <multiselect
            v-model="client.concepts"
            :options="concepts"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            @input="editClient('concepts', $event)"
            label="name"
            track-by="name"
            placeholder="Choisir"
            class="custom-multiselect"
            :disabled="!checkPermission"
          >
          </multiselect>
        </dd>
        <dt class="col-sm-4">Groupes</dt>
        <dd class="col-sm-8">
          <multiselect
            v-model="client.groups"
            :options="groups"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            @select="addClientGroup($event)"
            @remove="removeClientGroup($event)"
            label="name"
            track-by="name"
            placeholder="Choisir"
            class="custom-multiselect"
            :disabled="!checkPermission"
          >
          </multiselect>
        </dd>
        <dt class="col-sm-4">
          Notification Email

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Le client reçoit ou non les emails concernant la validation des séances etc.',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="client.emailNotification"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('emailNotification', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4">
          Offre SMS/Email
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Important en cas d\'export pour la campagne de newsletter.',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="client.newsletter"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('newsletter', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt class="col-sm-4">Avis Google</dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="client.googleNotice"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('googleNotice', $event)"
            :disabled="!checkPermission"
          />
          <span v-if="client.googleNoticeSince" class="text-muted ml-1">
            Avis posté le {{ formatDate(client.googleNoticeSince) }}
          </span>
        </dd>
        <dt class="col-sm-4">Avis Trustpilot</dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="client.trustpilotNotice"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('trustpilotNotice', $event)"
            :disabled="!checkPermission"
          />

          <span v-if="client.trustpilotNoticeSince" class="text-muted ml-1">
            Avis posté le {{ formatDate(client.trustpilotNoticeSince) }}
          </span>
        </dd>
        <dt class="col-sm-4">
          Accès Live Admin

          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content:
                'Permet au client de se connecter sans payer, ni remplir d\'informations bancaires',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="client.admin"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editClient('admin', $event)"
            :disabled="!checkPermission"
          />
        </dd>
        <dt
          class="col-sm-4"
          v-if="checkPermission"
        >
          Mot de passe
          <i
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: 'Pour l\'accès à l\'application client',
              placement: 'right',
            }"
          ></i>
        </dt>
        <dd
          class="col-sm-8"
          v-if="checkPermission"
        >
          <button
            type="button"
            class="btn btn-primary"
            @click="sendLostPasswordEmail"
          >
            Email "mot de passe oublié"
          </button>
        </dd>
        <dt class="col-sm-4">Compte créé</dt>
        <dd class="col-sm-8">{{ formatDate(client.createdAt) }}</dd>
        <dt class="col-sm-4" v-if="ordersPrice">Total des commandes</dt>
        <dd class="col-sm-8" v-if="ordersPrice">
          {{ formatPrice(ordersPrice) }}
        </dd>
        <dt class="col-sm-4">Coachs</dt>
        <dd class="col-sm-8">
          <span
            v-if="
              clientCoachs.length === 0 && clientCoachsSubstitutes.length === 0
            "
            >-</span
          >

          <template v-else>
            <div
              class="col-sm-12 p-0"
              v-for="(coach, index) in clientCoachs"
              :key="index"
            >
              <a :href="coachHref(coach.id)" target="_blank">
                {{ coach.coach.name }}
              </a>
              <span v-if="coach.coach.phone">
                {{ coach.coach.phone }}
              </span>
            </div>
            <div
              class="col-sm-12 p-0"
              v-for="(coach, index) in clientCoachsSubstitutes"
              :key="index"
            >
              <a :href="coachHref(coach.id)" target="_blank">
                {{ coach.coach.name }}
              </a>
              <span v-if="coach.coach.phone">
                {{ coach.coach.phone }}
              </span>
              <span> (Remplaçant) </span>
            </div>
          </template>
        </dd>
        <dt class="col-sm-4" v-if="payers.length > 0">Payeur</dt>
        <dd class="col-sm-8" v-if="payers.length > 0">
          <p class="m-0" v-for="(payer, index) in payers" :key="index">
            {{ payer }}
          </p>
        </dd>
        <dt class="col-sm-4" v-if="client.stripeKeys">Carte(s) de crédit</dt>
        <dd class="col-sm-8" v-if="client.stripeKeys">
          <span v-if="client.stripeKeys.length === 0">-</span>
          <a
            v-else
            :key="`stripe-keys-${index}`"
            v-for="(item, index) of client.stripeKeys"
            :href="stripeCustomer(item.customerId)"
            target="_blank"
            class="btn btn-sm btn-link"
          >
            <i class="icon-credit-card"></i>&nbsp;
            <span v-if="item.conceptName">{{ item.conceptName }}</span>
          </a>
        </dd>
        <dt class="col-sm-4">Fréquence</dt>
        <dd class="col-sm-8" v-if="orders.length > 0">
          {{ averageClientFrequency(orders) || "-" }}
        </dd>
        <dd class="col-sm-8" v-else>
          <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
        </dd>
      </dl>

      <div class="mb-1">
        <button
          type="button"
          class="btn btn-secondary"
          v-if="dateLastCompletedSession"
        >
          Séance validée
          {{ dateLastCompletedSession.format("DD.MM.YYYY") }}
        </button>
        <button
          type="button"
          class="btn btn-info"
          v-if="dateNextScheduledSession"
        >
          Programmé le {{ dateNextScheduledSession.format("DD.MM.YYYY") }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          v-if="dateLastCompletedSession && !dateNextScheduledSession"
        >
          Inactif depuis {{ daysInactive }} jours
        </button>
      </div>

      <div>
        <button
          type="button"
          class="btn btn-secondary"
          @click="showBilling = true"
        >
          <i class="icon-doc"></i> Informations de facturation
        </button>

        <div class="sap-toggle">
          <span class="txt-sap">SAP</span>
          <label
            class="switch switch-sm switch-text switch-info float-right mb-0"
            v-if="checkPermission"
          >
            <input
              type="checkbox"
              class="switch-input"
              v-model="client.sendSAP"
              @click="handleToggleSendSAP"
            />
            <span class="switch-label" data-on="On" data-off="Off"></span>
            <span class="switch-handle"></span>
          </label>
          <span v-else>{{ client.sendSAP ? "Activé" : "Désactivé" }}</span>
        </div>

        <span class="text-muted">
          Dernier envoie le :
          {{ client.lastSentSAP && formatDateTime(client.lastSentSAP) }}
        </span>

        <a
          v-if="client.sap"
          :href="goToPDF(client.sap)"
          class="btn btn-info float-right"
        >
          <i class="icon-cloud-download"></i>
        </a>
        <button
          class="btn btn-info float-right"
          @click="sapModal.show = true"
          v-if="checkPermission"
        >
          <i class="icon-cursor"></i> Attestation SAP
        </button>
      </div>
    </div>

    <modal
      title="Modifier les informations de facturation"
      v-model="showBilling"
      cancelText="Fermer"
      okText="Enregistrer"
      effect="fade/zoom"
      @ok="handleEditBilling()"
    >
      <div class="card card-inverse card-warning text-center">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Les informations seront utilisées pour la création de la facture.
          </blockquote>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6">
          <label>Nom</label>
          <div class="input-group">
            <input
              v-model="client.billing.lastName"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ client.billing.lastName }}</span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Prénom</label>
          <div class="input-group">
            <input
              v-model="client.billing.firstName"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ client.billing.firstName }}</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Société</label>
        <div class="input-group">
          <textarea
            v-model="client.billing.company"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ client.billing.company }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>Service</label>
        <div class="input-group">
          <input
            v-model="client.billing.service"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ client.billing.service }}</span>
        </div>
      </div>
      <div class="form-group">
        <label>Adresse</label>
        <div class="input-group">
          <input
            v-model="client.billing.address"
            type="text"
            class="form-control"
            v-if="checkPermission"
          />
          <span v-else>{{ client.billing.address }}</span>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Code postal</label>
          <div class="input-group">
            <input
              v-model="client.billing.zipcode"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ client.billing.zipcode }}</span>
          </div>
        </div>
        <div class="form-group col-sm-6">
          <label>Ville</label>
          <div class="input-group">
            <input
              v-model="client.billing.city"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ client.billing.city }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Email</label>
          <div class="input-group">
            <input
              v-model="client.billing.email"
              type="text"
              class="form-control"
              v-if="checkPermission"
            />
            <span v-else>{{ client.billing.email }}</span>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label>Téléphone</label>
          <PhoneNumberInput
            v-if="checkPermission"
            :phone-number="client.billing.phone"
            :phone-code="client.billing.phoneCode"
            copy
            @changeNumber="changeBillingPhone"
            @changeCode="changeBillingPhoneCode"
          />
          <div v-else>
            <span v-if="client.billing.phoneCode"
              >({{ client.billing.phoneCode }}) </span
            >{{ client.billing.phone }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Description (pré-rempli la création de commande)</label>
          <div class="input-group">
            <textarea
              v-model="client.billing.description"
              type="text"
              class="form-control"
              v-if="checkPermission"
            ></textarea>
            <span v-else>{{ client.billing.description }}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <div class="commercial-toggle">
            <label
              class="switch switch-sm switch-text switch-info mb-0"
              v-if="checkPermission"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="client.billing.showCommercial"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
            <span class="txt-sap">
              {{
                client.billing.showCommercial ? "Afficher" : "Ne pas afficher"
              }}
              les informations du commercial dans la facture
            </span>
          </div>
        </div>
      </div>
    </modal>

    <modal
      title="Envoyer l'attestation"
      v-model="sapModal.show"
      cancelText="Fermer"
      okText="Envoyer l'attestation"
      effect="fade/zoom"
      @ok="handleSendSAP()"
    >
      <p v-if="sapModal.error" class="alert alert-danger">
        Erreur lors de l'envoi...
      </p>
      <p v-else-if="sapModal.success" class="alert alert-success">
        Attestation envoyée !
      </p>

      <div class="form-group" v-if="client">
        <label>Adresse email</label>
        <input
          v-if="client.contactEmail && client.contactEmail.length > 5"
          :value="client.contactEmail"
          @input="sapModal.email = $event.target.value"
          type="text"
          class="form-control"
        />
        <input
          v-else-if="client.billing && client.billing.email"
          :value="client.billing.email"
          @input="sapModal.email = $event.target.value"
          type="text"
          class="form-control"
        />
        <input
          v-else
          :value="client.email"
          @input="sapModal.email = $event.target.value"
          type="text"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label>Année des paiements concernés</label>
        <input v-model="sapModal.year" type="text" class="form-control" />
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";

import Multiselect from "vue-multiselect";
import FrequencyMixin from "../mixin/FrequencyMixin";
import Modal from "vue-strap/src/Modal";
import PhoneNumberInput from "./PhoneNumberInput";

export default {
  components: {
    Multiselect,
    Modal,
    PhoneNumberInput,
  },

  mixins: [FrequencyMixin],

  props: {
    client: {
      type: Object,
      required: true,
    },

    orders: {
      type: Array,
      default: () => [],
    },

    sessions: {
      type: Array,
      default: () => [],
    },

    concepts: {
      type: Array,
      default: () => [],
    },

    groups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showBilling: false,
      sapModal: {
        show: false,
        email: null,
        year: moment().format("YYYY"),
        success: false,
        error: false,
      },
    };
  },

  computed: {
    payers() {
      const payers = [];

      if (this.client.payments) {
        for (const payment of this.client.payments) {
          if (payment.payer && !payers.includes(payment.payer)) {
            payers.push(payment.payer);
          }
        }
      }

      return payers;
    },

    ordersPrice() {
      let price = 0;

      this.orders.forEach((order) => {
        if (order.status === "refunded") {
          price -= order.price;
        } else {
          price += order.price;
        }
      });

      return price;
    },

    clientCoachs() {
      if (this.client && this.client.attributions) {
        let attributions = new Set(
          this.client.attributions
            .filter((a) => !a.expired && a.substitution)
            .map((a) => a.user)
        );

        return this.client.coachs.filter((u) => !attributions.has(u.email));
      }

      return [];
    },

    clientCoachsSubstitutes() {
      if (this.client && this.client.attributions) {
        let attributions = new Set(
          this.client.attributions
            .filter((a) => !a.expired && a.substitution)
            .map((a) => a.user)
        );

        return this.client.coachs
          .filter((u) => attributions.has(u.email))
          .filter((u) => {
            u.attribution = this.client.attributions.find(
              (s) => s.user === u.email
            );

            return true;
          });
      }

      return [];
    },

    dateLastCompletedSession() {
      let dateCurrent = moment();
      let date = null;

      let sessions = this.sessions.filter(
        (s) => !!s.scheduledAt && s.completed
      );

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);

        if (
          scheduledAt <= dateCurrent &&
          (date == null || scheduledAt > date)
        ) {
          date = scheduledAt;
        }
      });

      return date;
    },

    daysInactive() {
      if (!this.dateLastCompletedSession) return null;

      return moment().diff(this.dateLastCompletedSession, "days");
    },

    dateNextScheduledSession() {
      let dateCurrent = moment();
      let date = null;

      let sessions = this.sessions.filter((s) => !!s.scheduledAt);

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);

        if (
          scheduledAt >= dateCurrent &&
          (date == null || scheduledAt < date)
        ) {
          date = scheduledAt;
        }
      });

      return date;
    },

    checkPermission() {
      return this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") && !this.client.deletedAt;
    },
  },

  methods: {
    editClient(name, value) {
      this.$emit("edit", { name, value });
    },

    removeClient() {
      this.$confirm({
        message: "Attention, cette action est irreversible ! Le client sera anonymisé et vous ne retrouverez plus ses informations dans les commandes existantes.",
        onConfirm: async () => {
          try {
            await this.$api.delete("/clients", {
              data: {
                email: this.client.email,
              },
            });

            this.$router.push({
              name: "clients",
            });
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    sendLostPasswordEmail() {
      this.$confirm({
        message: `Attention, cela va supprimer le mot de passe actuel et envoyer le nouveau à cet email : ${this.client.usedEmail}`,
        onConfirm: async () => {
          try {
            await this.$api.post("/client-app/account/reset", {
              email: this.client.email,
            });

            this.$successToast("Email envoyé");
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },

    async addClientGroup(event) {
      this.$api.post(`/groups/${event.id}/add-client`, {
        clientEmail: this.client.email,
      });
    },

    async removeClientGroup(event) {
      this.$api.post(`/groups/${event.id}/remove-client`, {
        clientEmail: this.client.email,
      });
    },

    coachHref(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: {
          id,
        },
      });

      return route.href;
    },

    stripeCustomer(customerId) {
      return "https://dashboard.stripe.com/customers/" + customerId;
    },

    async handleEditBilling() {
      await this.$api.put("/clients", {
        email: this.client.email,
        billing: this.client.billing,
      });

      this.showBilling = false;
    },

    handleToggleSendSAP(event) {
      this.$api.put("/clients/toggle-sap", {
        email: this.client.email,
        sendSAP: event.target.checked,
      });
    },

    goToPDF(pdf) {
      return `${axios.defaults.baseURL}uploads/sap/${pdf}.pdf`;
    },

    handleSendSAP() {
      if (!this.sapModal.email) {
        if (this.client.contactEmail && this.client.contactEmail.length > 5) {
          this.sapModal.email = this.client.contactEmail;
        } else {
          this.sapModal.email =
            this.client.billing && this.client.billing.email
              ? this.client.billing.email
              : this.client.email;
        }
      }

      this.$confirm({
        message: `Générer une nouvelle attestation et l'envoyer à ${this.sapModal.email} ?`,
        onConfirm: async () => {
          try {
            this.sapModal.success = false;
            this.sapModal.error = false;

            const { email, year } = this.sapModal;

            await this.$api.put("/clients/sap/mail", {
              client: this.client.email,
              email,
              year,
            });

            this.sapModal.success = true;
          } catch (e) {
            this.sapModal.error = true;
          }
        },
      });
    },

    changeBillingPhone(phone) {
      this.client.billing = {
        ...this.client.billing,
        phone,
      };
    },

    changeBillingPhoneCode(phoneCode) {
      this.client.billing = {
        ...this.client.billing,
        phoneCode,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sap-toggle {
  display: inline-block;
  margin: 0 1rem;

  .txt-sap {
    margin-right: 0.5rem;
  }
}
</style>
