import { render, staticRenderFns } from "./AccountGlobalCard.vue?vue&type=template&id=5a502573&scoped=true&"
import script from "./AccountGlobalCard.vue?vue&type=script&lang=js&"
export * from "./AccountGlobalCard.vue?vue&type=script&lang=js&"
import style0 from "./AccountGlobalCard.vue?vue&type=style&index=0&id=5a502573&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a502573",
  null
  
)

export default component.exports