<template>
  <div class="animated fadeIn">
    <loader :isVisible="isLoading" />
    <div class="row">
      <div class="form-group col-sm-4">
        <label>Date de début</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.dataBegin, placement: 'right' }"
        ></i>
        <datepicker
          v-model="filters.dateBegin"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4">
        <label>Date de fin</label>
        <datepicker
          v-model="filters.dateEnd"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4 d-flex flex-column">
        <label class="mb-1">Archives</label>
        <el-radio-group v-model="filters.archives">
          <el-radio :label="'hidden'">Sans</el-radio>
          <el-radio :label="'displayed'">Avec</el-radio>
          <el-radio :label="'archivesOnly'">Archives uniquement</el-radio>
        </el-radio-group>
      </div>
      <div class="form-group col-sm-4">
        <label>Concept</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.concept, placement: 'right' }"
        ></i>
        <multiselect
          v-model="filters.concepts"
          :options="conceptOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Statut</label>
        <multiselect
          v-model="filters.status"
          :options="statusOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Séance découverte</label>
        <multiselect
          v-model="filters.discoveryStatus"
          :options="discoveryStatusOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Département</label>
        <multiselect
          v-model="filters.departments"
          :options="departmentsOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Sexe</label>
        <multiselect
          v-model="filters.genders"
          :options="gendersOptions"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Coachs</label>
        <multiselect
          v-model="filters.coachs"
          :options="coachsOptions"
          label="label"
          track-by="label"
          :multiple="true"
          :searchable="true"
          :loading="searchCoachsLoading"
          :internal-search="false"
          :close-on-select="false"
          selectLabel=""
          deselectLabel=""
          @search-change="searchCoachs"
        >
          <template slot="noOptions"> Rechercher un coach </template>
          <template slot="noResult">
            Aucun résultat (Attention la recherche dois faire plus de 2
            caractères)
          </template>
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Offre SMS / Mail</label>
        <multiselect
          v-model="filters.newsletter"
          :options="newsletterOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="col-sm-4">
        <label>Date de la derniere séance découverte validée entre le</label>
        <div class="row">
          <div class="form-group col-sm-6">
            <datepicker
              v-model="filters.dateLastSessionBegin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <datepicker
              v-model="filters.dateLastSessionEnd"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-sm btn-primary float-right mx-1"
          @click="download()"
        >
          <i class="icon-cloud-download"></i>&nbsp; Télécharger
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Multiselect from "vue-multiselect";
import debounce from "lodash.debounce";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    Multiselect,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      isLoading: false,
      filters: {
        dateBegin: moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
        archives: "hidden",
        concepts: [],
        status: [],
        genders: [],
        coachs: [],
        newsletter: [],
        departments: [],
        dateLastSessionBegin: null,
        dateLastSessionEnd: null,
        discoveryStatus: [],
      },
      conceptOptions: [],
      discoveryStatusOptions: [
        { label: "Payé", value: "payed" },
        { label: "Pas payé", value: "pending" },
        { label: "Non programmé", value: "not_schedule" },
        { label: "Planifiée", value: "schedule" },
        { label: "Validée", value: "validate" },
      ],
      gendersOptions: ["Homme", "Femme"],
      departmentsOptions: [],
      coachsOptions: [],
      searchCoachsLoading: false,
      newsletterOptions: [
        { label: "Oui", value: 1 },
        { label: "Non", value: 0 },
      ],
      helpers: {
        dataBegin: "Date de creation du prospect.",
        concept:
          "Concept des commandes du prospect. (Ne prend pas en compte le concept saisi à la création du prospect).",
      },
    };
  },

  async created() {
    await this.getConcepts();
    await this.getDepartment();
  },

  computed: {
    ...mapGetters({
      statusOptions: "getProspectStatus",
    }),

    sanitarizeFilters() {
      return {
        dateBegin: this.filters.dateBegin,
        dateEnd: this.filters.dateEnd,
        archives: this.filters.archives,
        concepts: this.filters.concepts,
        status: this.filters.status.map((s) => s.value),
        genders: this.filters.genders,
        coachs: this.filters.coachs.map((c) => c.id),
        newsletter: this.filters.newsletter && this.filters.newsletter.value,
        departments: this.filters.departments,
        dateLastSessionBegin: this.filters.dateLastSessionBegin,
        dateLastSessionEnd: this.filters.dateLastSessionEnd,
        discoveryStatus: this.filters.discoveryStatus.map((g) => g.value),
      };
    },
  },

  methods: {
    async download() {
      this.isLoading = true;
      const { data } = await this.$api.get("/exports/prospects", {
        params: {
          ...this.sanitarizeFilters,
        },
      });

      this.downloadXLSX(data, "prospect.xlsx");
      this.isLoading = false;
    },
    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => c.name);
    },

    async getDepartment() {
      const { data } = await this.$api.get("/clients/departments");

      this.departmentsOptions = data;
    },

    async searchCoachs(search) {
      if (search !== "" && search.length > 2) {
        await this.fetchCoachs(search, this);
      }
    },

    fetchCoachs: debounce(async (search, vm) => {
      vm.searchCoachsLoading = true;

      const { data } = await vm.$api.post("/users/coachs/search", {
        terms: search,
      });

      vm.coachsOptions = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      vm.searchCoachsLoading = false;

      return vm.coachsOptions;
    }, 300),
  },
};
</script>
