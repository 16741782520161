var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table table-condensed el-table"},[_c('thead',[_c('tr',[(_vm.coachs)?_c('th',[_vm._v("Coach")]):_vm._e(),(_vm.documents)?_c('th',[_vm._v("Partagé par")]):_vm._e(),_c('th',[_vm._v("Nom du fichier")]),_c('th',[_vm._v("Date de création")]),(_vm.coachs)?_c('th',[_vm._v(" Commercial "),_c('span',{staticClass:"caret-wrapper",on:{"click":function($event){return _vm.sort('commercialEmail')}}},[_c('i',{staticClass:"sort-caret ascending"}),_c('i',{staticClass:"sort-caret descending"})])]):_vm._e(),_c('th')])]),(_vm.docsInPage)?_c('tbody',_vm._l((_vm.docsInPage),function(doc,index){return _c('tr',{key:index},[(_vm.coachs)?_c('td',[_c('DisplayCoachName',{attrs:{"coach":{ coach: { name: doc.coachName }, id: doc.coachId },"cssClass":"user-link"}})],1):_vm._e(),(_vm.documents)?_c('td',[_vm._v(_vm._s(doc.user))]):_vm._e(),_c('td',[(doc.document)?_c('span',[_vm._v(_vm._s(doc.document.tag || doc.document.name))]):(doc.name)?_c('span',[_vm._v(_vm._s(doc.tag || doc.name))]):_vm._e()]),(_vm.coachs)?_c('td',[_vm._v(" "+_vm._s(doc.document && doc.document.createdAt ? _vm.formatDateTime(doc.document.createdAt) : _vm.formatDateTime(doc.createdAt))+" ")]):_vm._e(),(_vm.coachs)?_c('td',[(doc.commercial)?_c('div',{staticClass:"commercials-ctn"},[_c('div',{staticClass:"text-white rounded-3 text-nowrap",class:doc.commercial.commercial &&
                !doc.commercial.commercial.color &&
                'bg-primary',style:(doc.commercial.commercial &&
                doc.commercial.commercial.color &&
                `background-color:${doc.commercial.commercial.color}`)},[_vm._v(" "+_vm._s(doc.commercial.commercial && doc.commercial.commercial.pseudo)+" ")])]):_vm._e()]):_vm._e(),(_vm.coachs)?_c('td',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.download(doc.coachId, doc.document.fileId)}},[_c('i',{staticClass:"icon-cloud-download"}),_vm._v(" Télécharger ")]),(_vm.hasPermission(_vm.$store.state.user, 'DOCUMENTS_WRITE'))?_c('button',{staticClass:"btn btn-success",on:{"click":function($event){return _vm.validDocument(doc, index)}}},[_c('i',{staticClass:"icon-check"}),_vm._v(" Valider ")]):_vm._e(),(_vm.hasPermission(_vm.$store.state.user, 'DOCUMENTS_WRITE'))?_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.rejectDocument(doc, index)}}},[_c('i',{staticClass:"icon-close"}),_vm._v(" Refuser ")]):_vm._e()]):_vm._e(),(_vm.documents)?_c('td',{staticClass:"text-right"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":_vm.download('vault', doc.id)}},[_c('i',{staticClass:"icon-cloud-download"}),_vm._v(" Télécharger ")]),(
              doc.user === _vm.user.email &&
              _vm.hasPermission(_vm.$store.state.user, 'DOCUMENTS_WRITE')
            )?_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){return _vm.removeDocument(doc, index)}}},[_c('i',{staticClass:"icon-close"}),_vm._v(" Supprimer ")]):_vm._e()]):_vm._e()])}),0):_vm._e()]),(_vm.count)?_c('paginate',{attrs:{"page-count":_vm.count,"click-handler":_vm.paginationHandler,"prev-text":"Précédent","next-text":"Suivant","container-class":"pagination"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }