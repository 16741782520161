<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header font-weight-bold">Factures</div>

      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de début </label>
            <datepicker
              v-model="invoiceFilters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Date de fin </label>
            <datepicker
              v-model="invoiceFilters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>

          <div class="col-sm-3">
            <label for="">Code</label>
            <v-select
              :options="
                invoiceSeries
                  .filter((is) => is.code !== 'VISIO')
                  .map((is) => is.code)
              "
              :clearable="false"
              v-model="invoiceFilters.code"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="card-header">
        <LoaderButton
          class="mr-1"
          @ok="exportInvoices()"
          :disabled="!hasRole($store.state.user, 'admin')"
          :loading="loadInvoice"
          name="Export Excel"
          icon="icon-calculator"
        />
        <LoaderButton
          class="mr-1"
          @ok="downloadZipInvoices()"
          :disabled="!hasRole($store.state.user, 'admin')"
          :loading="loadInvoiceZip"
          btn-class="btn-secondary"
          name="Télécharger ZIP"
          icon="icon-cloud-download"
        />
        <LoaderButton
          class="mr-1"
          @ok="downloadMergedInvoices()"
          :disabled="!hasRole($store.state.user, 'admin')"
          :loading="loadInvoiceMerged"
          btn-class="btn-warning"
          name=" Pdf combiné"
          icon="fa fa-file-pdf-o"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <span class="font-weight-bold">Paiements Stripe</span>
        <span class="small">&nbsp;(paiements acquités ou remboursements)</span>
      </div>

      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de début</label>
            <datepicker
              v-model="stripePaymentFilters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Date de fin</label>
            <datepicker
              v-model="stripePaymentFilters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>

          <div class="col-sm-3">
            <label for="">Code</label>
            <v-select
              :options="
                invoiceSeries
                  .filter((is) => is.code !== 'VISIO')
                  .map((is) => is.code)
              "
              :clearable="false"
              v-model="stripePaymentFilters.code"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="card-header">
        <LoaderButton
          class="mr-1"
          @ok="exportCapturedStripePayments()"
          :disabled="!hasRole($store.state.user, 'admin')"
          :loading="loadCapturedStripePayments"
          name="Export Excel"
          icon="icon-calculator"
        />
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <span class="font-weight-bold">Paiements (Sans stripe)</span>
        <span class="small">&nbsp;(paiements acquités ou remboursements)</span>
      </div>

      <div class="card-block">
        <div class="row">
          <div class="form-group col-sm-3">
            <label>Date de début</label>
            <datepicker
              v-model="paymentFilters.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-3">
            <label>Date de fin</label>
            <datepicker
              v-model="paymentFilters.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>

          <div class="col-sm-3">
            <label for="">Code</label>
            <v-select
              :options="
                invoiceSeries
                  .filter((is) => is.code !== 'VISIO')
                  .map((is) => is.code)
              "
              :clearable="false"
              v-model="paymentFilters.code"
            ></v-select>
          </div>
        </div>
      </div>
      <div class="card-header">
        <LoaderButton
          class="mr-1"
          @ok="exportCapturedPayments()"
          :disabled="!hasRole($store.state.user, 'admin')"
          :loading="loadCapturedPayments"
          name="Export Excel"
          icon="icon-calculator"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import axios from "axios";
import { mapState } from "vuex";
import LoaderButton from "../components/form/LoaderButton";

export default {
  components: {
    Datepicker,
    LoaderButton
  },
  data() {
    return {
      invoiceFilters: {
        begin: moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format(),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format(),
        code: null,
      },

      stripePaymentFilters: {
        begin: moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format(),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format(),
        code: "COMP",
      },

      paymentFilters: {
        begin: moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format(),
        end: moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format(),
        code: null,
      },
      loadInvoice: false,
      loadInvoiceZip: false,
      loadInvoiceMerged: false,
      loadCapturedStripePayments: false,
      loadCapturedPayments: false
    };
  },

  computed: {
    ...mapState({
      invoiceSeries: (state) => state.invoiceSeries,
    }),
  },

  methods: {
    async downloadMergedInvoices() {
      try {
        if (this.loadInvoiceMerged) return

        this.loadInvoiceMerged = true

        await this.downloadInvoices('merged-pdf')

        this.loadInvoiceMerged = false
      } catch (e) {
        console.error(e);
      }
    },

    async downloadZipInvoices() {
      try {
        if (this.loadInvoiceZip) return

        this.loadInvoiceZip = true

        await this.downloadInvoices()

        this.loadInvoiceZip = false
      } catch (e) {
        console.error(e);
      }
    },

    async downloadInvoices(group = false) {
      try {
        const { data } = await this.$api.get("/invoices/download", {
          params: {
            ...this.invoiceFilters,
            group,
          },
        });

        if (data.warningInfo) {
          this.$warningToast(data.warningInfo);

          return;
        }
        window.location.href = axios.defaults.baseURL + data.archive;
      } catch (e) {
        console.error(e);
      }
    },

    async exportInvoices() {
      try {
        if (this.loadInvoice) return

        this.loadInvoice = true

        const { data } = await this.$api.get("/invoices/export", {
          params: {
            ...this.invoiceFilters,
          },
        });

        window.location.href = axios.defaults.baseURL + data.archive;

        this.loadInvoice = false
      } catch (e) {
        console.error(e);
      }
    },

    async exportCapturedStripePayments() {
      try {
        if (this.loadCapturedStripePayments) return

        this.loadCapturedStripePayments = true

        const { data } = await this.$api.post("/stripe/payouts", {
          date: {
            begin: this.stripePaymentFilters.begin,
            end: this.stripePaymentFilters.end,
          },
          invoiceSerieCode: this.stripePaymentFilters.code,
          type: "xlsx",
        });

        window.location.href = axios.defaults.baseURL + data.archive;

        this.loadCapturedStripePayments = false
      } catch (e) {
        console.error(e);
      }
    },

    async exportCapturedPayments() {
      try {
        if (this.loadCapturedPayments) return

        this.loadCapturedPayments = true

        const { data } = await this.$api.get("/payments/export", {
          params: {
            ...this.paymentFilters,
          },
        });

        window.location.href = axios.defaults.baseURL + data.archive;

        this.loadCapturedPayments = false
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
