<template>
  <div
    class="card"
    v-if="
      ['faq'].indexOf($route.name) > -1 &&
      hasPermission($store.state.user, 'FAQ_READ')
    "
  >
    <div class="card-header">
      <i class="fa fa-help"></i> Liste des questions/réponses
    </div>
    <div class="card-block">
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>Question</th>
            <th>Catégorie(s)</th>
            <th v-if="hasPermission($store.state.user, 'FAQ_WRITE')">Action</th>
          </tr>
        </thead>
        <tbody v-if="currentFaqs.length > 0">
          <tr
            v-for="(faq, index) in currentFaqs"
            :key="index"
            @click.stop="handleEditFaq(faq)"
            class="click"
          >
            <td>{{ faq.question }}</td>
            <td>
              <span
                class="bg-info mr-1"
                style="padding: 5px 10px"
                :key="index"
                v-for="(tag, index) in faq.faqTags"
              >
                {{ tag.refTag.label }}
              </span>
            </td>
            <td v-if="hasPermission($store.state.user, 'FAQ_WRITE')">
              <button
                class="btn btn-danger btn-sm"
                @click.stop="handleDeleteFaq(faq)"
              >
                <i class="fa fa-trash-o"></i>
              </button>
            </td>
          </tr>
        </tbody>
        <div class="w-full p-5" v-else>
          <h5 class="m-auto">
            Cette catégorie est vide, veuillez en séléctionner une autre pour
            afficher les Faq associée.
          </h5>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    faqs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      currentFaqs: [],
    };
  },

  mounted() {
    this.currentFaqs = this.faqs;
  },

  methods: {
    handleDeleteFaq(faq) {
      this.$confirm({
        message: "Êtes vous sûr de vouloir supprimer cette FAQ ?",
        onConfirm: async () => {
          await this.$api.delete(`/faq/${faq.id}`);

          this.$emit("delete");
        },
      });
    },

    async handleEditFaq(faq) {
      if (this.hasPermission(this.$store.state.user, "FAQ_WRITE")) {
        this.$emit("edit", faq);
      }
    },
  },

  watch: {
    faqs: {
      deep: true,
      handler() {
        this.currentFaqs = this.faqs;
      },
    },
  },
};
</script>

<style></style>
