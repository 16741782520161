<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-folder"></i> Documents
      <button
        class="btn btn-sm btn-primary float-right"
        @click="showDialogUpload = true"
      >
        Ajouter
      </button>
    </div>
    <div
      class="card-block"
      v-if="coach.documents || (settings && settings['documents_sign'])"
    >
      <table v-if="coach.documents" class="table table-striped">
        <thead>
          <th>Fichier</th>
          <th></th>
          <th></th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(document, index) in coach.documents" :key="index">
            <td>{{ document.tag }}</td>
            <td>{{ document.name }}</td>
            <td>
              <span class="badge badge-success" v-if="document.validatedAt"
                >Validé</span
              >
              <span class="badge badge-warning" v-else
                >En attente de validation</span
              >
            </td>
            <td>
              <button
                class="btn btn-sm btn-danger"
                v-if="!document.validatedAt"
                @click="removeDocument(document.fileId)"
              >
                <i class="icon-close"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-footer">
        <template v-if="docs.coach && coach.coach.isCoach">
          <span v-for="doc in docs.coach" :key="doc.id" class="mr-1">
            <a :href="download(doc.id)">{{ doc.name }}</a>
          </span>
        </template>
        <template v-if="docs.former && coach.coach.isFormer">
          <span v-for="doc in docs.former" :key="doc.id" class="mr-1">
            <a :href="download(doc.id)">{{ doc.name }}</a>
          </span>
        </template>
        <template v-if="docs.influencer && coach.coach.isInfluencer">
          <span v-for="doc in docs.influencer" :key="doc.id" class="mr-1">
            <a :href="download(doc.id)">{{ doc.name }}</a>
          </span>
        </template>
      </div>
    </div>

    <modal title="Ajouter un document" v-model="showDialogUpload">
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un document</h4>
      </div>
      <div slot="modal-body" class="modal-body">
        <div class="mb-2">
          <p>1. Choisir fichier</p>
          <dropzone
            ref="dropzoneDocument"
            :url="apiUploads()"
            :headers="apiAuthorization()"
            :acceptedFileTypes="defaultMimeType"
            id="file"
            v-on:vdropzone-success="documentUploaded"
          >
            <div class="dz-default dz-message">
              <span
                ><i class="icon-cloud-upload"></i><br />Glissez tous vos
                documents ici (PDF, Word, Image)
              </span>
            </div>
          </dropzone>
        </div>
        <hr />
        <div v-if="settings && settings['document_tags']">
          <p>2. Choisir type de document</p>
          <el-select v-model="selectedDocumentTag" placeholder="Select">
            <el-option
              v-for="item in settings['document_tags']"
              :key="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showDialogUpload = false"
        >
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-primary"
          @click="submitDocument"
          :disabled="!uploadedDocumentFile || !selectedDocumentTag"
        >
          Soumettre
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Modal,
    Dropzone,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },

    settings: {
      type: Object,
      default: null,
    },

    docs: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showDialogUpload: false,
      uploadedDocumentFile: null,
      selectedDocumentTag: null,
      defaultMimeType:
        "application/pdf,image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    };
  },

  watch: {
    showDialogUpload(opened) {
      if (opened) {
        this.uploadedDocumentFile = null;
        this.selectedDocumentTag = null;
        this.$refs.dropzoneDocument.removeAllFiles();
      }
    },
  },

  methods: {
    removeDocument(fileId) {
      this.$confirm({
        message:
          "Si vous supprimez un fichier, votre compte sera soumis à validation.",
        onConfirm: async () => {
          await this.$api.delete(`/app/document/${fileId}`);

          this.$store.dispatch("loadUser");
        },
      });
    },

    download(fileId) {
      return `${axios.defaults.baseURL}uploads/document/${fileId}`;
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    documentUploaded(file, response) {
      this.uploadedDocumentFile = response;
    },

    submitDocument() {
      this.$api
        .put("/app/document", {
          fileId: this.uploadedDocumentFile.id,
          fileName: this.uploadedDocumentFile.name,
          fileTag: this.selectedDocumentTag,
        })
        .then(() => {
          this.$store.dispatch("loadUser");
          this.showDialogUpload = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
