<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app'
}
</script>
<style>
  a, button,
  .click {
    cursor: pointer
  }
  .editable {
    cursor: text;
    -moz-text-decoration-line: underline;
    -moz-text-decoration-style: dashed;
    -moz-text-decoration-color: #444;
    -webkit-text-decoration-line: underline;
    -webkit-text-decoration-style: dashed;
    -webkit-text-decoration-color: #444;
    text-decoration-line: underline;
    text-decoration-style: dashed;
    text-decoration-color: #444;
  }
  .editable:hover {
    border-bottom: 2px solid rgba(32, 168, 216, 0.6);
    margin-top: -2px;
  }
  .editable:focus {
    border-bottom: 2px solid rgba(32, 168, 216, 1);
    margin-top: -2px;
  }
  .has-danger .v-select > .dropdown-toggle {
    border-color: #ff5454;
  }
  .v-select input[type=search] {
    width: auto !important
  }
  .btn-xs{
    padding: 0.1rem 0.2rem !important;
    font-size: 0.7rem !important;
  }
  .table-wrapper-scroll-y {
    display: block;
    max-height: 200px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
</style>
