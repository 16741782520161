<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-2">
          <label class="control-label">Catégorie</label>
          <select v-model="filters.tag" class="form-control">
            <option :key="tag.id" v-for="tag of filterTags" :value="tag.code">
              {{ tag.label }}
            </option>
          </select>
        </div>
        <div class="col-sm-10">
          <div class="float-right">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="openModal()"
              v-if="hasPermission($store.state.user, 'FAQ_WRITE')"
            >
              <i class="icon-plus"></i> Ajouter
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="applyFilters"
          >
            <i class="icon-eye"></i>&nbsp; Visualiser
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    default: {
      type: Object,
      required: true,
    },
    filterTags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filters: {},
    };
  },

  async created() {
    this.filters = this.default;
  },

  methods: {
    openModal() {
      this.$emit("openModal");
    },

    applyFilters() {
      this.$emit("update", { ...this.filters });
    },
  },
};
</script>
