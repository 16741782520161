<template>
  <paginate
    v-model="currentPage"
    :page-count="numberOfPages"
    :click-handler="changePage"
    prev-text="Précédent"
    next-text="Suivant"
    container-class="pagination"
  >
  </paginate>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: { Paginate },
  props: {
    total: {
      type: Number,
      required: true
    },
    offset: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      currentPage: 0,
      numberOfPages: 0
    };
  },

  mounted() {
    this.currentPage = this.offset / this.limit + 1;
    this.numberOfPages = Math.round(this.total / this.limit) - 1;
  },

  methods: {
    changePage(page) {
      this.currentPage = page;

      this.$emit("changePage", page);
    }
  }
};
</script>
