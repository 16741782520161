<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <i class="icon-check"></i> Pointage - Séances
          {{ validated ? `validées` : `à valider` }}
          <button
            v-if="!validated"
            class="btn btn-sm btn-primary float-right"
            @click="fetchAllSession(true)"
          >
            Voir les séances validées
          </button>
          <button
            v-else
            class="btn btn-sm btn-primary float-right"
            @click="fetchAllSession(false)"
          >
            Voir les séances à valider
          </button>
        </div>

        <div class="card-block">
          <el-table
            ref="table"
            :data="data"
            row-key="id"
            @row-click="toggleRowExpand"
          >
            <el-table-column prop="client" label="Client">
              <template slot-scope="scope">
                <span v-if="scope.row.client">{{ scope.row.client }}</span>
                <span v-else-if="scope.row.live && scope.row.live.group"
                  >{{ scope.row.live.group.name }} (Groupe)</span
                >
                <span v-if="scope.row.children">
                  ({{ scope.row.children.length }})</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="live.name" label="Cours">
              <template v-if="scope.row.live" slot-scope="scope">
                {{ scope.row.live.name }}
              </template>
            </el-table-column>
            <el-table-column prop="live.type" label="Type">
              <template v-if="scope.row.live" slot-scope="scope">
                {{ getLiveType(scope.row.live.type) }}
              </template>
            </el-table-column>
            <el-table-column prop="credit" label="Avoir">
              <template
                v-if="scope.row.credit || scope.row.credit === 0"
                slot-scope="scope"
              >
                {{ formatPrice(scope.row.credit, true) }}
              </template>
            </el-table-column>
            <el-table-column prop="scheduledAt" label="Programmée le">
              <template slot-scope="scope">
                {{ formatDateTime(scope.row.scheduledAt) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="nbOfParticipants"
              label="Nb Participants"
              width="125"
              align="center"
            >
              <template v-if="!scope.row.children" slot-scope="scope">
                <input
                  class="form-control"
                  type="number"
                  v-model="scope.row.nbOfParticipants"
                  min="0"
                  step="1"
                  :disabled="inputDisabled(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column align="center" width="120">
              <template v-if="!scope.row.children" slot-scope="scope">
                <button
                  v-if="!scope.row.validated"
                  type="button"
                  class="btn btn-sm btn-success"
                  :disabled="validationDisabled(scope.row)"
                  @click="onValidate(scope.row)"
                >
                  Valider
                </button>
                <span v-else class="text-success"
                  ><i class="fa fa-check"></i
                ></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    sessionsToValidate: {
      type: Number,
      required: true,
    },
    validated: {
      type: Boolean,
      required: true,
    },
    sessions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      data: [],
    };
  },

  mounted() {
    this.data = this.sessions;
  },

  methods: {
    fetchAllSession(boolean) {
      this.$emit("fetchApi", boolean);
    },

    getLiveType(type) {
      switch (type) {
        case "live":
          return "Live";
        case "small":
          return "Visio";
        default:
          return "";
      }
    },

    inputDisabled(session) {
      return (
        this.validated ||
        !session.scheduledAt ||
        moment(session.scheduledAt).isAfter(moment())
      );
    },

    validationDisabled(session) {
      if (this.inputDisabled(session)) return true;

      if (session.nbOfParticipants === 0) return false;

      return !session.nbOfParticipants;
    },

    onValidate(session) {
      this.$emit("updateSession", session);
    },

    toggleRowExpand(row) {
      this.$refs.table.toggleRowExpansion(row);
    },
  },

  watch: {
    sessions(sessions) {
      this.data = sessions;
    },
  },
};
</script>
