<template>
  <div class="card" v-if="isAdmin($store.state.user) || commercialUser">
    <div class="card-header">
      <i class="fa fa-align-justify"></i> Paiements encaissés
    </div>
    <div class="card-block">
      <div class="row">
        <div :class="`col-12 col-md-${colSize} d-flex align-items-stretch`">
          <div class="card w-100">
            <div class="card-header text-bold">
              Répartition totale CA
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold"> Total </span> :
                {{ localizedPrice(total) || "0.00€" }}
              </div>
              <div class="text-muted">
                Remboursements :
                {{ localizedPrice(totalRefund) || "0.00€" }} ({{
                  ((totalRefund / (total + totalRefund)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
            </div>
            <div class="card-block">
              <span class="text-bold"> Acquisition </span>:
              {{ localizedPrice(totalAcquisition) || "0.00€" }}
              ({{
                ((totalAcquisition / total) * 100)
                  .toFixed(2)
                  .replace(".", ",") + "%"
              }})
            </div>
            <div class="card-block">
              <span class="text-bold"> Fidélisation </span>:
              {{ localizedPrice(totalFidelisation) || "0.00€" }}
              ({{
                ((totalFidelisation / total) * 100)
                  .toFixed(2)
                  .replace(".", ",") + "%"
              }})
            </div>
          </div>
        </div>

        <div
          :class="`col-12 col-md-${colSize}`"
          v-for="(com, index) in commercials"
          :key="index"
        >
          <div class="card">
            <div
              :class="`card-header ${com.color ? 'text-white' : ''}`"
              :style="`background-color: ${com.color ? com.color : '#F9F9FA'}`"
            >
              <span class="text-bold">{{ com.label }}</span>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Total</span> :
                {{ localizedPrice(totalCommercial(com)) || "0.00€" }}
                ({{
                  ((totalCommercial(com) / total) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div class="text-muted">
                Remboursements :
                {{ localizedPrice(totalRefundCommercial(com)) || "0.00€" }} ({{
                  (
                    (totalRefundCommercial(com) /
                      (totalCommercial(com) + totalRefundCommercial(com))) *
                    100
                  )
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Acquisition</span> :
                {{ localizedPrice(acquisitionCommercial(com)) || "0.00€" }} ({{
                  ((acquisitionCommercial(com) / totalCommercial(com)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div>
                <p class="mb-0">
                  Séance découverte:
                  <span v-html="seanceDecouverte(com)"></span>
                </p>
                <p class="mb-0">
                  Pack conversion: <span v-html="packConversion(com)"></span>
                </p>
                <p class="mb-0">
                  Pack one shot: <span v-html="packOneShot(com)"></span>
                </p>
                <p class="mb-0">
                  Pack autres: <span v-html="packAutres(com)"></span>
                </p>
              </div>
            </div>

            <div class="card-block">
              <div>
                <span class="text-bold">Fidélisation</span> :
                {{ localizedPrice(fidelisationCommercial(com)) || "0.00€" }}
                ({{
                  ((fidelisationCommercial(com) / totalCommercial(com)) * 100)
                    .toFixed(2)
                    .replace(".", ",") + "%"
                }})
              </div>
              <div>
                <p class="mb-0">
                  Pack Fidélisation:
                  <span v-html="packFidelisation(com)"></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

export default {
  props: {
    payments: {
      type: Array,
      required: true
    },

    commercials: {
      type: Array,
      required: true
    }
  },

  computed: {
    total() {
      const total = this.sumPayments(this.payments);

      return total;
    },

    totalRefund() {
      const refundedPayments = this.payments.filter(p => p.price < 0);

      const total = this.sumPayments(refundedPayments);

      return Math.abs(total);
    },

    totalAcquisition() {
      const acquisitionPayments = this.payments.filter(
        p => p.order.isAcquisition
      );
      const total = this.sumPayments(acquisitionPayments);

      return total;
    },

    totalFidelisation() {
      const fidelisationPayments = this.payments.filter(
        p => !p.order.isAcquisition
      );

      const total = this.sumPayments(fidelisationPayments);

      return total;
    },

    colSize() {
      if (this.commercials?.length === 1) {
        return "6";
      }

      if (this.commercials?.length > 1) {
        return "4";
      }

      return "12";
    },

    commercialUser() {
      if (
        this.$store.state.user.commercial &&
        this.$store.state.user.commercial.pseudo
      ) {
        return {
          value: this.$store.state.user.email,
          label: this.$store.state.user.commercial.pseudo
        };
      }

      return false;
    }
  },

  methods: {
    getCommercialPayments(commercial) {
      const commercialPayments = this.payments.filter(p => {
        if (p.order.commercials) {
          const orderCommercial = p.order.commercials.find(
            c => commercial.value === c.userEmail
          );

          if (orderCommercial) {
            return (
              !orderCommercial.relatedSince ||
              (orderCommercial.relatedSince &&
                moment(orderCommercial.relatedSince).isSame(
                  p.order.createdAt,
                  "day"
                ))
            );
          }
        }
      });

      return commercialPayments;
    },

    sumPayments(payments) {
      let total = 0;

      payments.forEach(payment => {
        total += payment.price;
      });

      return total;
    },

    totalCommercial(commercial) {
      const commercialPayments = this.getCommercialPayments(commercial);
      const total = this.sumPayments(commercialPayments);

      return total;
    },

    totalRefundCommercial(commercial) {
      const commercialPayments = this.getCommercialPayments(commercial);
      const filteredCommercialPayments = commercialPayments.filter(
        p => p.price < 0
      );

      const total = this.sumPayments(filteredCommercialPayments);

      return Math.abs(total);
    },

    acquisitionCommercial(commercial) {
      const commercialPayments = this.getCommercialPayments(commercial);
      const acquisitionPayments = commercialPayments.filter(
        p => p.order.isAcquisition
      );
      const total = this.sumPayments(acquisitionPayments);

      return total;
    },

    fidelisationCommercial(commercial) {
      const commercialPayments = this.getCommercialPayments(commercial);
      const fidelisationPayments = commercialPayments.filter(
        p => !p.order.isAcquisition
      );
      const total = this.sumPayments(fidelisationPayments);

      return total;
    },

    seanceDecouverte(com) {
      const commercialPayments = this.getCommercialPayments(com);
      const acquisitionPayments = commercialPayments
        .filter(p => p.order.isConversion)
        .filter(p => !p.order.isFidelisation)
        .filter(p => p.order.isAcquisition);

      if (acquisitionPayments.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumPayments(acquisitionPayments);

      return (
        "<strong>" +
        acquisitionPayments.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packConversion(com) {
      const commercialPayments = this.getCommercialPayments(com);
      const acquisitionPayments = commercialPayments
        .filter(p => !p.order.isConversion)
        .filter(p => p.order.isFidelisation)
        .filter(p => p.order.isAcquisition);

      if (acquisitionPayments.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumPayments(acquisitionPayments);

      return (
        "<strong>" +
        acquisitionPayments.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packOneShot(com) {
      const commercialPayments = this.getCommercialPayments(com);
      const acquisitionPayments = commercialPayments
        .filter(p => p.order.isConversion)
        .filter(p => p.order.isFidelisation)
        .filter(p => p.order.isAcquisition);

      if (acquisitionPayments.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumPayments(acquisitionPayments);

      return (
        "<strong>" +
        acquisitionPayments.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },
    packFidelisation(com) {
      const commercialPayments = this.getCommercialPayments(com);
      const fidelisationPayments = commercialPayments
        .filter(p => !p.order.isConversion)
        .filter(p => p.order.isFidelisation)
        .filter(p => !p.order.isAcquisition);

      return "<strong>" + fidelisationPayments.length + "</strong>";
    },
    packAutres(com) {
      const commercialPayments = this.getCommercialPayments(com);
      const acquisitionPayments = commercialPayments
        .filter(p => !p.order.isConversion)
        .filter(p => !p.order.isFidelisation)
        .filter(p => p.order.isAcquisition);

      if (acquisitionPayments.length === 0) {
        return "<strong>0</strong>";
      }

      const total = this.sumPayments(acquisitionPayments);

      return (
        "<strong>" +
        acquisitionPayments.length +
        "</strong> | " +
        this.localizedPrice(total) +
        " (" +
        (
          parseFloat(total) /
          (parseFloat(this.acquisitionCommercial(com)) / 100)
        )
          .toFixed(2)
          .replace(".", ",") +
        "%)"
      );
    },

    localizedPrice(price) {
      return (parseFloat(price) / 100)
        .toLocaleString("fr-FR", {
          style: "currency",
          currency: "EUR"
        })
        ?.replace(",", ".");
    }
  }
};
</script>

<style scoped>
.text-bold {
  font-weight: 700;
}
</style>
