<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-graph"></i> Prospects / Clients perdus
    </div>

    <div class="card-block">
      <div class="row">
        <div class="col-12 col-lg-6">
          <span class="font-weight-bold d-block mb-1"
            >Prospects ({{ prospectsTotal }})</span
          >
          <div
            v-if="prospectsTotal > 0"
            class="row d-flex flex-column justify-content-center"
          >
            <div class="col-12 mb-2">
              <LostChart
                :clients="prospects"
                :total="prospectsTotal"
                :lostReasons="lostReasons"
              />
            </div>
            <div class="col-12 d-flex flex-column align-items-end">
              <ChartLabel
                v-for="label in prospectsLabels"
                :key="label.value"
                :label="label"
                class="col-12 col-lg-6"
              />
            </div>
          </div>
          <div v-else class="alert alert-info">
            Aucun prospect perdu pour cette période
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <span class="font-weight-bold d-block mb-1"
            >Clients ({{ clientsTotal }})</span
          >
          <div
            v-if="clientsTotal > 0"
            class="row d-flex flex-column justify-content-center"
          >
            <div class="col-12 mb-2">
              <LostChart
                :clients="clients"
                :total="clientsTotal"
                :lostReasons="lostReasons"
              />
            </div>
            <div class="col-12 d-flex flex-column align-items-end">
              <ChartLabel
                v-for="label in clientsLabels"
                :key="label.value"
                :label="label"
                class="col-12 col-lg-6"
              />
            </div>
          </div>
          <div v-else class="alert alert-info">
            Aucun client perdu pour cette période
          </div>
        </div>
      </div>

      <div v-if="commercials.length > 0" class="col-12">
        <div
          v-for="(com, i) in commercials"
          :key="com.value"
          :class="`card col-12 p-0 ${i === 0 ? 'mt-2' : ''}`"
        >
          <div
            :class="`card-header ${com.color ? 'text-white' : ''}`"
            :style="`background-color: ${com.color ? com.color : '#F9F9FA'}`"
          >
            <span class="text-bold">{{ com.label }}</span>
          </div>

          <div class="card-block">
            <div class="row">
              <div class="col-12 col-lg-6">
                <span class="font-weight-bold d-block mb-1"
                  >Prospects ({{
                    getCommercialProspectsData(com.value).total
                  }})</span
                >
                <div
                  v-if="getCommercialProspectsData(com.value).total > 0"
                  class="row d-flex flex-column justify-content-center"
                >
                  <div class="col-12 mb-2">
                    <LostChart
                      :clients="getCommercialProspectsData(com.value).prospects"
                      :total="getCommercialProspectsData(com.value).total"
                      :lostReasons="lostReasons"
                    />
                  </div>
                  <div class="col-12 d-flex flex-column align-items-end">
                    <ChartLabel
                      v-for="label in getCommercialProspectsData(com.value)
                        .labels"
                      :key="label.value"
                      :label="label"
                      class="col-12 col-lg-6"
                    />
                  </div>
                </div>
                <div v-else class="alert alert-info">
                  Aucun prospect perdu pour cette période
                </div>
              </div>

              <div class="col-12 col-lg-6">
                <span class="font-weight-bold d-block mb-1"
                  >Clients ({{
                    getCommercialClientsData(com.value).total
                  }})</span
                >
                <div
                  v-if="getCommercialClientsData(com.value).total > 0"
                  class="row d-flex flex-column justify-content-center"
                >
                  <div class="col-12 mb-2">
                    <LostChart
                      :clients="getCommercialClientsData(com.value).clients"
                      :total="getCommercialClientsData(com.value).total"
                      :lostReasons="lostReasons"
                    />
                  </div>
                  <div class="col-12 d-flex flex-column align-items-end">
                    <ChartLabel
                      v-for="label in getCommercialClientsData(com.value)
                        .labels"
                      :key="label.value"
                      :label="label"
                      class="col-12 col-lg-6"
                    />
                  </div>
                </div>
                <div v-else class="alert alert-info">
                  Aucun client perdu pour cette période
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LostChart from "../components/LostChart";
import ChartLabel from "../components/ChartLabel";

import { mapGetters } from "vuex";

export default {
  components: {
    LostChart,
    ChartLabel,
  },

  props: {
    clients: {
      type: Object,
      default: null,
    },

    prospects: {
      type: Object,
      default: null,
    },

    clientsTotal: {
      type: Number,
      default: 0,
    },

    prospectsTotal: {
      type: Number,
      default: 0,
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    showSources: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    clientsLabels() {
      if (this.clients) {
        const labels = this.getLabels(this.clients, this.clientsTotal);

        return labels;
      }

      return [];
    },

    prospectsLabels() {
      if (this.prospects) {
        const labels = this.getLabels(this.prospects, this.prospectsTotal);

        return labels;
      }

      return [];
    },
  },

  methods: {
    getLabels(clients, total) {
      const labels = [];

      for (const [key, value] of Object.entries(clients)) {
        const percentage = ((value.length / total) * 100)
          .toFixed(2)
          .replace(".", ",");

        if (key === "no-reason") {
          const label = {
            value: key,
            name: `Aucune raison (${value.length}) (soit ${percentage}%)`,
            color: "#d3d3d3",
            percentage,
          };

          if (this.showSources) {
            label.details = this.getSourcesDetails(value);
          }

          labels.push(label);
        } else {
          const lostReason = this.lostReasons.find(
            (reason) => reason.value === key
          );
          const label = {
            value: key,
            name: `${lostReason.label} (${value.length}) (soit ${percentage}%)`,
            color: lostReason.color,
            percentage,
          };

          if (this.showSources) {
            label.details = this.getSourcesDetails(value);
          }

          labels.push(label);
        }
      }

      return labels.sort(
        (a, b) => parseFloat(b.percentage) - parseFloat(a.percentage)
      );
    },

    getCommercialProspectsData(email) {
      let prospects = {};
      let total = 0;
      let labels = [];

      if (this.prospects) {
        for (const [key, value] of Object.entries(this.prospects)) {
          if (value?.length > 0) {
            const filteredValue = value.filter(
              (client) => client.commercials[0]?.commercial?.email === email
            );

            if (filteredValue.length > 0) {
              prospects[key] = filteredValue;
              total += filteredValue.length;
            }
          }
        }
      }

      if (prospects) {
        labels = this.getLabels(prospects, total);
      }

      return { prospects, total, labels };
    },

    getCommercialClientsData(email) {
      let clients = {};
      let total = 0;
      let labels = [];

      if (this.clients) {
        for (const [key, value] of Object.entries(this.clients)) {
          if (value?.length > 0) {
            const filteredValue = value.filter(
              (client) => client.commercials[0]?.commercial?.email === email
            );

            if (filteredValue.length > 0) {
              clients[key] = filteredValue;
              total += filteredValue.length;
            }
          }
        }
      }

      if (clients) {
        labels = this.getLabels(clients, total);
      }

      return { clients, total, labels };
    },

    getSourcesDetails(clients) {
      const sourcesValues = [];
      const campaignsValues = [];
      const contentsValues = [];

      for (const client of clients) {
        const { utm_source, utm_campaign, utm_content } = client.marketing;

        if (utm_source && !sourcesValues.includes(utm_source)) {
          sourcesValues.push(utm_source);
        }

        if (utm_campaign && !campaignsValues.includes(utm_campaign)) {
          campaignsValues.push(utm_campaign);
        }

        if (utm_content && !contentsValues.includes(utm_content)) {
          contentsValues.push(utm_content);
        }
      }

      const sources = sourcesValues.map((source) => {
        const sourceClients = clients.filter(
          (client) => client.marketing.utm_source === source
        );

        const percentage = ((sourceClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: source, value: sourceClients.length, percentage };
      });

      const campaigns = campaignsValues.map((campaign) => {
        const campaignClients = clients.filter(
          (client) => client.marketing.utm_campaign === campaign
        );

        const percentage = ((campaignClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: campaign, value: campaignClients.length, percentage };
      });

      const contents = contentsValues.map((content) => {
        const contentClients = clients.filter(
          (client) => client.marketing.utm_content === content
        );

        const percentage = ((contentClients.length / clients.length) * 100)
          .toFixed(2)
          .replace(".", ",");

        return { label: content, value: contentClients.length, percentage };
      });

      return { sources, campaigns, contents };
    },
  },
};
</script>
