<template>
  <div class="wrapper">
    <router-view></router-view>

    <div class="animated fadeIn">
      <div class="card" v-if="['teammembers'].indexOf($route.name) > -1">
        <div class="card-header">
          <i class="fa fa-help"></i> Membres de l'équipe
          <button
            type="button"
            class="btn btn-sm btn-primary float-right"
            @click="modalNewMember = true"
            v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
          >
            <i class="fa fa-plus"></i>&nbsp; Ajouter
          </button>
        </div>
        <div class="card-block">
          <tabs v-model="activeTab" @input="changeTab">
            <tab header="Actif">
              <SettingsTeamMembers :users="activeUser" @loadUser="fetchUsers" />
            </tab>
            <tab header="Archivé">
              <SettingsTeamMembers
                :users="archivedUser"
                @loadUser="fetchUsers"
              />
            </tab>
          </tabs>
        </div>
      </div>
    </div>

    <modal
      title="Ajouter un membre de l'équipe"
      v-model="modalNewMember"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleNewMember()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Ajouter un membre de l'équipe</h4>
      </div>
      <div class="form-group">
        <label>Email*</label>
        <input
          v-model="form.email"
          type="text"
          class="form-control"
          placeholder="exemple : michael@justcoaching.fr"
          :class="this.formErrors.email ? 'has-error' : ''"
        />
      </div>
      <div class="form-group">
        <label>Mot de passe*</label>
        <PasswordInput
          v-model="form.password"
          :hasError="this.formErrors.password"
        />
      </div>
      <div class="form-group">
        <label>Prénom</label>
        <input
          v-model="form.firstName"
          type="text"
          class="form-control"
          placeholder="exemple : Michael"
        />
      </div>
      <div class="form-group">
        <label>Nom de famille</label>
        <input
          v-model="form.lastName"
          type="text"
          class="form-control"
          placeholder="exemple : Denis"
        />
      </div>
      <div class="form-group" style="margin-top: 30px">
        <span style="margin-right: 20px">Équipier</span
        ><el-switch v-model="form.admin" /><span style="margin-left: 20px"
          >Admin</span
        >
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import PasswordInput from "../components/form/PasswordInput";
import SettingsTeamMembers from "../components/SettingsTeamMembers";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Modal,
    PasswordInput,
    SettingsTeamMembers,
    Tabs,
    Tab,
  },
  data() {
    return {
      users: [],
      modalNewMember: false,
      form: {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        admin: false,
      },
      formErrors: {
        email: false,
        password: false,
      },
      activeTab: 0,
    };
  },

  created() {
    let activeTab = sessionStorage.getItem("SettingMemberTab");

    if (activeTab) {
      this.activeTab = parseInt(activeTab);
    }

    this.fetchUsers();
  },

  watch: {
    $route() {
      this.users = [];
      this.fetchUsers();
    },
  },

  computed: {
    activeUser() {
      return this.users.filter((u) => !u.archived);
    },

    archivedUser() {
      return this.users.filter((u) => u.archived);
    },
  },

  methods: {
    async fetchUsers() {
      const { data } = await this.$api.get("/users/team").catch(() => {
        this.$router.push({
          name: "dashboard",
        });
      });

      this.users = data;
    },

    toggleRole(user, event) {
      this.$confirm({
        message: "Attention, cela va changer le statut Administrateur !",
        onConfirm: async () => {
          let newRole = user.role;

          if (event) {
            newRole = newRole | this.getRole("admin");
          } else {
            newRole = newRole & ~this.getRole("admin");
            newRole = newRole | this.getRole("member");
          }

          try {
            await this.$api.put("/users", {
              email: user.email,
              role: newRole,
            });

            this.fetchUsers();
          } catch (e) {
            this.$errorToast(e);
          }
        },
      });
    },

    toggleLeader(user, event) {
      if (event) {
        this.$confirm({
          message: "Attention, cela va retirer le référent commercial actuel !",
          onConfirm: async () => {
            try {
              await this.$api.put("/users/setleader", {
                email: user.email,
              });

              this.fetchUsers();
            } catch (e) {
              this.$errorToast(e);
            }
          },
        });
      } else {
        this.$errorToast(
          "Pour retirer ce rôle, vous devez désigner un autre référent commercial."
        );
      }
    },

    checkFields() {
      this.formErrors.email = !this.form.email;
      this.formErrors.password = !this.form.password;

      return (
        Object.values(this.formErrors).filter((error) => error).length === 0
      );
    },

    handleNewMember() {
      if (!this.checkFields()) {
        return;
      }

      // try adding a new user
      this.$api
        .post("/users", {
          email: this.form.email,
          password: this.form.password,
          role: this.form.admin ? "admin" : "member",
          firstName: this.form.firstName,
          lastName: this.form.lastName,
        })
        .then(() => {
          this.modalNewMember = false;
          this.fetchUsers();
        })
        .catch(() => {
          // retrieve user and try updating role
          const role = this.form.admin
            ? this.getRole("admin")
            : this.getRole("member");
          this.$api
            .put("/users", {
              email: this.form.email,
            })
            .then((res) => {
              let newRole = res.data.role | role;
              this.$api
                .put("/users", {
                  email: this.form.email,
                  role: newRole,
                })
                .then(() => {
                  this.modalNewMember = false;
                  this.fetchUsers();
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },

    changeTab(index) {
      sessionStorage.setItem("SettingMemberTab", index);
    },
  },
};
</script>

<style scoped>
.has-error {
  border: 1px solid #ff5454 !important;
  border-radius: 4px;
}
</style>
e
