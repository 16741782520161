<template>
  <div class="animated fadeIn">
    <InvoiceFilters
      :filters="filters"
      :concepts="concepts"
      :merchants-options="merchantsOptions"
      :commercials="commercials"
      :zipcodes="zipcodes"
      :invoiceSeries="invoiceSeries"
      :showNova="showNova"
      :unpaid="true"
      @load="(form) => loadInvoices(form)"
      @download="(form) => loadInvoices(form, true)"
      @export="(form) => loadInvoices(form, false, true)"
      @toggleShowNova="showNova = !showNova"
    />

    <div class="card card-inverse card-info text-center" v-if="loading">
      <div class="card-block">
        <blockquote class="card-blockquote">Chargement en cours ...</blockquote>
      </div>
    </div>

    <div v-else>
      <UnpaidCard :commercials="filters.commercials" :orders="orders" />

      <tabs v-model="activeTab" class="empty-tab">
        <tab :header="headerAll"></tab>
        <tab :header="headerDelay"></tab>
        <tab :header="headerUnpaid"></tab>
      </tabs>

      <InvoicesTable
        @createRefund="createRefund"
        :isUnpaid="true"
        v-if="activeTab === 0"
        :orders="orders"
      />
      <InvoicesTable
        @createRefund="createRefund"
        :isUnpaid="true"
        v-if="activeTab === 1"
        :orders="delays"
      />
      <InvoicesTable
        @createRefund="createRefund"
        :isUnpaid="true"
        v-if="activeTab === 2"
        :orders="unpaids"
      />
    </div>

    <RefundModal
      :order="orderToEdit"
      :modalShow="openModal"
      @close="onModalClose"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import moment from "moment-timezone";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

import InvoiceFilters from "../components/InvoiceFilters";
import InvoicesTable from "../components/InvoicesTable";
import UnpaidCard from "../components/UnpaidCard";
import RefundModal from "../components/RefundModal";

export default {
  components: {
    Tabs,
    Tab,
    InvoiceFilters,
    InvoicesTable,
    UnpaidCard,
    RefundModal,
  },
  data() {
    return {
      loading: false,
      activeTab: 0,
      concepts: [],
      merchantsOptions: [],
      commercials: [],
      orders: [],
      showNova: false,
      filters: {
        begin: moment().startOf("month").startOf("day").format("YYYY-MM-DD"),
        end: moment().endOf("month").startOf("day").format("YYYY-MM-DD"),
        concept: null,
        invoiceSerieCode: null,
        client: null,
        status: null,
        zipcodes: null,
        commercials: [],
      },
      openModal: false,
      orderToEdit: null,
    };
  },

  async created() {
    this.filters.begin = moment()
      .subtract(6, "month")
      .startOf("month")
      .format("YYYY-MM-DD hh:mm");
    this.filters.end = moment().endOf("month").format("YYYY-MM-DD hh:mm");

    await this.getConcepts();
    await this.getMerchants();
    await this.getCommercials();

    const currentCommercial = this.commercials.find((c) => {
      if (this.$store.state.user.email === c.value) {
        return c;
      }
    });

    if (currentCommercial) {
      this.filters.commercials.push(currentCommercial);
    }

    await this.loadInvoices();
  },

  computed: {
    unpaids() {
      return this.orders.filter((o) => o.unpaid);
    },

    delays() {
      return this.orders.filter((o) => o.delay && !o.unpaid);
    },

    headerAll() {
      return `Tout (${this.orders.length})`;
    },

    headerDelay() {
      return `Retard (${this.delays.length}) <span title="Dernière deadline > 7 jours" class="fa fa-info-circle"></span>`;
    },

    headerUnpaid() {
      return `Critique (${this.unpaids.length}) <span title="Dernière deadline  > 31 jours" class="fa fa-info-circle"></span>`;
    },

    ...mapState({
      merchant: (state) => state.merchant,
      invoiceSeries: (state) => state.invoiceSeries,
    }),

    ...mapGetters({
      departments: "getDepartments",
    }),

    zipcodes() {
      return this.departments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },
  },
  methods: {
    async loadInvoices(form, download, excel) {
      if (form) {
        this.filters = form;
      }

      let zipcodes = null;

      if (this.filters.zipcodes && this.filters.zipcodes.length > 0) {
        const values = this.filters.zipcodes.map(({ value }) => value);

        if (!values.includes("all")) {
          zipcodes = values;
        }
      }

      this.loading = true;

      const { data } = await this.$api.get(`/invoices/unpaid`, {
        params: {
          download: download || null,
          excel: excel || null,
          begin: this.filters.begin,
          end: this.filters.end,
          concept: this.filters.concept,
          merchantId: this.filters.merchant
            ? this.filters.merchant.value
            : null,
          invoiceSerieCode: this.filters.invoiceSerieCode,
          client: this.filters.client ? this.filters.client.value : null,
          status: this.filters.status ? this.filters.status.value : null,
          zipcodes,
          match: this.filters.match,
          commercials:
            this.filters.commercials &&
            this.filters.commercials.map((c) => c.value),
        },
      });

      this.loading = false;

      if (download || excel) {
        window.location.href = axios.defaults.baseURL + data.archive;
      } else {
        this.orders = data.filter((i) => i.status !== "unpaid");

        for (let order of this.orders) {
          const amountDiff = this.amountDiff(order);

          order.amountDiff = amountDiff;
        }
      }
    },

    async getCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },

    async getMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    goToPDF(pdf) {
      return axios.defaults.baseURL + "uploads/invoice/" + pdf + ".pdf";
    },

    goToClient(client) {
      let routeData = this.$router.resolve({
        name: "client-details",
        params: {
          id: client.id,
        },
      });
      window.open(routeData.href, "_blank");
    },

    goToOrder(order) {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },

    createRefund(order) {
      this.orderToEdit = order;
      this.openModal = true;
    },

    onModalClose(status) {
      if (status === "success") {
        this.orders = this.orders.filter(
          (order) => order.id !== this.orderToEdit.id
        );
      }

      this.openModal = false;
      this.orderToEdit = null;
    },

    amountDiff(order) {
      let payed = 0;

      for (let payment of order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return order.price - payed;
    },
  },
};
</script>

<style lang="scss">
.custom-multiselect {
  .multiselect {
    &__tags {
      min-height: 34px;
    }

    &__tag {
      margin-bottom: 0;
    }

    &__placeholder {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
}
</style>
