<template>
  <div class="animated fadeIn">
    <CARatiosFilters :default="filters" @update="handleFilters" />

    <div class="card">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <span><i class="icon-graph"></i> Coûts</span>
          <span v-if="totalCA">CA période: {{ formatPrice(totalCA) }}</span>
        </div>
      </div>
      <div class="card-block">
        <tabs v-model="activeTab">
          <tab header="Graph">
            <div class="d-flex justify-content-around">
              <div class="">
                <h3>
                  Ratios des dépenses
                  <i
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content:
                        'Les dépenses coachs sont la sommes des avoirs réglés sur la périodes, les autres dépenses sont celles renseigné dans le menu Marketing / Dépenses. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',
                      placement: 'bottom',
                    }"
                  ></i>
                </h3>
                <Pie :chart-data="chartData" :options="options"></Pie>
              </div>
              <div class="">
                <h3>
                  Ratios par concept
                  <i
                    class="fa fa-info-circle ml-05"
                    v-tooltip="{
                      content:
                        'Répartition de CA par concept. Vous pouvez cocher/décocher les options si-dessous pour afficher/cacher les options.',
                      placement: 'bottom',
                    }"
                  ></i>
                </h3>
                <Pie :chart-data="chartDataConcept" :options="options"></Pie>
              </div>
            </div>
          </tab>
          <tab header="Carte Coachs">
            <h3>
              Ratios par zone géographique
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content:
                    'Les dépenses correspondent au montant des avoirs réglés aux coachs sur la période. Le CA correspond aux montants des commandes payés ou non sur la période (les remboursements sont déduits).',
                  placement: 'bottom',
                }"
              ></i>
            </h3>
            <StatsCARatiosFranceMap :data="mapData" color-scheme="red" />
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapGetters } from "vuex";
import CARatiosFilters from "../components/CARatiosFilters";
import StatsCARatiosFranceMap from "../components/StatsCARatiosFranceMap";
import moment from "moment-timezone";
import Pie from "../components/chart/Pie";

export default {
  components: {
    Tabs,
    Tab,
    CARatiosFilters,
    StatsCARatiosFranceMap,
    Pie,
  },

  data() {
    return {
      activeTab: 0,
      defaultData: {
        total: 0,
        totalPercentage: 0,
      },
      filters: {
        begin: moment().startOf("month").toDate(),
        end: moment().toDate(),
      },
      stats: {
        orders: [],
        disbursments: [],
      },
      loading: false,
      data: [0, 0, 0, 0, 0],
      dataConcept: [],
      conceptsLabel: [],
      conceptsColor: [],
    };
  },

  async created() {
    const { data } = await this.$api.get("/concepts");

    for (const concept of data) {
      this.conceptsLabel.push(concept.name);
      this.conceptsColor.push(concept.managerColor);
    }
  },

  computed: {
    chartData() {
      return {
        labels: [
          "Coach",
          "Marketing",
          "Team Marketing",
          "Developpement",
          "Autres",
        ],
        datasets: [
          {
            backgroundColor: [
              "#0D0AFD",
              "#E46651",
              "#6C757D",
              "#FFC107",
              "#0DCAF0",
            ],
            data: this.data,
          },
        ],
      };
    },
    chartDataConcept() {
      return {
        labels: this.conceptsLabel,
        datasets: [
          {
            backgroundColor: this.conceptsColor,
            data: this.dataConcept,
          },
        ],
      };
    },

    options() {
      return {
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              const price = (
                parseFloat(data.datasets[0].data[tooltipItem.index]) / 100
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

              const total = data.datasets[0].data.reduce((a, d) => a + d, 0);
              const percent = (
                (100 * data.datasets[0].data[tooltipItem.index]) /
                total
              ).toFixed(2);
              return `${
                data.labels[tooltipItem.index]
              } - ${price}€ (${percent}%)`;
            },
          },
        },
      };
    },

    ...mapGetters({
      departments: "getDepartments",
    }),

    displayedDepartments() {
      const domTom = ["971", "972", "973", "974", "976"];
      const metroDepartments = this.departments.filter(
        (dpt) => !domTom.includes(dpt.code) && dpt.code !== "all"
      );

      return metroDepartments.map(({ code, name }) => {
        if (code === "all") {
          return { label: name, value: code };
        } else {
          return { label: code + " - " + name, value: code };
        }
      });
    },

    mapData() {
      let departments = [
        ...this.displayedDepartments.map((dpt) => ({
          ...dpt,
          data: this.defaultData,
        })),
      ];

      if (this.stats.orders && !this.loading) {
        departments.forEach((dpt) => {
          dpt.data = this.departmentCaInfo(dpt.value);
        });
      }

      return departments;
    },

    totalCA() {
      return this.stats.orders && this.getCA(this.stats.orders);
    },
  },

  methods: {
    async handleFilters(newFilters) {
      this.filters = newFilters;

      const { data } = await this.$api.get("/stats/ca-ratios/couts", {
        params: {
          begin: this.filters.begin,
          end: this.filters.end,
          merchantId: this.filters.merchant && this.filters.merchant.value,
          merchantLabel: this.filters.merchant && this.filters.merchant.label,
          concept: this.filters.concept && this.filters.concept.label,
        },
      });

      this.stats = data;
      this.updateChartData(data);
      this.updateChartDataConcept(data);
    },

    updateChartData(data) {
      const coach = data.disbursments.reduce((a, d) => a + d.price, 0);
      const marketing =
        data.expenses
          .filter(
            (e) =>
              !["Team Marketing", "Développement", "Autres"].includes(e.type)
          )
          .reduce((a, d) => a + d.amount, 0) * 100;
      const teamMarketing =
        data.expenses
          .filter((e) => "Team Marketing" === e.type)
          .reduce((a, d) => a + d.amount, 0) * 100;
      const developpement =
        data.expenses
          .filter((e) => "Développement" === e.type)
          .reduce((a, d) => a + d.amount, 0) * 100;
      const other =
        data.expenses
          .filter((e) => "Autres" === e.type)
          .reduce((a, d) => a + d.amount, 0) * 100;
      this.data = [coach, marketing, teamMarketing, developpement, other];
    },

    updateChartDataConcept(data) {
      this.dataConcept = [];

      for (const conceptName of this.conceptsLabel) {
        this.dataConcept.push(
          data.orders
            .filter((o) => o.concept === conceptName)
            .reduce((a, d) => a + d.price, 0)
        );
      }
    },

    departmentCaInfo(dpt) {
      const departmentOrders = this.stats.orders.filter((order) => {
        if (order.zipcode && typeof order.zipcode === "string") {
          return order.zipcode.slice(0, 2) === dpt;
        }
      });

      const departmentDisbursments = this.stats.disbursments.filter((d) => {
        if (d.zipcode && typeof d.zipcode === "string") {
          return d.zipcode.slice(0, 2) === dpt;
        }
      });

      const disbursments = this.getCA(departmentDisbursments);
      const ca = this.getCA(departmentOrders);

      let caPercentage = 0;

      if (ca) {
        caPercentage = (disbursments * 100) / ca;
      }

      if (isNaN(caPercentage)) {
        caPercentage = 0;
      }

      return { ca, disbursments, caPercentage };
    },

    getCA(orders) {
      let ca = 0;

      orders.forEach((order) => {
        if (!order || !order.zipcode) {
          return;
        }
        if (order.status === "refunded") {
          ca -= order.price;
        } else {
          ca += order.price;
        }
      });

      return ca;
    },
  },
};
</script>
