<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-camrecorder"></i> Tutoriel Live
    </div>
    <div class="card-block">
      <VimeoPlayer
        :videoId="liveTutorialId"
        title="Bienvenue sur Just Coaching Live"
      />
    </div>
  </div>
</template>

<script>
import VimeoPlayer from "../components/VimeoPlayer";

export default {
  components: {
    VimeoPlayer,
  },

  data() {
    return {
      liveTutorialId: "740724287",
    };
  },
};
</script>
