<template>
  <div class="sidebar hidden-print">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li class="nav-item" v-if="isTeam(user)">
          <router-link
            v-if="hasPermission(user, 'DASHBOARD_READ')"
            :to="'/dashboard'"
            class="nav-link"
            ><i class="icon-speedometer"></i> Dashboard</router-link
          >
          <router-link
            v-if="hasPermission(user, 'MYACCOUNT_READ')"
            :to="'/account'"
            class="nav-link"
            ><i class="icon-settings"></i> Mon compte</router-link
          >
          <router-link
            v-if="hasPermission(user, 'CONVERSION_READ')"
            :to="'/conversion'"
            class="nav-link"
            ><i class="icon-trophy"></i> Conversion</router-link
          >
          <router-link
            v-if="hasPermission(user, 'FIDELISATION_READ')"
            :to="'/fidelisation'"
            class="nav-link"
            ><i class="icon-action-redo"></i> Fidélisation</router-link
          >
          <router-link
            v-if="hasPermission(user, 'RANK_READ')"
            :to="'/rank'"
            class="nav-link"
            ><i class="icon-badge"></i> Classement</router-link
          >
          <router-link
            v-if="hasPermission(user, 'ORDERS_READ')"
            :to="'/orders'"
            class="nav-link"
            ><i class="icon-basket"></i> Commandes</router-link
          >
          <router-link
            v-if="hasPermission(user, 'BILLING_READ')"
            :to="'/billing'"
            class="nav-link"
            ><i class="icon-doc"></i> Facturation</router-link
          >
          <router-link
            v-if="hasPermission(user, 'ATTRIBUTIONS_READ')"
            :to="'/attributions'"
            class="nav-link"
            ><i class="icon-target"></i> Attributions</router-link
          >
          <router-link
            v-if="hasPermission(user, 'CLIENTS_READ')"
            :to="'/clients'"
            class="nav-link"
            ><i class="icon-people"></i> Prospects / Clients</router-link
          >
          <router-link
            v-if="hasPermission(user, 'GROUP_MENU_READ')"
            :to="'/groups'"
            class="nav-link"
            ><i class="icon-grid"></i> Groupes</router-link
          >
          <router-link
            v-if="hasPermission(user, 'USERS_MENU_READ')"
            :to="'/users'"
            class="nav-link"
            ><i class="icon-user"></i> Prestataires</router-link
          >
          <router-link
            v-if="hasPermission(user, 'LIVE_MENU_READ')"
            :to="'/live'"
            class="nav-link"
            ><i class="icon-camrecorder"></i> Live</router-link
          >
          <router-link
            v-if="hasPermission(user, 'STATS_READ')"
            :to="'/stats'"
            class="nav-link"
            ><i class="icon-graph"></i> Statistiques</router-link
          >
          <router-link
            v-if="hasPermission(user, 'SETTINGS_READ')"
            :to="'/settings'"
            class="nav-link"
            ><i class="icon-settings"></i> Paramètres</router-link
          >
        </li>
        <li class="nav-item" v-else>
          <router-link :to="'/account'" class="nav-link"
            ><i class="icon-settings"></i> Mon compte</router-link
          >
        </li>
      </ul>
    </nav>
    <a
      v-if="isTeam(user)"
      href="https://dev-manager.justcoaching.fr"
      class="nav-link"
      style="position: absolute; bottom: 60px; z-index: 99999"
    >
      Manager de test
    </a>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "sidebar",
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle("open");
    },
  },
};
</script>

<style lang="css">
.nav-link {
  cursor: pointer;
}
</style>
