<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-align-justify"></i> Liste des factures
      <span class="total inline-block float-right">
        Total ({{ formatPrice(totals.total) || "0.00€" }})
      </span>
      <span class="total inline-block float-right" v-if="showRefundedTotal">
        Avoirs ({{ formatPrice(totals.refunded) || "0.00€" }})
      </span>
      <span class="total inline-block float-right" v-if="showUnpaidTotal">
        Impayé traité ({{ formatPrice(totals.unpaid) || "0.00€" }})
      </span>
      <span class="total inline-block float-right" v-if="showPartialTotal">
        Partiel ({{ formatPrice(totals.partial) || "0.00€" }})
      </span>
      <span class="total inline-block float-right" v-if="showPayedTotal">
        Payé ({{ formatPrice(totals.payed) || "0.00€" }})
      </span>
    </div>
    <div class="card-block">
      <el-table class="table-condensed" :data="orders">
        <el-table-column
          label="Numéro"
          sortable
          prop="order.invoice.serial"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.invoice.serial || scope.row.invoice.filename }}
          </template>
        </el-table-column>
        <el-table-column
          label="Date"
          width=""
          sortable
          prop="order.invoice.createdAt"
        >
          <template slot-scope="scope">
            {{ formatDate(scope.row.invoice.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column label="Information">
          <template slot-scope="scope">
            <strong v-if="scope.row.invoice.company">{{
              scope.row.invoice.company
            }}</strong>
            <span v-if="scope.row.invoice.name">{{
              scope.row.invoice.name
            }}</span>
            <a
              v-if="scope.row.refClient"
              @click="(e) => goToClient(e, scope.row.refClient.id)"
              href="#"
              style="padding-left: 0.3rem"
            >
              <i class="icon-user"></i>
            </a>
            <br />{{ formatAddress(scope.row.invoice) }}
          </template>
        </el-table-column>
        <el-table-column label="Participants">
          <template slot-scope="scope">
            <div class="d-flex justify-content-center">
              <div
                class="text-primary"
                @click="openParticipantsTabs(scope.row)"
              >
                <i class="icon-user"></i>
                <span
                  v-if="
                    scope.row.orderGroup &&
                    scope.row.orderGroup.orders &&
                    scope.row.orderGroup.orders.length > 1
                  "
                >
                  {{ scope.row.orderGroup.orders.length }}
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isUnpaid"
          label="Montant dû"
          sortable
          prop="order.amountDiff"
        >
          <template slot-scope="scope">
            {{ formatPrice(scope.row.amountDiff) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="isUnpaid"
          label="Deadline"
          sortable
          prop="order.invoice.missedDeadline"
        >
          <template slot-scope="scope">
            {{ formatDate(scope.row.missedDeadline) }}
          </template>
        </el-table-column>
        <el-table-column label="Pack" sortable prop="order.session">
          <template slot-scope="scope">
            <button
              class="btn btn-link btn-sm p-0"
              @click="goToOrder(scope.row)"
            >
              <span
                v-if="
                  isUnpaid &&
                  scope.row.sessions &&
                  scope.row.sessions.length > 0
                "
                style="font-size: 12px; padding-left: 2px; padding-right: 2px"
                :class="`font-weight-bold bg-${completedSessionsRatio(
                  scope.row
                )}`"
                >{{ completedSessionsAmount(scope.row) }}/{{
                  scope.row.sessions.length
                }}</span
              >
              {{ formatPack(scope.row.session) }}
            </button>
          </template>
        </el-table-column>
        <el-table-column label="Montant" sortable prop="order.price">
          <template slot-scope="scope">
            {{ formatPrice(scope.row.price) }}
          </template>
        </el-table-column>
        <el-table-column label="Commerciaux">
          <template slot-scope="scope">
            <template v-for="(rel, index) of scope.row.commercials">
              <CommercialRel :key="`com-${index}`" :relation="rel" />
            </template>
          </template>
        </el-table-column>
        <el-table-column v-if="isUnpaid" label="Notes" width="220">
          <template slot-scope="scope">
            <NoteModal
              :adminNotes="scope.row.notes"
              :key="scope.row.id"
              permission="ORDERS_WRITE"
              @showComment="showComment(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="action-btns">
              <a
                v-if="!isUnpaid && scope.row.invoice.pdf"
                :href="goToPDF(scope.row.invoice.pdf)"
                class="btn btn-sm btn-outline-primary"
              >
                <i class="icon-cloud-download"></i> Télécharger
              </a>

              <button
                v-if="!isUnpaid && scope.row.refClient"
                class="btn btn-sm"
                @click="goToClient(null, scope.row.refClient.id)"
              >
                <i class="icon-people"></i>
              </button>

              <PaymentsHasTroubles :order="scope.row" />

              <button
                v-if="
                  isUnpaid && hasPermission($store.state.user, 'UNPAID_WRITE')
                "
                class="btn btn-sm btn-danger mr-1"
                @click="createRefund(scope.row)"
              >
                <i class="fa fa-times" />
              </button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <modal
      v-if="modalUpdateComment.order"
      title="Notes"
      v-model="modalUpdateComment.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment(modalUpdateComment.order)"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.order.notes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import PaymentsHasTroubles from "./PaymentsHasTrouble.vue";
import NoteModal from "./form/NoteModal";
import Modal from "vue-strap/src/Modal";
import CommercialRel from "../components/CommercialRel";

export default {
  components: {
    PaymentsHasTroubles,
    NoteModal,
    Modal,
    CommercialRel,
  },
  props: {
    orders: {
      type: Array,
      required: true,
    },
    showPayedTotal: {
      type: Boolean,
      default: false,
    },
    showPartialTotal: {
      type: Boolean,
      default: false,
    },
    showUnpaidTotal: {
      type: Boolean,
      default: false,
    },
    showRefundedTotal: {
      type: Boolean,
      default: false,
    },
    isUnpaid: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modalUpdateComment: {
        showComment: false,
        order: null,
      },
    };
  },

  computed: {
    totals() {
      let totals = {
        total: 0,
        payed: 0,
        partial: 0,
        refunded: 0,
        unpaid: 0,
      };

      this.orders.forEach((order) => {
        switch (order.status) {
          case "payed":
            totals.payed += order.price;
            break;
          case "partial":
            totals.partial += order.price;
            break;
          case "refunded":
            totals.refunded += order.price;
            break;
          case "unpaid":
            totals.unpaid += order.price;
            break;
        }
      });

      totals.total =
        totals.payed + totals.partial + totals.unpaid - totals.refunded;

      return totals;
    },
  },

  methods: {
    goToPDF(pdf) {
      return axios.defaults.baseURL + "uploads/invoice/" + pdf + ".pdf";
    },

    goToClient(e, id) {
      if (e) e.preventDefault();

      let routeData = this.$router.resolve({
        name: "client-details",
        params: {
          id,
        },
      });
      window.open(routeData.href, "_blank");
    },

    goToOrder(order) {
      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });

      window.open(route.href, "_blank");
    },

    async updateComment(order) {
      await this.$api
        .put("/orders/" + order.id, {
          notes: order.notes,
        })
        .catch((err) => {
          console.log(err);
        });

      this.modalUpdateComment.showComment = false;
    },

    createRefund(order) {
      this.$emit("createRefund", order);
    },

    showComment(order) {
      this.modalUpdateComment.showComment = true;
      this.modalUpdateComment.order = order;
    },

    completedSessionsAmount(order) {
      const completedSessions = order.sessions.filter(
        (order) => order.completed
      );

      return completedSessions.length;
    },

    completedSessionsRatio(order) {
      const completedSessions = this.completedSessionsAmount(order);

      const ratio = completedSessions / order.sessions.length;

      if (ratio < 1 / 3) {
        return "notice";
      } else if (ratio >= 1 / 3 && ratio < 2 / 3) {
        return "warning";
      } else if (ratio >= 2 / 3) {
        return "danger";
      } else {
        return "";
      }
    },

    openParticipantsTabs(order) {
      let ids = [order.refClient.id];

      if (order.orderGroup?.orders?.length > 0) {
        ids = [];

        for (const participantOrder of order.orderGroup.orders) {
          ids.push(participantOrder.refClient.id);
        }
      }

      ids.forEach((id) => {
        this.goToClient(null, id);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btns {
  display: flex;
  justify-content: space-between;
}

.bg-notice {
  background-color: #fcfc2d;
}
</style>
