<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-phone"></i>Ringover
      <div class="pull-right form-inline">
        <label class="pr-1" for="">Depuis le</label>
        <datepicker
          v-model="untilDate"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
          @input="fetchCalls()"
        ></datepicker>
      </div>
    </div>
    <div class="card-block">
      <Loader v-if="isLoading" :isVisible="isLoading" size="small" />
      <el-table
        v-else
        :data="calls"
        :default-sort="{ prop: 'start_time', order: 'descending' }"
        max-height="600"
      >
        <el-table-column label="Statut" width="140" prop="last_state" sortable>
          <template slot-scope="scope">
            <RingoverCallState
              :direction="scope.row.direction"
              :state="scope.row.last_state"
            />
          </template>
        </el-table-column>
        <el-table-column label="Date" width="180" prop="start_time" sortable>
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.start_time) }}
          </template>
        </el-table-column>
        <el-table-column
          label="Utilisateur"
          width="180"
          prop="user.concat_name"
          sortable
        >
          <template slot-scope="scope">
            <template v-if="scope.row.user">
              <span
                v-if="getCommercialData(scope.row.user)"
                class="badge"
                :style="`background-color: ${
                  getCommercialData(scope.row.user).color || '#292b2c'
                }`"
              >
                {{
                  getCommercialData(scope.row.user).pseudo ||
                  scope.row.user.concat_name
                }}
              </span>
              <span v-else>
                {{ scope.row.user.concat_name }}
              </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="Enregistrement"
          width="auto"
          prop="incall_duration"
          sortable
        >
          <template slot-scope="scope">
            <audio controls v-if="scope.row.record" :src="scope.row.record" />
            <audio
              controls
              v-if="scope.row.voicemail"
              :src="scope.row.voicemail"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import RingoverCallState from "../components/RingoverCallState";
import Loader from "../components/Loader";

export default {
  components: {
    Datepicker,
    RingoverCallState,
    Loader,
  },

  props: {
    phone: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      untilDate: undefined,
      calls: [],
      commercials: [],
    };
  },

  async created() {
    this.untilDate = moment().subtract(1, "months").toDate();

    await this.fetchCalls();
    await this.fetchCommercials();
  },

  methods: {
    async fetchCalls() {
      this.isLoading = true;

      const { data } = await this.$api.get("/ringover/calls-by-phone", {
        params: {
          phone: this.phone,
          untilDate: this.untilDate,
        },
      });

      this.calls = data;
      this.isLoading = false;
    },

    async fetchCommercials() {
      let commercials = [];

      for (const call of this.calls) {
        if (call.user?.email && !commercials.includes(call.user.email)) {
          commercials.push(call.user.email);
        }
      }

      if (commercials.length > 0) {
        const { data } = await this.$api.get("/users/commercials/search", {
          params: {
            commercials,
          },
        });

        this.commercials = data;
      }
    },

    getCommercialData(ringoverUser) {
      const commercial = this.commercials.find(
        (com) => com.email === ringoverUser.email
      );

      return commercial?.commercial;
    },
  },
};
</script>
