<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="col-sm-12">
          <span class="small">
            Changer les dates, supprimera les filtres (car ils sont générés a
            partir des dates)
          </span>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de début</label>
          <datepicker
            v-model="dates.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de fin</label>
          <datepicker
            v-model="dates.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="updateDate()"
          ></datepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="small">
            Appliquer les filtres pour voir les résultats ({{ countClient }}
            prospects)
          </div>
          <label for="">Filtres</label>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Sources</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.sources"
            :options="values.utm_sources"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Campaigns</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.campaigns"
            :options="values.utm_campaigns"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>

        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Mediums</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.mediums"
            :options="values.utm_mediums"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Terms</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.terms"
            :options="values.utm_terms"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-2 col-sm-4">
          <label>UTM Contents</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.contents"
            :options="values.utm_contents"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-md-4">
          <label>Landing pages (source)</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.sourceUrls"
            :options="values.sourceUrls"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
        <div class="form-group col-md-4">
          <label>Referer pages (origine)</label>
          <multiselect
            deselectLabel="X"
            selectLabel="Ajouter"
            v-model="filters.referers"
            :options="values.referers"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect custom-no-wrap"
            group-values="values"
            group-label="type"
            :group-select="true"
            selectGroupLabel=""
            deselectGroupLabel=""
            @input="apply()"
          >
          </multiselect>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Multiselect,
    Datepicker
  },

  props: {
    default: {
      type: Object,
      required: true
    },
    defaultDates: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    countClient: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      dates: {},
      filters: {},
      allOptions: {
        source: false,
        campaigns: false
      }
    };
  },

  async created() {
    this.filters = this.default;
    this.dates = this.defaultDates;
  },

  methods: {
    updateDate() {
      this.filters = {};

      this.$emit("updateDate", this.dates);
    },
    apply() {
      this.$emit("updateFilter", this.filters);
    }
  }
};
</script>

<style lang="scss">
.custom-multiselect {
  &.custom-no-wrap {
    .multiselect__tags {
      max-height: 68px;
      overflow-y: auto;
      overflow-x: hidden;
    }

    .multiselect__tag {
      white-space: normal;
    }
  }
}
</style>
