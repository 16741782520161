<template>
  <div class="animated fadeIn">
    <div class="card" v-if="settings">
      <div class="card-header">
        <i class="fa fa-align-justify"></i> Prestataires
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Prestations</dt>
          <dd class="col-sm-9">
            <input-tag
              :on-change="handleChangePrestations"
              :tags="settings && settings['intervenants_prestations']"
              :read-only="
                !hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')
              "
            ></input-tag>
          </dd>
          <dt class="col-sm-3">Tags</dt>
          <dd class="col-sm-9">
            <input-tag
              :on-change="handleChangeTags"
              :tags="settings['intervenants_tags']"
              :read-only="
                !hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')
              "
            ></input-tag>
          </dd>
        </dl>
      </div>
    </div>
    <div class="card" v-if="docs.coach">
      <div class="card-header">
        <i class="fa fa-align-justify"></i> Documents Intervenant
      </div>
      <div class="card-block">
        <dl class="row" v-for="(doc, i) in docs.coach" :key="doc.id">
          <dt class="col-sm-3">Document à signer ({{ i + 1 }})</dt>
          <dd class="col-sm-9">
            <a :href="download(doc.id)">{{ doc.name }}</a
            ><button
              class="btn btn-danger btn-sm ml-2"
              @click="removeDocument(doc.id)"
              v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
            >
              <i class="fa fa-trash-o"></i>
            </button>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
        >
          <dt class="col-sm-3">Upload document à signer</dt>
          <dd class="col-sm-9">
            <template>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="fileCoach"
                v-on:vdropzone-success="coachUploaded"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
        </dl>
      </div>
    </div>
    <div class="card" v-if="docs.former">
      <div class="card-header">
        <i class="fa fa-align-justify"></i> Documents Formateur
      </div>
      <div class="card-block">
        <dl class="row" v-for="(doc, i) in docs.former" :key="doc.id">
          <dt class="col-sm-3">Document à signer ({{ i + 1 }})</dt>
          <dd class="col-sm-9">
            <a :href="download(doc.id)">{{ doc.name }}</a
            ><button
              class="btn btn-danger btn-sm ml-2"
              @click="removeDocument(doc.id)"
              v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
            >
              <i class="fa fa-trash-o"></i>
            </button>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
        >
          <dt class="col-sm-3">Upload document à signer</dt>
          <dd class="col-sm-9">
            <template>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="fileFormer"
                v-on:vdropzone-success="formerUploaded"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
        </dl>
      </div>
    </div>
    <div class="card" v-if="docs.influencer">
      <div class="card-header">
        <i class="fa fa-align-justify"></i> Documents Influenceur
      </div>
      <div class="card-block">
        <dl class="row" v-for="(doc, i) in docs.influencer" :key="doc.id">
          <dt class="col-sm-3">Document à signer ({{ i + 1 }})</dt>
          <dd class="col-sm-9">
            <a :href="download(doc.id)">{{ doc.name }}</a
            ><button
              class="btn btn-danger btn-sm ml-2"
              @click="removeDocument(doc.id)"
              v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
            >
              <i class="fa fa-trash-o"></i>
            </button>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="hasPermission($store.state.user, 'SETTINGS_PRESTA_WRITE')"
        >
          <dt class="col-sm-3">Upload document à signer</dt>
          <dd class="col-sm-9">
            <template>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="fileInfluencer"
                v-on:vdropzone-success="influencerUploaded"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import InputTag from "vue-input-tag";
import axios from "axios";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    InputTag,
    Dropzone,
  },
  data() {
    return {
      settings: {},
      docs: {
        coach: [],
        former: [],
        influencer: [],
      },
    };
  },
  methods: {
    async fetchSettings() {
      const { data } = await this.$api.get("/settings");

      this.settings = data;

      this.sortDocs();
    },

    sortDocs() {
      this.docs = {
        coach: [],
        former: [],
        influencer: [],
      };

      this.settings.documents_sign.forEach((doc) => {
        if (doc.presta == "former") {
          this.docs.former.push(doc);
        } else if (doc.presta == "influencer") {
          this.docs.influencer.push(doc);
        } else {
          this.docs.coach.push(doc);
        }
      });
    },

    handleChangePrestations(prestations) {
      this.$api
        .patch("/settings", { intervenants_prestations: prestations })
        .catch((e) => {
          this.$errorToast(`Erreur lors de la sauvegarde des paramètre: ${e}`);
        });
    },

    handleChangeTags(tags) {
      this.$api.patch("/settings", { intervenants_tags: tags }).catch((e) => {
        this.$errorToast(`Erreur lors de la sauvegarde des paramètre: ${e}`);
      });
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    download(fileId) {
      return `${axios.defaults.baseURL}uploads/document/${fileId}`;
    },

    coachUploaded(file, response) {
      this.fileUploaded(file, response, "coach");
    },

    formerUploaded(file, response) {
      this.fileUploaded(file, response, "former");
    },

    influencerUploaded(file, response) {
      this.fileUploaded(file, response, "influencer");
    },

    async fileUploaded(file, response, presta) {
      const responseSettings = await this.$api.get("/settings");
      const settings = responseSettings.data;
      let documents = settings["documents_sign"] || [];
      documents.push({ id: response.id, name: response.name, presta: presta });

      try {
        const { data } = await this.$api.patch("/settings", {
          documents_sign: documents,
        });

        this.settings = data;
        this.sortDocs();
      } catch (error) {
        console.error(error);
      }
    },

    removeDocument(fileId) {
      this.$confirm({
        message: "Supprimer le document ? Cette action est irréversible.",
        onConfirm: async () => {
          try {
            await this.$api.delete(`/users/document/${fileId}`);

            const { data } = await this.$api.patch("/settings", {
              documents_sign: this.settings["documents_sign"].filter(
                (doc) => doc.id !== fileId
              ),
            });

            this.settings = data;
            this.sortDocs();
          } catch (error) {
            console.error(error);
          }
        },
      });
    },
  },
  created() {
    this.fetchSettings();
  },
};
</script>
