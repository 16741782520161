<template>
  <div class="animated fadeIn">
    <Loader :is-visible="loading" />

    <LostFilters
      :commercials="commercials"
      show-sources
      @displayCommercials="displayCommercials"
      @apply="applyFilters"
    />

    <tabs v-if="!loading && clients && prospects">
      <tab header="Prospects / Clients">
        <LostStats
          :clients="sortedClients"
          :clientsTotal="totalClients"
          :prospects="sortedProspects"
          :prospectsTotal="totalProspects"
          :commercials="selectedCommercials"
          show-sources
        />
      </tab>
      <tab header="Par source">
        <LostStatsPerSource
          :clients="sortedClientsPerSource"
          :clientsTotal="totalClients"
          :prospects="sortedProspectsPerSource"
          :prospectsTotal="totalProspects"
          show-sources
        />
      </tab>
    </tabs>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import LostFilters from "../components/LostFilters";
import LostStats from "../components/LostStats";
import LostStatsPerSource from "../components/LostStatsPerSource";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Loader,
    LostFilters,
    LostStats,
    LostStatsPerSource,
    Tabs,
    Tab,
  },

  data() {
    return {
      loading: false,
      clients: null,
      prospects: null,
      commercials: [],
      selectedCommercials: [],
      filters: {
        orderType: null,
        dates: {
          begin: null,
          end: null,
        },
        utm_sources: null,
        utm_campaigns: null,
        utm_contents: null,
      },
    };
  },

  computed: {
    totalClients() {
      return this.clients.length;
    },

    totalProspects() {
      return this.prospects.length;
    },

    sortedClients() {
      let sorted = {};

      this.clients.forEach((client) => {
        if (client.lostReason) {
          if (
            sorted[client.lostReason] &&
            Array.isArray(sorted[client.lostReason])
          ) {
            sorted[client.lostReason].push(client);
          } else {
            sorted[client.lostReason] = [client];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(client);
          } else {
            sorted["no-reason"] = [client];
          }
        }
      });

      return sorted;
    },

    sortedProspects() {
      let sorted = {};

      this.prospects.forEach((prospect) => {
        if (prospect.prospect.lostReason) {
          if (
            sorted[prospect.prospect.lostReason] &&
            Array.isArray(sorted[prospect.prospect.lostReason])
          ) {
            sorted[prospect.prospect.lostReason].push(prospect);
          } else {
            sorted[prospect.prospect.lostReason] = [prospect];
          }
        } else {
          if (sorted["no-reason"] && Array.isArray(sorted["no-reason"])) {
            sorted["no-reason"].push(prospect);
          } else {
            sorted["no-reason"] = [prospect];
          }
        }
      });

      return sorted;
    },

    sortedClientsPerSource() {
      return this.clients.reduce((a, p) => {
        a[p.marketing.utm_source] = a[p.marketing.utm_source] || [];
        a[p.marketing.utm_source].push(p);

        return a;
      }, Object.create(null));
    },

    sortedProspectsPerSource() {
      return this.prospects.reduce((a, p) => {
        a[p.marketing.utm_source] = a[p.marketing.utm_source] || [];
        a[p.marketing.utm_source].push(p);

        return a;
      }, Object.create(null));
    },
  },

  created() {
    this.getCommercials();
  },

  methods: {
    async loadLostClients() {
      const { begin, end } = this.filters.dates;
      const { orderType, utm_sources, utm_campaigns, utm_contents } =
        this.filters;

      try {
        const { data } = await this.$api.get("/clients/lost", {
          params: {
            sources: true,
            begin,
            end,
            orderType,
            utm_sources,
            utm_campaigns,
            utm_contents,
          },
        });

        this.clients = data;
      } catch (e) {
        this.clients = [];
      }
    },

    async loadLostProspects() {
      const { begin, end } = this.filters.dates;
      const { orderType, utm_sources, utm_campaigns, utm_contents } =
        this.filters;

      try {
        const { data } = await this.$api.get("/prospects/lost", {
          params: {
            sources: true,
            begin,
            end,
            orderType,
            utm_sources,
            utm_campaigns,
            utm_contents,
          },
        });

        this.prospects = data;
      } catch (e) {
        this.prospects = [];
      }
    },

    async getCommercials() {
      try {
        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial?.pseudo || c.firstName,
          color: c.commercial?.color,
        }));
      } catch (e) {
        this.commercials = [];
      }
    },

    displayCommercials(commercials) {
      this.selectedCommercials = commercials;
    },

    async applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters,
      };

      this.loading = true;

      await this.loadLostClients();
      await this.loadLostProspects();

      this.loading = false;
    },
  },
};
</script>
