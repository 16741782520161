<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-camrecorder"></i> Live / Visio
      <button
        v-if="!oldSession"
        class="btn btn-sm btn-primary float-right"
        @click="fetchLiveSessions(true)"
      >
        Voir les cours passés
      </button>
      <button
        v-if="oldSession"
        class="btn btn-sm btn-primary float-right"
        @click="fetchLiveSessions()"
      >
        Voir les cours à venir
      </button>
    </div>
    <div class="card-block">
      <CoachSessionLiveTabs
        style="max-width: 100%; overflow-x: scroll"
        :oldSession="oldSession"
        :sessions="sessions"
      />
    </div>
  </div>
</template>

<script>
import CoachSessionLiveTabs from "../components/CoachSessionLiveTabs";

export default {
  components: {
    CoachSessionLiveTabs,
  },

  data() {
    return {
      oldSession: false,
      sessions: [],
    };
  },

  mounted() {
    this.fetchLiveSessions(this.oldSession);
  },

  methods: {
    async fetchLiveSessions(old) {
      if (old) {
        this.oldSession = true;
      } else {
        this.oldSession = false;
      }

      const { data } = await this.$api.get(
        `/users/live-sessions${old ? "?old=true" : ""}`
      );

      this.sessions = data;
    },
  },
};
</script>
