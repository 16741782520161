<template>
  <div class="row">
    <Loader :is-visible="loading" />

    <template v-if="order">
      <div class="col-sm-7">
        <OrderGlobalCard
          :order="order"
          :client="client"
          :payments="payments"
          :sessions="sessions"
          @reload="loadOrder"
        />

        <OrderParticipantsCard v-if="client" :order="order" :client="client" />
      </div>

      <div class="col-sm-5">
        <OrderAttributionCard :order="order" />

        <OrderCommercialCard :order="order" />

        <OrderClientCard
          v-if="client"
          :client="client"
          :order="order"
          @reload="loadOrder"
        />

        <OrderNotesCard :order="order" />
      </div>

      <div class="col-sm-12">
        <OrderPayments
          v-if="client"
          :needPayments="needPayments"
          :order="order"
          :client="client"
          :paymentProps="payments"
        />
      </div>

      <div class="col-sm-12">
        <OrderSessionsCard
          v-if="sessions && order.status !== 'refunded'"
          :sessions="sessions"
          :order="order"
          @reload="loadOrder"
        />
      </div>

      <div class="col-sm-12">
        <OrderLogCard :order="order" />
      </div>
    </template>
  </div>
</template>

<script>
import Loader from "../components/Loader";
import OrderGlobalCard from "../components/OrderGlobalCard";
import OrderCommercialCard from "../components/OrderCommercialCard";
import OrderAttributionCard from "../components/OrderAttributionCard";
import OrderClientCard from "../components/OrderClientCard";
import OrderNotesCard from "../components/OrderNotesCard";
import OrderPayments from "../components/OrderPayments";
import OrderSessionsCard from "../components/OrderSessionsCard";
import OrderLogCard from "../components/OrderLogCard";
import OrderParticipantsCard from "../components/OrderParticipantsCard";

export default {
  components: {
    Loader,
    OrderGlobalCard,
    OrderCommercialCard,
    OrderAttributionCard,
    OrderClientCard,
    OrderNotesCard,
    OrderPayments,
    OrderSessionsCard,
    OrderLogCard,
    OrderParticipantsCard,
  },
  data() {
    return {
      loading: false,

      order: null,
      client: null,
      payments: null,
      sessions: null,

      needPayments: "",
    };
  },

  created() {
    this.loadOrder();
  },

  methods: {
    async getOrder() {
      try {
        const { data } = await this.$api.get(
          `/orders/${this.$route.params.id}`
        );

        this.order = data;
      } catch (e) {
        this.$router.push({
          name: "orders",
        });
      }
    },

    async loadOrder() {
      this.loading = true;

      await this.getOrder();

      if (this.order.client && typeof this.order.client === "object") {
        this.client = this.order.client;
      }

      if (this.order.payments) {
        this.payments = this.order.payments;
      }
      if (this.order.sessions) {
        this.sessions = await this.order.sessions;
      }

      if (this.order.price >= this.amountDiff(true)) {
        this.needPayments = (parseFloat(this.amountDiff(true)) / 100).toFixed(
          2
        );
      }

      if (this.order.allowed) {
        let completed = 0;

        this.order.sessions.forEach((s) => {
          if (s.completed || s.assignedAt) {
            completed++;
          }
        });

        if (completed === 0) {
          this.order.allowed = 0;
        }
      }

      this.loading = false;
    },

    amountDiff(raw) {
      let payed = 0;

      for (const payment of this.order.payments) {
        if (payment.captured) {
          payed += payment.price;
        }
      }

      return raw
        ? this.order.price - payed
        : this.formatPrice(this.order.price - payed);
    },
  },
};
</script>

<style type="text/css">
.btn-info.float-right {
  margin-left: 4px;
}
</style>
