<template>
  <Geoloc
    v-if="geoloc"
    :geoloc="geoloc"
    :address="formatAddress(coach.coach)"
    :displayAddress="true"
    :range="coach.coach.range"
    placeholder="Adresse du coach"
    @placeChanged="handleAddress"
  />
</template>

<script>
import Geoloc from "../components/Geoloc";

export default {
  components: {
    Geoloc,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },

  computed: {
    geoloc() {
      if (this.coach.coach.geoloc) {
        return {
          lat: this.coach.coach.geoloc.coordinates[1],
          lng: this.coach.coach.geoloc.coordinates[0],
        };
      } else {
        return false;
      }
    },
  },

  methods: {
    async handleAddress(place) {
      const data = place;

      await this.$api.put("/app", data);
    },
  },
};
</script>
