<template>
  <modal
    title="Ajouter une commande"
    class="custom-modal"
    large
    v-model="modalOpened"
    @ok="handleNewOrder()"
    @cancel="$emit('closeModal')"
    effect="fade/zoom"
    cancelText="Fermer"
    okText="Enregistrer"
    :backdrop="false"
  >
    <div slot="modal-header" class="modal-header row m-0">
      <h4 class="modal-title col-4">Ajouter une commande</h4>

      <div
        class="col-8 d-flex flex-column align-items-end"
        style="padding-top: 17px"
      >
        <div class="pull-right d-flex flex-wrap justify-content-end">
          <div class="form-group d-inline-block mr-1">
            <span class="mr-1"><i class="fa fa-bullhorn"></i> Parrainage</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.sponsorship.isSponsorship"
                @change="changeIsSponsorship"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div class="form-group d-inline-block mr-1">
            <span class="mr-1"><i class="fa fa-gift"></i> Carte cadeau</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.giftCard.isGiftCard"
                @change="handleChangeIsGiftCard()"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div class="form-group d-inline-block" v-if="!form.isCredit">
            <span class="mr-1"><i class="icon-envelope"></i> Email</span>
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.sendEmail"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>
        <div
          v-if="form.sponsorship.isSponsorship"
          class="form-group d-flex flex-column align-items-end mb-0"
        >
          <v-select
            v-model="form.sponsorship.sponsorEmail"
            :options="clients"
            :reduce="(option) => option.value"
            :class="
              formErrors.noSponsor || formErrors.autoSponsoring
                ? 'has-error'
                : ''
            "
            :disabled="formErrors.sponsorship"
            @search="searchClients"
            @input="checkSponsor"
          ></v-select>
          <label class="text-muted mb-0">Parrain/Marraine</label>
          <div v-if="formErrors.autoSponsoring" class="text-danger">
            Un client ne peut pas se parrainer lui-même
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group has-danger col-sm-4">
        <label>Société</label>
        <v-select
          v-model="form.merchantId"
          :options="merchantsOptions"
          :class="this.formErrors.merchantId ? 'has-error' : ''"
          @click.native="checkIfEmpty"
          @input="handleMerchantChange"
        ></v-select>
      </div>
      <div class="form-group has-danger col-sm-4">
        <label>Concept</label>
        <v-select
          v-model="form.concept"
          :options="concepts"
          :class="this.formErrors.concept ? 'has-error' : ''"
          @input="updatePaymentMethods()"
        >
          <span slot="no-options">Sélectionnez une option pour Société.</span>
        </v-select>
      </div>

      <div class="form-group col-sm-4">
        <label>Commercial</label>
        <v-select :options="commercials" v-model="form.commercial"></v-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label>Pack</label>
        <v-select
          v-model="form.session"
          :options="sessions"
          :class="this.formErrors.session ? 'has-error' : ''"
          @input="(e) => createSubPayments(e)"
        ></v-select>
      </div>
      <div class="form-group col-sm-4">
        <label>Séances temporaires</label>
        <v-select v-model="form.allowed" :options="sessions"></v-select>
      </div>
      <div
        class="form-group col-sm-4"
        v-if="!form.giftCard.isGiftCard"
        style="padding-top: 14px"
      >
        Pointage des participants
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="form.requiredSessionUserCount"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>
      <div class="col-sm-4" v-else>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Expéditeur</label>
            <input
              v-model="form.giftCard.sender"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-6">
            <label>Destinataire</label>
            <input
              v-model="form.giftCard.recipient"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group col-sm-12">
            <label>Message carte cadeau</label>
            <textarea
              v-model="form.giftCard.message"
              type="text"
              class="form-control"
              maxlength="80"
            />
            <p>
              <strong class="text-muted">
                {{ giftCardMaxChar }} caractères restants.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-9">
        <label>
          Description
          <span v-if="form.giftCard.isGiftCard">carte cadeau</span>
          <span v-else>commande</span>
        </label>
        <textarea v-model="form.description" type="text" class="form-control" />
      </div>
      <div class="form-group col-sm-3">
        <label>Commande effectuée le</label>
        <datepicker
          v-model="form.createdAt"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        />
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-7">
        <label>Menu / Statistique</label>
        <v-select
          v-model="form.menuStat"
          :options="menuStatsOptions"
          label="label"
          :selectable="(option) => !option.disable"
          :class="this.formErrors.menu ? 'has-error' : ''"
          :disabled="form.isCredit"
          @input="changeMenuStat"
        />
      </div>

      <div class="form-group col-sm-2" style="padding-top: 35px">
        <i class="icon-target"></i> Attribution
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="form.attribution"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>

      <div class="form-group col-sm-3">
        <label>Prix</label>
        <div
          class="input-group"
          :class="this.formErrors.price ? 'has-error' : ''"
        >
          <input
            v-model="form.price"
            type="text"
            class="form-control"
            @input="(e) => formPriceInput(e.target.value)"
          />
          <span class="input-group-addon"><i class="fa fa-euro"></i></span>
        </div>
        <small
          v-if="
            sponsoredStatus &&
            sponsoredStatus.value === 'discovery-done' &&
            (form.sponsorship.isSponsorship ||
              (!form.sponsorship.isSponsorship &&
                !sponsoredStatus.canBeSponsored)) &&
            !formErrors.client
          "
          class="text-success"
        >
          -10% applicable grâce au parrainage
        </small>
        <small class="text-danger" v-if="form.isCredit">
          Avoir de <strong>{{ form.price }}&euro;</strong>, une facture sera
          générée directement.
        </small>
      </div>
    </div>

    <div v-if="!form.isCredit">
      <div class="row">
        <div class="form-group col-sm-2">
          <label>
            Avance imm.
            <i
              class="fa fa-info-circle"
              v-tooltip="
                `Avance immédiate : ${
                  !client.urssafInscription
                    ? 'client non inscrit'
                    : 'ne peut contenir de paiement CB & doit être dans COMP'
                }`
              "
            ></i>
          </label>
          <div class="input-group" style="padding-top: 7px">
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.paymentData.isAI"
                :disabled="
                  !hasPermission($store.state.user, 'ORDERS_WRITE') ||
                  !client.urssafInscription ||
                  !client.urssafInscription.valid ||
                  !isCompany
                "
                @change="toggleAI"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="form-group col-sm-2">
          <label> Nombre d'échéances </label>
          <div class="input-group">
            <input
              v-model="form.paymentData.countPayments"
              type="number"
              min="1"
              :max="maxDueDate"
              class="form-control"
              @input="createSubPayments"
            />
          </div>
        </div>
        <div class="form-group col-sm-2">
          <label> Paiement par CB </label>
          <div class="input-group" style="padding-top: 7px">
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.paymentData.isCB"
                :disabled="
                  !hasPermission($store.state.user, 'ORDERS_WRITE') ||
                  form.paymentData.isAI
                "
                @change="createSubPayments"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="form-group col-sm-4" v-if="form.paymentData.isCB">
          <label style="width: 100%">
            Description Paiement
            <a class="pull-right" href="#" @click="createSubPayments">
              <i class="fa fa-refresh"></i>
            </a>
          </label>
          <input
            v-model="form.paymentData.description"
            type="text"
            class="form-control"
          />
        </div>

        <div class="form-group col-sm-4" v-else>
          <div class="form-group" style="position: relative">
            <label style="width: 100%">
              Choix du mode de paiement (par défaut)
              <a class="pull-right" href="#" @click="createSubPayments">
                <i class="fa fa-refresh"></i>
              </a>
            </label>
            <div class="input-group">
              <span class="input-group-addon">Paiement par</span>
              <select
                v-model="form.paymentData.type"
                @change="createSubPayments"
                :disabled="form.paymentData.isAI"
                class="form-control"
              >
                <option
                  :key="key"
                  v-for="(pm, key) in paymentMethods"
                  :value="pm.name"
                >
                  {{ pm.name }}
                </option>
              </select>
            </div>
            <div
              class="input-group"
              style="margin-top: -1px; position: absolute; width: 100%"
              v-if="form.paymentData.type == 'manual'"
            >
              <input
                v-model="form.paymentData.typeManual"
                type="text"
                class="form-control"
                placeholder="Saisir un nom de paiement"
              />
            </div>
          </div>
        </div>

        <div class="form-group col-sm-2">
          <label style="width: 100%">
            Première échéance
            <a class="pull-right" href="#" @click="createSubPayments">
              <i class="fa fa-refresh"></i>
            </a>
          </label>
          <datepicker
            v-model="form.paymentData.wishedDay"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-8">
          <div class="alert alert-danger" v-if="paymentsDifference">
            Veuillez ajuster le paiement de :
            {{ paymentsDifference }} €
          </div>

          <div class="alert alert-danger" v-if="formErrors.missingType">
            Veuillez ajouter une description (si CB) ou un mode (si pas CB) aux
            paiements.
          </div>

          <div class="alert alert-danger" v-if="formErrors.paymentAiDecimal">
            Attention lors d'un paiement en avance immédiate le nombre de séances doit être 
            un entier divisible par le nombre d'échéances. ({{form.session.value}} / {{form.paymentData.countPayments}} = {{form.session.value / form.paymentData.countPayments}})
          </div>

          <el-table :data="this.form.payments">
            <el-table-column label="Prix" width="100">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.price"
                  @input="
                    (e) => formPaymentPriceInput(e.target.value, scope.row)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="CB" align="center" width="40">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="checkbox"
                  :disabled="scope.row.isAI"
                  v-model="scope.row.isCB"
                  @input="clearPaymentType(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column label="Description" width="200">
              <template slot-scope="scope">
                <input
                  class="form-control"
                  type="text"
                  v-model="scope.row.type"
                  :disabled="!scope.row.isCB"
                />
              </template>
            </el-table-column>
            <el-table-column label="Date de prélèvement" width="170">
              <template slot-scope="scope">
                <el-date-picker
                  v-model="scope.row.deadline"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  style="width: 100%"
                  type="date"
                />
              </template>
            </el-table-column>
            <el-table-column label="Mode">
              <template slot-scope="scope">
                <select
                  v-if="!scope.row.isCB && !scope.row.isAI"
                  v-model="scope.row.type"
                  class="form-control"
                >
                  <option
                    :key="key"
                    v-for="(pm, key) in paymentMethods"
                    :value="pm.name"
                  >
                    {{ pm.name }}
                  </option>
                </select>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="col-sm-4" v-if="!form.giftCard.isGiftCard">
          <div class="form-group">
            Valeurs par défaut des sessions
            <label
              class="switch switch-sm switch-text switch-info float-right mb-0 mr-0"
            >
              <input
                type="checkbox"
                class="switch-input"
                v-model="form.editSessions"
                @input="fetchClientCoachs"
                :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
              />
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div v-if="form.editSessions" class="row">
            <div class="col-sm-8 form-group">
              <label>Coach</label>
              <v-select
                v-model="form.defaultCoach"
                :options="coachs"
                @search="searchCoachs"
                :class="this.formErrors.coach ? 'has-error' : ''"
              ></v-select>
            </div>
            <div class="col-sm-4 form-group">
              <label>Avoirs</label>
              <input
                type="text"
                class="form-control"
                v-model="form.defaultCredit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import moment from "moment";
import Modal from "vue-strap/src/Modal";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import debounce from "lodash.debounce";

import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    vSelect,
    Datepicker,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    requiredSessionUserCount: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      modalOpened: false,
      merchantsOptions: [],
      concepts: [],
      clients: [],
      coachs: [],
      sessions: [],
      commercials: [],
      paymentMethods: [],
      formSending: false,
      form: {
        sendEmail: true,
        giftCard: {
          isGiftCard: false,
          sender: "",
          recipient: "",
          message: "",
        },
        sponsorship: {
          isSponsorship: false,
          sponsorEmail: null,
        },
        menuStat: null,
        merchantId: null,
        concept: null,
        client: null,
        session: null,
        allowed: null,
        clients: null,
        price: null,
        description: null,
        attribution: null,
        commercial: null,
        paymentData: {
          isCB: true,
          isAI: false,
          type: null,
          wishedDay: moment().add("15", "days").format(),
          countPayments: 1,
          description: null,
        },
        payments: [],
        defaultCoach: null,
        defaultCredit: "27,5",
        requiredSessionUserCount: null,
      },
      formErrors: {
        session: false,
        concept: false,
        price: false,
        merchantId: false,
        client: false,
        menu: false,
        missingType: false,
        paymentsDifference: false,
        paymentAiDecimal: false,
        sponsorship: false,
        noSponsor: false,
        autoSponsoring: false,
      },
      unpaidQuantity: null,
      sponsoredStatus: null,
    };
  },
  async created() {
    this.modalOpened = this.modalShow;

    await this.fetchCommercials();
    await this.fetchMerchants();
    await this.getClientCommercials(this.client.email);
    await this.checkClient();

    if (this.requiredSessionUserCount) {
      this.form.requiredSessionUserCount = this.requiredSessionUserCount;
    }

    if (this.client.billing?.description) {
      this.form.description = this.client.billing.description;
    }

    if (!this.form.commercial) {
      const currentCommercial = this.commercials.find((c) => {
        if (this.$store.state.user.email === c.value) {
          return c;
        }
      });

      if (currentCommercial) {
        this.form.commercial = currentCommercial;
      }
    }

    for (let i = 1; i <= 200; i++) {
      this.sessions.push({
        label: this.formatPack(i.toString()),
        value: i.toString(),
      });

      if (!this.form.session) {
        this.form.session = this.sessions[0];
      }
    }
  },
  computed: {
    giftCardMaxChar() {
      return 80 - this.form.giftCard.message.length;
    },

    paymentsDifference() {
      let diff = 0;

      if (this.form.payments) {
        let totalsPayments = 0;

        for (const p of this.form.payments) {
          totalsPayments += +p.price;
        }

        diff = this.form.price - totalsPayments;
      }

      return diff.toFixed(2) === "0.00" || diff.toFixed(2) === "-0.00"
        ? 0
        : diff.toFixed(2);
    },

    cleanedCredit() {
      return (
        parseFloat(this.form.defaultCredit.replace(",", ".")).toFixed(2) * 100
      );
    },

    ...mapGetters({
      orderStatus: "getOrderStatusGroups",
      orderStatusFollowed: "getOrderStatusFollowedGroups",
      sponsorshipStatus: "getSponsorshipStatus",
      menuStatsOptions: "getMenuStatsOptions",
    }),

    isCompany() {
      return (
        this.form.merchantId?.value === "502c9c9e-0205-4380-9fb3-b5f63176da84"
      );
    },

    conceptIsAffectedByOrderBlocking() {
      const conceptsNames = [
        "justcoaching.fr",
        "Body Trainers",
        "luxurycoaching.fr",
      ];

      if (this.form.concept) {
        return conceptsNames.includes(this.form.concept);
      }

      return false;
    },

    addOrderBlocked() {
      return (
        this.unpaidQuantity >= 1 &&
        this.conceptIsAffectedByOrderBlocking &&
        !this.hasPermission(
          this.$store.state.user,
          "BLOCKED_IMPAYED_ORDERS_READ"
        )
      );
    },

    maxDueDate() {
      if (!this.form.paymentData.isAI) {
        return '20'
      }

      return Math.floor(this.form.session.value/10)
    }
  },

  methods: {
    checkFields() {
      this.formErrors.client = !this.form.client;

      if (this.addOrderBlocked) {
        this.formErrors.client = true;
      }

      this.formErrors.sponsorship =
        this.form.sponsorship.isSponsorship &&
        !this.sponsoredStatus?.canBeSponsored;
      this.formErrors.noSponsor =
        this.form.sponsorship.isSponsorship &&
        !this.form.sponsorship.sponsorEmail;
      this.formErrors.session = !this.form.isCredit && !this.form.session;
      this.formErrors.concept = !this.form.concept;
      this.formErrors.merchantId = !this.form.merchantId;
      this.formErrors.price = !this.form.price;
      this.formErrors.menu = !this.form.isCredit && !this.form.menuStat;

      if (
        !this.form.isCredit &&
        this.form.payments &&
        this.form.payments.length > 0
      ) {
        if (this.form.payments.find((p) => !p.type)) {
          this.formErrors.missingType = true;
        } else {
          this.formErrors.missingType = false;
        }

        this.formErrors.paymentsDifference = !!this.paymentsDifference;
      } else {
        this.formErrors.missingType = false;
        this.formErrors.paymentsDifference = false;
      }

      return (
        Object.values(this.formErrors).filter((error) => error).length === 0
      );
    },

    async checkClient() {
      if (!this.form.client || !this.form.client.value) {
        this.clearSponsorshipOffer();
        this.sponsoredStatus = null;
        this.formErrors.client = false;
        return;
      }

      try {
        const { data } = await this.$api.get(
          "/clients/orders/unpaid-quantity",
          {
            params: {
              client: this.form.client.value,
            },
          }
        );

        this.unpaidQuantity = data.unpaidQuantity;
      } catch (e) {
        console.log(e);
      }

      this.checkIfClientCanBeSponsored();
    },

    async updatePaymentMethods() {
      const { data } = await this.$api.get("/payment-methods/by-concept", {
        params: { concept: this.form.concept },
      });

      this.paymentMethods = data;
    },

    async handleNewOrder() {
      this.form.client = this.client.email;

      if (!this.checkFields() || this.formSending) {
        return;
      }

      const isConversion =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "decouverte" ||
          this.form.menuStat.value === "one-shot");

      const isAcquisition =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "decouverte" ||
          this.form.menuStat.value === "pack-conversion" ||
          this.form.menuStat.value === "one-shot" ||
          this.form.menuStat.value === "autres");

      const isFidelisation =
        this.form.menuStat &&
        this.form.menuStat.value &&
        (this.form.menuStat.value === "pack-conversion" ||
          this.form.menuStat.value === "pack-fidelisation" ||
          this.form.menuStat.value === "one-shot");

      let defaultCoach;
      let defaultCredit;

      if (this.form.editSessions) {
        defaultCoach = this.form.defaultCoach && this.form.defaultCoach.value;
        defaultCredit = this.cleanedCredit;
      }

      try {
        this.formSending = true;

        const { data } = await this.$api.post("/orders", {
          merchantId: this.form.merchantId.value,
          client: this.form.client,
          session: this.form.session.value,
          allowed: this.form.allowed ? this.form.allowed.value : 0,
          concept: this.form.concept,
          commercial: this.form.commercial ? this.form.commercial.value : 0,
          discovery: isConversion && isAcquisition && !isFidelisation,
          isCredit: this.form.isCredit,
          price: this.form.price.replace(",", "."),
          description: this.form.description,
          isConversion,
          isFidelisation,
          isAcquisition,
          defaultCoach,
          defaultCredit,
          giftCard: this.form.giftCard,
          sponsored: this.form.sponsorship.isSponsorship,
          sponsoredBy:
            this.form.sponsorship.isSponsorship &&
            this.form.sponsorship.sponsorEmail,
          isOneShot: this.form.menuStat?.value === "one-shot",
          requiredSessionUserCount: this.form.requiredSessionUserCount,
          isAI: this.form.paymentData.isAI,
          attribution: this.form.attribution,
          payments: this.form.payments,
        });

        this.newOrder = false;

        if (!this.form.isCredit && this.form.sendEmail) {
          this.$confirm({
            message: "Envoyez un email pour informer le client ?",
            onConfirm: async () => {
              await this.sendEmailAfterCreation(data);
            },
          });
        }

        this.$emit("close");
      } catch (err) {
        this.formSending = false;
      }
    },

    handleChangeIsGiftCard() {
      if (this.form.giftCard && this.form.giftCard.isGiftCard) {
        this.form.menuStat = {
          label:
            "Pack OneShot (Menu Conversion - Fidélisation / Stat Acquisition)",
          value: "one-shot",
          disable: false,
        };

        this.form.description = "Carte cadeau";
      }
    },

    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter`, {
        params: {
          q: search,
        },
      });

      vm.clients = data.map((c) => ({
        label: `${c.name} (${c.usedEmail})`,
        firstName: c.firstName,
        lastName: c.lastName,
        company: c.company,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async searchCoachs(search, loading) {
      if (search !== "") {
        await this.fetchCoachs(search, loading, this);
      }
    },

    fetchCoachs: debounce(async (search, loading, vm) => {
      if (!search || search.lentgh < 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.post("/users/coachs/search", {
        terms: search,
        filterBy: ["firstName", "lastName", "email"],
      });

      vm.coachs = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    async fetchClientCoachs() {
      if (this.form.client) {
        try {
          const { data } = await this.$api.get(
            `/clients/coachs/${this.form.client.value}`
          );

          this.coachs = data;
        } catch (e) {
          console.log(e);
        }
      }
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));

      this.commercials.push({
        label: "Aucun",
        value: "none",
      });
    },

    async getClientCommercials(email) {
      const { data } = await this.$api.get(`/clients/by-email/${email}`);

      this.clients.push(data);

      if (data.commercials && data.commercials.length > 0) {
        const commercialToSet = data.commercials[0].user;

        if (commercialToSet) {
          this.form.commercial = {
            label: commercialToSet,
            value: commercialToSet,
          };
        }
      }
    },

    async fetchMerchants() {
      const { data } = await this.$api.get("/merchants");

      this.merchantsOptions = data.map((merchant) => {
        return { label: merchant.name, value: merchant.id };
      });
    },

    async handleMerchantChange(merchant) {
      const DISABLED_FROM_CREATION = ["Just Coaching Live"];

      if (merchant) {
        const params = { merchantId: merchant.value };
        const { data } = await this.$api.get("/concepts", { params });

        this.concepts = [];
        this.concepts = data
          .filter((concept) => {
            if (!DISABLED_FROM_CREATION.includes(concept.name)) {
              return concept.name;
            }
          })
          .map(({ name }) => name);
      }
    },

    toggleAI() {
      if (this.form.paymentData.isAI) {
        this.form.paymentData.isCB = false;
      }

      this.createSubPayments();
    },

    createSubPayments(e) {
      if (e && typeof e.preventDefault === "function") e.preventDefault();
      if (!this.form.isCredit) {
        if (this.form.price) {
          const payments = [];

          let nbPaiement = this.form.paymentData.countPayments

          if (this.form.paymentData.isAI) {
            const maxPaymentQuantity = Math.floor(this.form.session.value/10)
            this.formErrors.paymentAiDecimal = false

            if (nbPaiement > maxPaymentQuantity) {
              nbPaiement = maxPaymentQuantity === 0 ? 1 : maxPaymentQuantity
              this.form.paymentData.countPayments = nbPaiement
            }

            if (!Number.isInteger(this.form.session.value / this.form.paymentData.countPayments)) {
              this.formErrors.paymentAiDecimal = true
            }
          }

          const price = (
            this.form.price / nbPaiement
          ).toFixed(2);

          let deadline = moment(this.form.paymentData.wishedDay).format();

          let stripe;
          let type = this.form.paymentData.type;

          if (this.form.paymentData.isCB) {
            deadline = null;
            type = this.form.paymentData.description;
          } else if (this.form.paymentData.isAI) {
            type = "Avance immédiate";
          }

          for (
            let index = 0;
            index < nbPaiement;
            index++
          ) {
            payments.push({
              price,
              captured: false,
              type,
              alert: false,
              alertMessage: "",
              deadline,
              stripe,
              isCB: this.form.paymentData.isCB,
              isAI: this.form.paymentData.isAI,
            });

            if (null === deadline) {
              deadline = moment(this.form.paymentData.wishedDay).format();
            } else {
              deadline = moment(deadline).add(1, "M").format();
            }
          }

          this.form.payments = payments;
        }
      }
    },

    async sendEmailAfterCreation(order) {
      await this.$api.post(`/orders/send-creation-email/${order.id}`);
    },

    changeIsSponsorship() {
      if (this.form.sponsorship.isSponsorship) {
        if (this.form.client) {
          this.checkIfClientCanBeSponsored();
        }
      } else {
        this.clearSponsorshipOffer();
        this.form.sponsorship.sponsorEmail = null;
      }
    },

    checkIfEmpty() {
      if (!this.form.merchantId) {
        this.concepts = [];
        this.form.concept = null;
      }
    },

    checkStatusFilter() {
      if (this.isProblematicStatusFilter) {
        this.form.status = this.form.status.filter(
          (status) => status.isProblematic
        );

        this.orderStatus[0].status.forEach((status) => {
          status.$isDisabled = true;
        });
      } else {
        this.orderStatus[0].status.forEach((status) => {
          status.$isDisabled = false;
        });
      }
    },

    checkSponsor() {
      if (!this.form.sponsorship.sponsorEmail) {
        this.formErrors.autoSponsoring = false;
      } else {
        this.formErrors.noSponsor = false;

        if (
          this.form.client?.value &&
          this.form.sponsorship.sponsorEmail === this.form.client.value
        ) {
          this.formErrors.autoSponsoring = true;
        }
      }
    },

    async checkIfClientCanBeSponsored() {
      try {
        if (
          this.form.sponsorship.sponsorEmail &&
          this.form.sponsorship.sponsorEmail === this.form.client.value
        ) {
          this.formErrors.autoSponsoring = true;

          return;
        }

        const { data } = await this.$api.post("/clients/can-be-sponsored", {
          email: this.form.client.value,
        });

        this.sponsoredStatus = this.sponsorshipStatus.find(
          ({ value }) => value === data.status
        );

        if (this.form.sponsorship.isSponsorship) {
          if (this.sponsoredStatus.canBeSponsored) {
            if (this.form.menuStat?.value === "one-shot") {
              this.setOneShotSponsorshipStatus();
            } else {
              this.setFreeDiscovery();
            }
          } else {
            this.formErrors.sponsorship = true;
          }
        }
      } catch (e) {
        this.formErrors.client = true;
      }
    },

    clearSponsorshipOffer() {
      this.formErrors.sponsorship = false;
      this.formErrors.noSponsor = false;
      this.formErrors.autoSponsoring = false;
      this.form.description = null;
      this.form.menuStat = null;
      this.form.price = null;
    },

    setFreeDiscovery() {
      this.form.session = this.sessions.find(({ value }) => value === "1");
      this.form.clients = "1";
      this.form.description = "Parrainage - Séance découverte offerte";
      this.form.menuStat = this.menuStatsOptions.find(
        ({ value }) => value === "decouverte"
      );
      this.form.price = "0";
    },

    changeMenuStat(menuStat) {
      if (
        this.form.sponsorship.isSponsorship &&
        this.sponsoredStatus?.canBeSponsored
      ) {
        if (menuStat?.value === "one-shot") {
          this.setOneShotSponsorshipStatus();
        } else if (this.sponsoredStatus?.value !== "eligible") {
          this.setEligibleSponsorshipStatus();
        }
      }
    },

    setOneShotSponsorshipStatus() {
      const discoveryDone = this.sponsorshipStatus.find(
        (status) => status.value === "discovery-done"
      );

      const oneShotSponsorship = { ...discoveryDone, canBeSponsored: true };

      this.sponsoredStatus = oneShotSponsorship;
    },

    setEligibleSponsorshipStatus() {
      this.sponsoredStatus = this.sponsorshipStatus.find(
        (status) => status.value === "eligible"
      );
    },

    clearPaymentType(payment) {
      payment.type = null;
    },

    formPriceInput(value) {
      this.form.price = this.formatFormPrice(value);

      this.createSubPayments();
    },

    formPaymentPriceInput(value, payment) {
      payment.price = this.formatFormPrice(value);
    },
  },
  watch: {
    modalShow: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.modalOpened = newVal;
      }
    },
  },
};
</script>

<style>
.has-error {
  border: 1px solid #ff5454;
  border-radius: 4px;
}

.custom-modal .modal-lg {
  max-width: 1100px;
}
</style>
