<template>
  <div class="row">
    <div class="col-xl-8">
      <AccountLiveSessionsCard />

      <AccountSoloSessionsCard />
    </div>
    <div class="col-xl-4">
      <AccountLiveTutorialCard />
    </div>
  </div>
</template>

<script>
import AccountLiveSessionsCard from "../components/AccountLiveSessionsCard";
import AccountSoloSessionsCard from "../components/AccountSoloSessionsCard";
import AccountLiveTutorialCard from "../components/AccountLiveTutorialCard";

export default {
  components: {
    AccountLiveSessionsCard,
    AccountSoloSessionsCard,
    AccountLiveTutorialCard,
  },
};
</script>
