<template>
  <div class="card">
    <div class="card-header"><i class="icon-user"></i> Informations</div>
    <div class="card-block">
      <dl class="row">
        <dt class="col-sm-4">
          Email du compte
          <span
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: 'Votre identifiant.',
              placement: 'right',
            }"
          ></span>
        </dt>
        <dd class="col-sm-8">
          {{ coach.email }}
        </dd>
        <dt class="col-sm-4">
          Email
          <span
            class="fa fa-info-circle ml-05"
            v-tooltip="{
              content: 'Si différent de votre identifiant.',
              placement: 'right',
            }"
          ></span>
        </dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.contactEmail"
            size="mini"
            @change="editCoach('contactEmail', $event)"
          />
        </dd>
        <dt class="col-sm-4">Téléphone</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.phone"
            size="mini"
            @change="editCoach('phone', $event)"
          />
        </dd>
        <dt class="col-sm-4">Prénom</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.firstName"
            size="mini"
            @change="editCoach('firstName', $event)"
          />
        </dd>
        <dt class="col-sm-4">Nom de famille</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.lastName"
            size="mini"
            @change="editCoach('lastName', $event)"
          />
        </dd>
        <dt class="col-sm-4">Genre</dt>
        <dd class="col-sm-8">
          <el-select
            v-model="coach.coach.gender"
            size="mini"
            @change="editCoach('gender', $event)"
          >
            <el-option value="Homme" />
            <el-option value="Femme" />
          </el-select>
        </dd>

        <dt class="col-sm-4">Nom de la société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.companyName"
            size="mini"
            @change="editCoach('companyName', $event)"
          />
        </dd>
        <dt class="col-sm-4">Siret</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.siret"
            size="mini"
            @change="editCoach('siret', $event)"
          />
        </dd>
        <dt class="col-sm-4">
          Société soumise à la TVA
        </dt>
        <dd class="col-sm-8">
          <el-switch
            v-model="coach.coach.isTva"
            active-text="Oui"
            inactive-text="Non"
            size="mini"
            @change="editCoach('isTva', $event)"
          />
        </dd>
        <dt class="col-sm-4">Adresse de la société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.companyAddress"
            size="mini"
            @change="editCoach('companyAddress', $event)"
          />
        </dd>
        <dt class="col-sm-4">Ville de la société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.companyCity"
            size="mini"
            @change="editCoach('companyCity', $event)"
          />
        </dd>
        <dt class="col-sm-4">Code postal de la société</dt>
        <dd class="col-sm-8">
          <el-input
            v-model="coach.coach.companyZipcode"
            size="mini"
            @change="editCoach('companyZipcode', $event)"
          />
        </dd>
        <dt class="col-sm-4" v-if="coach.coach.isCoach || coach.coach.isFormer">
          Rayon d'action
        </dt>
        <dd class="col-sm-8" v-if="coach.coach.isCoach || coach.coach.isFormer">
          <el-select
            v-model="coach.coach.range"
            size="mini"
            @change="editCoach('range', $event)"
          >
            <el-option :value="1" label="1 Km" />
            <el-option :value="2" label="2 Km" />
            <el-option :value="5" label="5 Km" />
            <el-option :value="10" label="10 Km" />
            <el-option :value="15" label="15 Km" />
            <el-option :value="20" label="20 Km" />
            <el-option :value="25" label="25 Km" />
            <el-option :value="30" label="30 Km" />
            <el-option :value="40" label="40 Km" />
            <el-option :value="50" label="50 Km" />
            <el-option :value="75" label="75 Km" />
            <el-option :value="100" label="100 Km" />
          </el-select>
        </dd>
        <dt class="col-sm-4" v-if="coach.coach.isCoach || coach.coach.isFormer">
          Code PIN (4 Chiffres.)
        </dt>
        <dd class="col-sm-8" v-if="coach.coach.isCoach || coach.coach.isFormer">
          <el-input
            :class="!validPin && 'has-danger-pincode'"
            v-model="coach.coach.pincode"
            size="mini"
            maxlength="4"
            @change="editCoach('pincode', $event)"
          />
          <span v-if="!validPin" class="text-danger">
            Votre code doit être composé de 4 Chiffres
          </span>
        </dd>
        <p
          class="text-muted col-sm-8 offset-sm-4"
          v-if="coach.coach.isCoach || coach.coach.isFormer"
        >
          Le code vous sert à accéder à l'application Just Coaching.
        </p>
        <dt class="col-sm-4">
          Mot de passe
        </dt>
        <dd class="col-sm-8">
          <button
            @click="modalChangePassword = true"
            class="btn btn-primary btn-sm"
          >
            Changer
          </button>
        </dd>
        <dt class="col-sm-4">Photo</dt>
        <dd class="col-sm-8">
          <div class="row">
            <div v-if="coach.coach.picture" class="col-sm-6">
              <img :src="getPicture" class="rounded img-responsive" />
            </div>
            <div class="col-sm-6">
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="photo"
                acceptedFileTypes="image/*"
                :maxNumberOfFiles="1"
                :maxFileSizeInMB="8"
                v-on:vdropzone-success="photoUploaded"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </div>
          </div>
        </dd>
        <template v-if="!isOnlyInfluencer">
          <dt class="col-sm-4">Prestations</dt>
          <dd class="col-sm-8">
            <span v-if="coach.coach.prestations">{{
              coach.coach.prestations.join(", ")
            }}</span>
          </dd>
        </template>
      </dl>
    </div>

    <div v-if="!isOnlyInfluencer" class="card-footer card-inverse card-info">
      Changez vos informations directement depuis l'application
    </div>

    <ChangeCoachPassword 
      :email="coach.email"
      :open="modalChangePassword" 
    />
  </div>
</template>

<script>
import axios from "axios";
import Dropzone from "vue2-dropzone";
import ChangeCoachPassword from "./modal/ChangeCoachPassword";

import CoachAccount from "../mixin/CoachAccount";

export default {
  components: {
    Dropzone,
    ChangeCoachPassword
  },

  mixins: [CoachAccount],

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalChangePassword: false
    }
  },

  computed: {
    validPin() {
      const pincode = this.coach.coach.pincode.toString();

      return pincode.length === 4 && pincode.toString().match(/^[0-9]+$/);
    },

    getPicture() {
      return `${axios.defaults.baseURL}users/${this.coach.id}/picture`;
    },
  },

  methods: {
    async editCoach(name, value) {
      var data = {};

      if (name === "pincode" && !this.validPin) {
        return;
      }

      data[name] = value;

      await this.$api.put("/app", data);
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    async photoUploaded(file, response) {
      await this.$api.post(`/users/picture/${this.coach.id}`, {
        file: response.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.has-danger-pincode {
  input {
    border-color: #8a6d3b;
  }
}
</style>
