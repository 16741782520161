<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header font-weight-bold">Balance comptable</div>

      <div class="card-block">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-6">
                <label for="">Société *</label>
                <v-select
                  :options="merchantOptions"
                  v-model="merchant"
                ></v-select>
              </div>
              <div class="col-sm-6">
                <label for="">Concept </label>
                <v-select
                  :options="conceptOptions"
                  v-model="concept"
                ></v-select>
              </div>
            </div>
            <div class="row mt-1">
              <div class="form-group col-sm-6">
                <label>Date de début *</label>
                <datepicker
                  v-model="begin"
                  format="dd.MM.yyyy"
                  language="fr"
                  input-class="form-control"
                ></datepicker>
              </div>
              <div class="form-group col-sm-6">
                <label>Date de fin *</label>
                <datepicker
                  v-model="end"
                  format="dd.MM.yyyy"
                  language="fr"
                  input-class="form-control"
                ></datepicker>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-sm-12">
                <button
                  type="button"
                  class="btn btn-sm btn-secondary float-right"
                  @click="loadData"
                  :disabled="
                    !hasPermission(
                      $store.state.user,
                      'SETTINGS_SOLDE_COMPTABLE'
                    )
                  "
                >
                  <i class="icon-eye"></i>&nbsp; Visualiser
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <ExportSoldeComptableResult v-if="result" :result="result" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import ExportSoldeComptableResult from "../components/ExportSoldeComptableResult";

export default {
  components: {
    Datepicker,
    ExportSoldeComptableResult,
  },
  data() {
    return {
      begin: moment()
        .subtract(6, "months")
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DD"),
      end: moment()
        .subtract(1, "months")
        .endOf("month")
        .startOf("day")
        .format("YYYY-MM-DD"),
      result: null,
      merchant: null,
      concept: null,
      merchantOptions: [],
      conceptOptions: [],
    };
  },

  async created() {
    await this.getMerchants();
    await this.getConcepts();
  },

  methods: {
    async loadData() {
      if (!this.begin || !this.end || !this.merchant || !this.merchant.id) {
        this.$errorToast("* Certains champs sont obligatoire");
      }

      const { data } = await this.$api.get("/sessions/solde-comptable", {
        params: {
          begin: this.begin,
          end: this.end,
          merchant: this.merchant.id,
          concept: this.concept,
        },
      });

      this.result = data;
      this.result.begin = moment(this.begin, "YYYY-MM-DD").format("DD-MM-YYYY");
      this.result.end = moment(this.end, "YYYY-MM-DD").format("DD-MM-YYYY");
    },

    async getMerchants() {
      try {
        const { data } = await this.$api.get(`/merchants`);

        this.merchantOptions = data.map((c) => {
          return {
            id: c.id,
            label: c.name,
          };
        });
      } catch (e) {
        console.log(e);
      }
    },

    async getConcepts() {
      try {
        const { data } = await this.$api.get(`/concepts`);

        this.conceptOptions = data.map((c) => c.name);
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
