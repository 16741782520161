<template>
  <div>
    <p v-if="paginatedSessions.length === 0" class="text-center text-muted">
      Aucun cours solo {{ oldSession ? "passé" : "à venir" }}
    </p>

    <template v-else>
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>Nom du client</th>
            <th>Avoir</th>
            <th>Programmée le</th>
            <th>Lien Room</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(session, index) in paginatedSessions"
            :key="index"
            class="click"
          >
            <td>
              {{ session.client.name }}
            </td>
            <td>{{ formatPrice(session.credit, true) }}</td>
            <td>
              <strong>{{ getDay(session.scheduledAt) }}</strong>
              {{ formatDateTime(session.scheduledAt) }}
            </td>
            <td>
              <button class="btn btn-info" @click="goToRoom(session)">
                Rejoindre la séance
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :click-handler="handleCurrentChange"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
      ></paginate>
    </template>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  components: {
    Paginate,
  },

  props: {
    sessions: {
      type: Array,
      default: () => [],
    },

    oldSession: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      limit: 10,
    };
  },

  computed: {
    paginatedSessions() {
      return this.sessions.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.sessions.length / this.limit);
    },

    clientAppUrl() {
      return process.env.VUE_CLIENT_APP_URL || "https://app.justcoaching.fr/";
    },
  },

  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    goToRoom(session) {
      const route = `${this.clientAppUrl}live/solo/${session.id}?secret=${session.coachSecret}`;

      window.open(route);
    },
  },
};
</script>

<style scoped>
.table {
  overflow-x: auto;
}
</style>
