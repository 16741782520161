<template>
  <div class="card">
    <div class="card-header"><i class="icon-refresh"></i> Filtrer</div>
    <div class="card-block">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Date de début</label>
          <datepicker
            v-model="form.begin"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
        <div class="form-group col-sm-6">
          <label>Date de fin</label>
          <datepicker
            v-model="form.end"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-4" v-if="merchantsOptions">
          <label>Filtrer par société</label>
          <v-select
            :options="merchantsOptions"
            v-model="form.merchant"
          ></v-select>
        </div>

        <div class="form-group col-sm-4" v-if="concepts">
          <label>Filtrer par concept</label>
          <v-select :options="concepts" v-model="form.concept"></v-select>
        </div>

        <div class="form-group col-sm-4" v-if="invoiceSeries && !stats">
          <label>Filtrer par code</label>
          <v-select
            :options="
              invoiceSeries
                .filter((is) => is.code !== 'VISIO')
                .map((is) => is.code)
            "
            v-model="form.invoiceSerieCode"
          ></v-select>
        </div>

        <div
          class="form-group"
          :class="!unpaid && !stats ? 'col-sm-3' : 'col-sm-4'"
        >
          <label>Filtrer par client</label>
          <v-select
            v-model="form.client"
            :options="clients"
            @search="searchClients"
          ></v-select>
        </div>

        <div class="form-group col-sm-3" v-if="!unpaid">
          <label>Filtrer par statut</label>
          <multiselect
            v-model="form.status"
            :options="orderStatus"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>
        <div
          class="form-group"
          v-if="!stats"
          :class="!unpaid ? 'col-sm-3' : 'col-sm-4'"
        >
          <label>Filtrer par numéro de facture</label>
          <input
            class="form-control"
            placeholder="202103COMP"
            v-model="form.match"
          />
        </div>
        <div class="form-group" :class="!unpaid ? 'col-sm-3' : 'col-sm-4'">
          <label>Commercial</label>
          <multiselect
            v-model="form.commercials"
            :options="commercials"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>

        <div class="form-group" :class="!unpaid ? 'col-sm-3' : 'col-sm-4'">
          <label>Filtrer par département</label>
          <multiselect
            v-model="form.zipcodes"
            :options="zipcodes"
            :multiple="true"
            label="label"
            track-by="label"
            class="custom-multiselect"
          >
          </multiselect>
        </div>

        <div class="form-group" :class="!unpaid ? 'col-sm-3' : 'col-sm-4'">
          <label>Filtrer par type de commande</label>
          <v-select
            v-model="form.type"
            :options="orderTypes"
            placeholder="Tout"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="card-header">
      <div class="float-right" v-if="!unpaid && !stats">
        NOVA
        <label class="switch switch-sm switch-text switch-info mb-0">
          <input
            type="checkbox"
            class="switch-input"
            v-model="formShowNova"
            @change="toggleShowNova"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>

      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="loadInvoices()"
      >
        <i class="icon-eye"></i>&nbsp; Visualiser
      </button>
    </div>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import Multiselect from "vue-multiselect";

export default {
  components: {
    vSelect,
    Datepicker,
    Multiselect,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    commercials: {
      type: Array,
      required: true,
    },
    zipcodes: {
      type: Array,
      default: () => [],
    },
    concepts: {
      type: Array,
      required: true,
    },
    merchantsOptions: {
      type: Array,
      required: true,
    },
    invoiceSeries: {
      type: Array,
      required: true,
    },
    showNova: {
      type: Boolean,
      default: false,
    },
    unpaid: {
      type: Boolean,
      default: false,
    },
    stats: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orderStatus: [
        { label: "Payée", value: "payed" },
        { label: "Partiel", value: "partial" },
        { label: "Avoir", value: "refunded" },
        { label: "Impayé (traité)", value: "unpaid" },
      ],
      clients: [],
      orderTypes: [
        { label: "Conversion", value: "conversion" },
        { label: "Acquisition", value: "acquisition" },
        { label: "Fidélisation", value: "fidelisation" },
        { label: "Découverte", value: "discovery" },
      ],
      formShowNova: false,
      form: {},
    };
  },

  async mounted() {
    this.form = this.filters;
  },

  methods: {
    async searchClients(search, loading) {
      if (search !== "") {
        await this.fetchClients(search, loading, this);
      }
    },

    fetchClients: debounce(async (search, loading, vm) => {
      if (!search || !search.lentgh > 3) {
        return;
      }

      loading(true);

      const { data } = await vm.$api.get(`/clients/filter?q=${search}`);

      vm.clients = data.map((c) => ({
        label: c.name + " (" + c.email + ")",
        value: c.email,
      }));

      loading(false);

      return vm.clients;
    }, 300),

    loadInvoices() {
      this.$emit("load", this.form);
    },

    toggleShowNova() {
      this.$emit("toggleShowNova");
    },
  },
};
</script>
