<template>
  <div class="wrapper animated fadeIn">
    <DisbursementsFilters @load="load" />

    <div class="card">
      <div class="card-header">
        <i class="icon-clock"></i> Avoirs à traiter
        <div class="pull-right">
          <span class="total inline-block">
            Total ({{ formatPrice(totalPending || "0.00€") }})
          </span>
        </div>
      </div>

      <div class="card-block">
        <Disbursements
          v-if="!isLoading"
          :disbursements-data="disbursements"
          pending
          coach
        />
        <Loader v-else :isVisible="isLoading" size="small" />
      </div>
    </div>
  </div>
</template>

<script>
import Disbursements from "../components/Disbursements";
import DisbursementsFilters from "../components/DisbursementsFilters";
import Loader from "../components/Loader";

export default {
  components: {
    Disbursements,
    DisbursementsFilters,
    Loader,
  },

  data() {
    return {
      disbursements: [],
      form: {
        merchant: null,
      },
      isLoading: false,
    };
  },

  computed: {
    totalPending() {
      let total = 0;

      if (this.disbursements?.length > 0) {
        this.disbursements.forEach((disbursement) => {
          total += disbursement.credit;
        });
      }

      return total;
    },
  },

  created() {
    this.getDisbursements();
  },

  methods: {
    async getDisbursements() {
      this.isLoading = true;

      const { data } = await this.$api.get("/disbursements", {
        params: {
          type: "not-processed",
          merchant: this.form.merchant?.value,
        },
      });

      this.disbursements = data;
      this.isLoading = false;
    },

    load(form) {
      this.form = form;

      this.getDisbursements();
    },
  },
};
</script>
