<template>
  <div class="row">
    <template v-if="!isOnlyInfluencer">
      <div
        class="col-sm-12"
        v-if="
          !coach.validated && (!coach.documents || coach.documents.length === 0)
        "
      >
        <div class="card card-inverse card-danger text-center">
          <div class="card-block">
            Vous n'avez pas encore envoyé vos documents
          </div>
        </div>
      </div>
      <div class="col-sm-12" v-else-if="!coach.validated">
        <div class="card card-inverse card-info text-center">
          <div class="card-block">
            Vos documents vont être validés par JustCoaching. <br />
            Vous pouvez encore ajouter ou modifier les documents.
          </div>
        </div>
      </div>
      <div class="col-sm-12" v-else>
        <div class="card card-inverse card-success text-center">
          <div class="card-block">Votre compte est validé</div>
        </div>
      </div>
    </template>

    <tabs v-if="coach" v-model="activeTab" class="col-12 account-tabs">
      <tab header="Général">
        <AccountGlobalTab :coach="coach" />
      </tab>

      <tab v-if="liveOrSoloCoach" header="Live">
        <AccountLiveTab />
      </tab>

      <tab
        v-if="!isOnlyInfluencer && showSessionValidation"
        :header="sessionsToValidateTabHeader"
      >
        <AccountValidateSessionsTab
          :sessionsToValidate="sessionsToValidate"
          :validated="validated"
          :sessions="sessions"
          @fetchApi="fetchApi"
          @updateSession="updateSession"
        />
      </tab>

      <tab
        v-if="showInvoicesTab"
        :header="isOnlyInfluencer ? 'Avoirs Codes Promo' : 'Mes factures'"
      >
        <AccountInvoicesTab :coach="coach" />
      </tab>

      <tab header="Mes documents">
        <AccountDocumentsTab :coach="coach" />
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import AccountGlobalTab from "../components/AccountGlobalTab";
import AccountLiveTab from "../components/AccountLiveTab";
import AccountInvoicesTab from "../components/AccountInvoicesTab";
import AccountDocumentsTab from "../components/AccountDocumentsTab";
import AccountValidateSessionsTab from "../components/AccountValidateSessionsTab";

import CoachAccount from "../mixin/CoachAccount";

export default {
  components: {
    Tabs,
    Tab,
    AccountGlobalTab,
    AccountLiveTab,
    AccountInvoicesTab,
    AccountDocumentsTab,
    AccountValidateSessionsTab,
  },

  mixins: [CoachAccount],

  data() {
    return {
      sessions: [],
      activeTab: 0,
      sessionsToValidate: 0,
      showSessionValidation: false,
      validated: false,
    };
  },

  created() {
    this.$store.dispatch("loadMerchants");

    const { validate } = this.$route.query;

    if (validate) {
      this.activeTab = this.liveOrSoloCoach ? 2 : 1;
    }
  },

  computed: {
    ...mapState({
      coach: (state) => state.user,
      merchants: (state) => state.merchants,
    }),

    liveOrSoloCoach() {
      return (
        this.coach &&
        (this.coach.coach.isCoach || this.coach.coach.isFormer) &&
        this.coach.coach.live
      );
    },

    showInvoicesTab() {
      return this.coach?.coach?.isInfluencer || this.coach?.disbursement;
    },

    sessionsLength() {
      let length = 0;

      this.sessions.forEach((s) => {
        if (s.children) {
          length += s.children.length;
        }
      });

      return length;
    },

    sessionsToValidateTabHeader() {
      return `Mes séances à valider${
        this.sessionsToValidate ? ` (${this.sessionsToValidate})` : ""
      }`;
    },
  },

  mounted() {
    this.fetchSessionsWithRequiredUserCount(this.validated);
  },

  methods: {
    async fetchSessionsWithRequiredUserCount(validated) {
      try {
        const { data } = await this.$api.get("/users/required-count-sessions", {
          params: {
            validated,
          },
        });

        this.sessions = data;

        if (validated) {
          this.validated = true;
        } else {
          this.validated = false;
          this.sessionsToValidate = this.sessionsLength;
          if (this.sessionsToValidate > 0) {
            this.showSessionValidation = true;
          }
        }
      } catch (e) {
        this.sessions = [];
      }
    },

    fetchApi(boolean) {
      this.fetchSessionsWithRequiredUserCount(boolean);
    },

    updateSession(session) {
      const { id, nbOfParticipants, scheduledAt, client } = session;

      if (
        nbOfParticipants === null ||
        nbOfParticipants === undefined ||
        isNaN(parseInt(nbOfParticipants))
      ) {
        return;
      }

      const message = `Etes-vous sûr.e de vouloir valider la séance du ${this.formatDate(
        scheduledAt
      )}${
        client ? ` pour ${client}` : ""
      } avec ${nbOfParticipants} participants ?`;

      this.$confirm({
        message,
        onConfirm: async () => {
          try {
            await this.$api.post(`/sessions/${id}/validate-user-count`, {
              nbOfParticipants,
            });

            this.sessions = this.sessions.map((s) => ({
              ...s,
              children: s.children.filter((c) => c.id !== id),
            }));

            this.sessionsToValidate--;

            if (this.sessionsToValidate === 0) {
              window.location.reload();
            }
          } catch (e) {
            console.warn(e);
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
.account-tabs {
  & > .tab-content {
    border: none;
    background: transparent;
    & > .tab-pane {
      padding: 0;
    }
  }
}
</style>
