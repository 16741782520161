<template>
  <div class="animated fadeIn" v-if="merchant">
    <div class="card">
      <div class="card-header">
        <i class="icon-globe"></i> Société : {{ merchant.name }}
        <button
          type="button"
          class="btn btn-sm btn-danger float-right"
          @click="removeMerchant()"
          v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
        >
          Supprimer
        </button>
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Adresse</dt>
          <dd
            class="col-sm-9 editable"
            edit="address"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.address }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.address }}
          </dd>
          <dt class="col-sm-3">Code postal</dt>
          <dd
            class="col-sm-9 editable"
            edit="zipcode"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.zipcode }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.zipcode }}
          </dd>
          <dt class="col-sm-3">Ville</dt>
          <dd
            class="col-sm-9 editable"
            edit="city"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.city }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.city }}
          </dd>
          <dt class="col-sm-3">Pays</dt>
          <dd
            class="col-sm-9 editable"
            edit="country"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.country }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.country }}
          </dd>
          <dt class="col-sm-3">Téléphone</dt>
          <dd
            class="col-sm-9 editable"
            edit="phone"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.phone }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.phone }}
          </dd>
          <dt class="col-sm-3">Email</dt>
          <dd
            class="col-sm-9 editable"
            edit="email"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.email }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.email }}
          </dd>
          <dt class="col-sm-3">IBAN</dt>
          <dd
            class="col-sm-9 editable"
            edit="iban"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.iban }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.iban }}
          </dd>
          <dt class="col-sm-3">Forme juridique</dt>
          <dd
            class="col-sm-9 editable"
            edit="legal"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.legal }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.legal }}
          </dd>
          <dt class="col-sm-3">SIRET N°</dt>
          <dd
            class="col-sm-9 editable"
            edit="siret"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.siret }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.siret }}
          </dd>
          <dt class="col-sm-3">TVA N°</dt>
          <dd
            class="col-sm-9 editable"
            edit="vat"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.vat }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.vat }}
          </dd>
          <dt class="col-sm-3">Capital</dt>
          <dd
            class="col-sm-9 editable"
            edit="capital"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.capital }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.capital }}
          </dd>
          <dt class="col-sm-3">Code APE</dt>
          <dd
            class="col-sm-9 editable"
            edit="ape"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="edited"
          >
            {{ merchant.ape }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.ape }}
          </dd>
        </dl>
      </div>
      <div class="card-footer">
        Identifiant API : <strong>{{ merchant.id }}</strong>
      </div>
    </div>

    <div class="card" v-if="merchant">
      <div class="card-header">
        <i class="icon-envelope"></i> Réglages alertes email
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Paiements</dt>
          <dd
            class="col-sm-9 editable"
            edit="payment"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="notificationsEdited"
          >
            <span v-if="merchant.notifications">{{
              merchant.notifications.payment
            }}</span>
          </dd>
          <dd class="col-sm-9" v-else>
            <span v-if="merchant.notifications">{{
              merchant.notifications.payment
            }}</span>
          </dd>
        </dl>
        <p>Note : utiliser des virgules pour définir plusieurs adresses.</p>
      </div>
    </div>

    <div class="card" v-if="merchant">
      <div class="card-header">
        <i class="icon-support"></i> Service à la personne
        <label
          class="switch switch-sm switch-text switch-info float-right mb-0"
        >
          <input
            type="checkbox"
            class="switch-input"
            v-model="merchant.sap.available"
            @click="handleSap"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
          />
          <span class="switch-label" data-on="On" data-off="Off"></span>
          <span class="switch-handle"></span>
        </label>
      </div>
      <div class="card-block" v-if="merchant.sap.available">
        <dl class="row">
          <dt class="col-sm-3">N°</dt>
          <dd
            class="col-sm-9 editable"
            edit="number"
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            :contenteditable="true"
            v-on:blur="sapEdited"
          >
            {{ merchant.sap.number }}
          </dd>
          <dd class="col-sm-9" v-else>
            {{ merchant.sap.number }}
          </dd>
          <dt class="col-sm-3">Délivré le</dt>
          <datepicker
            v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
            v-model="merchant.sap.deliveredAt"
            v-on:closed="handleSapDate"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          ></datepicker>
          <span v-else>
            {{ formatDate(merchant.sap.deliveredAt) }}
          </span>
        </dl>
      </div>
    </div>

    <div class="card" v-if="merchant">
      <div class="card-header">
        <i class="icon-docs"></i> Réglages facturation
      </div>
      <div class="card-block">
        <dl class="row">
          <dt class="col-sm-3">Signature</dt>
          <dd class="col-sm-9">
            <div v-if="merchant.signatureId">
              <img
                :src="signature()"
                height="64"
                v-if="merchant.signatureFile"
              />
              <button
                type="button"
                class="btn btn-sm btn-danger"
                @click="removeSignature()"
                v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
              >
                Supprimer
              </button>
            </div>
            <template v-else>
              <dropzone
                :url="apiUploads()"
                :headers="apiAuthorization()"
                id="signatureFile"
                v-on:vdropzone-success="signatureUploaded"
                v-if="hasPermission($store.state.user, 'MERCHANTS_WRITE')"
              >
                <div class="dz-default dz-message">
                  <span
                    ><i class="icon-cloud-upload"></i><br />Glissez l'image
                    ici</span
                  >
                </div>
              </dropzone>
            </template>
          </dd>
        </dl>
      </div>
    </div>

    <div class="card">
      <div class="card-header"><i class="icon-puzzle"></i> Concepts</div>
    </div>

    <div
      v-for="concept in merchant.concepts"
      :key="concept.id"
      class="card inline-card"
    >
      <div class="card-header inline-card-header">
        Concept: {{ concept.conceptName }}
      </div>
      <div class="card-block">
        <div class="card" v-if="concept.invoiceSerie">
          <div class="card-header">
            <i class="icon-docs"></i> Réglages facturation
          </div>
          <div class="card-block">
            <dl class="row">
              <dt class="col-sm-4">Code</dt>
              <dd class="col-sm-8">
                {{ concept.invoiceSerie.code }}
                <br />
                <p class="text-muted">
                  Le code des factures : 201706{{
                    concept.invoiceSerie.code
                  }}000023.pdf
                </p>
              </dd>
              <dt class="col-sm-4">Prochain numéro</dt>
              <dd class="col-sm-8">
                <strong>{{ concept.invoiceSerie.nextNumber }}</strong>
              </dd>
              <template>
                <dt class="col-sm-4">Lien facture Sendgrid</dt>
                <dd
                  class="col-sm-8"
                  v-if="concept.sendgrid && concept.sendgrid.invoice"
                >
                  {{ concept.sendgrid.invoice }}
                </dd>
                <dd class="col-sm-8" v-else>Non</dd>
              </template>
            </dl>
          </div>
        </div>

        <div
          class="card"
          v-if="concept.invoiceSerie && concept.invoiceSerie.stripe"
        >
          <div class="card-header">
            <i class="icon-credit-card"></i> Réglages Stripe
          </div>
          <div class="card-block">
            <dl class="row">
              <dt class="col-sm-4">Live Secret Key</dt>
              <dd class="col-sm-8 o-scroll">
                <span
                  v-if="concept.invoiceSerie && concept.invoiceSerie.stripe"
                  >{{ concept.invoiceSerie.stripe.live_secret_key }}</span
                >
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">Live Publishable Key</dt>
              <dd class="col-sm-8 o-scroll">
                <span
                  v-if="concept.invoiceSerie && concept.invoiceSerie.stripe"
                  >{{ concept.invoiceSerie.stripe.live_publishable_key }}</span
                >
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">Test Secret Key</dt>
              <dd class="col-sm-8 o-scroll">
                <span
                  v-if="concept.invoiceSerie && concept.invoiceSerie.stripe"
                  >{{ concept.invoiceSerie.stripe.test_secret_key }}</span
                >
              </dd>
            </dl>
            <dl class="row">
              <dt class="col-sm-4">Test Publishable Key</dt>
              <dd class="col-sm-8 o-scroll">
                <span
                  v-if="concept.invoiceSerie && concept.invoiceSerie.stripe"
                  >{{ concept.invoiceSerie.stripe.test_publishable_key }}</span
                >
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-if="merchant">
      <div class="card-header">
        <i class="fa fa-cloud-upload"></i> Import Excel depuis GrandTotal
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-sm-4">
            <dl>
              <dt>Concept</dt>
              <dd>justcoaching.fr</dd>
              <dt>Société</dt>
              <dd>{{ merchant.name }}</dd>
            </dl>
          </div>
          <div class="col-sm-8">
            <dropzone
              id="file"
              :url="apiGrandTotal()"
              :headers="apiAuthorization()"
              acceptedFileTypes="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
              v-on:vdropzone-success="excelUploaded"
              v-if="
                !excelUploadedSuccess &&
                hasPermission($store.state.user, 'MERCHANTS_WRITE')
              "
            >
              <div class="dz-default dz-message">
                <span
                  ><i class="icon-cloud-upload"></i><br />Glissez le template de
                  commandes GrandTotal au format EXCEL</span
                >
              </div>
            </dropzone>
            <p class="text-success" v-else>
              <strong>{{ excelUploadedSuccess }}</strong> commandes ajoutées
              avec succès !
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import Datepicker from "vuejs-datepicker";
import Dropzone from "vue2-dropzone";

export default {
  components: {
    Datepicker,
    Dropzone,
  },
  data() {
    return {
      excelUploadedSuccess: 0,
      merchant: null,
    };
  },
  async created() {
    try {
      this.getMerchant();
    } catch (e) {
      console.warn(e);
    }
  },
  methods: {
    async getMerchant() {
      const { data } = await this.$api.get(
        `/merchants/${this.$route.params.id}`
      );

      data.sap = data.sap || {};
      this.merchant = data;
    },

    edited: async function (event) {
      let body = {};
      let edit = event.target.getAttribute("edit");
      body[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
      event.target.innerHTML = body[edit];

      await this.updateMerchant(body);
    },

    notificationsEdited: async function (event) {
      let body = {
        notifications: {},
      };
      let edit = event.target.getAttribute("edit");
      body.notifications[edit] = event.target.innerHTML.replace(
        /(<([^>]+)>)/gi,
        ""
      );
      event.target.innerHTML = body.notifications[edit];

      await this.updateMerchant(body);
    },

    sapEdited: async function (event) {
      let body = {
        sap: {},
      };
      let edit = event.target.getAttribute("edit");
      body.sap[edit] = event.target.innerHTML.replace(/(<([^>]+)>)/gi, "");
      event.target.innerHTML = body.sap[edit];

      await this.updateMerchant(body);
    },

    handleSap: async function (event) {
      await this.updateMerchant({
        sap: {
          available: event.target.checked,
        },
      });
    },

    async handleSapDate() {
      await this.updateMerchant({
        sap: {
          deliveredAt: this.merchant.sap.deliveredAt,
        },
      });
    },

    removeMerchant() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete(`/merchants/${this.$route.params.id}`);

          this.$router.push({
            name: "merchants",
          });

          window.location.reload(true);
        },
      });
    },

    apiGrandTotal() {
      return `${axios.defaults.baseURL}grandtotal/${this.merchant.id}`;
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    excelUploaded(file, response) {
      this.excelUploadedSuccess = response;
    },

    goToInvoice(order) {
      this.$router.push({
        name: "order-details",
        params: {
          id: order.id,
        },
      });
    },

    apiUploads() {
      return `${axios.defaults.baseURL}uploads`;
    },

    signature() {
      return `data:${this.merchant.signatureFile.mimetype};base64,${this.merchant.signatureFile.data}`;
    },

    async removeSignature() {
      await this.updateMerchant({
        signatureId: null,
      });
    },

    async signatureUploaded(file, response) {
      await this.updateMerchant({
        signatureId: response.id,
      });
    },

    async updateMerchant(body) {
      const { data } = await this.$api.put(
        `/merchants/${this.$route.params.id}`,
        body
      );

      this.merchant = data;
    },
  },
};
</script>

<style type="text/css">
.inline-card {
  padding: 0;
  background-color: transparent;
  border: none;
}

.inline-card > .card-header {
  border: 1px solid #e1e6ef;
}

.inline-card > .card-block {
  display: flex;
  flex-direction: row;
  padding: 0 0.5em;
  margin-top: 1em;
  column-gap: 15px;
}

.inline-card > .card-block > .card {
  width: calc(50% - 15px);
  margin-bottom: 0;
}

.o-scroll {
  overflow-x: scroll;
}
</style>
