<template>
  <div class="attrib-custom-wrapper">
    <loader :isVisible="isLoading" />
    <router-view></router-view>
    <div
      v-if="['attributions'].indexOf($route.name) > -1"
      class="animated fadeIn"
    >
      <AttributionListFilters
        :default="filters"
        :pushLoading="pushLoading"
        @applyFilters="applyFilters"
        @loadLists="loadLists"
        @pushAll="pushAll"
      />
      <tabs v-model="activeTab" @input="changeTab">
        <tab :header="ordersHeader">
          <attributions
            :attributions="orders"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="notPayedHeader">
          <attributions
            :attributions="notPayed"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="clientsHeader">
          <attributions
            :attributions="clients"
            :clients="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="prospectsHeader">
          <attributions
            :attributions="prospects"
            :clients="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="paymentsHeader">
          <attributions
            :attributions="payments"
            :payments="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
        <tab :header="recallsHeader">
          <attributions
            :attributions="recalls"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>

        <tab :header="livesHeader">
          <AttributionsLive
            :attributions="lives"
            :orders="true"
            @click="goToDetails"
            @delete="deleteAttribution"
            @moveAttribution="moveAttribution"
            @allowAttribution="allowAttribution"
            @showComment="displayComment"
          />
        </tab>
      </tabs>
    </div>
    <modal
      v-if="modalSendAttribution.attribution"
      title="Envoyer les attributions"
      v-model="modalSendAttribution.allowType"
      cancelText="Annuler"
      okText="Valider"
      @ok="sendAttribution()"
      effect="fade/zoom"
    >
      <div v-if="modalSendAttribution.coachs.length > 0">
        <p>Voulez-vous vraiment envoyer les attributions ?</p>
        <p>{{ attributionCoachsLabel }}</p>
      </div>
      <p v-else>Aucuns coachs disponible</p>
    </modal>
    <modal
      v-if="modalUpdateComment.commentaireOrder"
      title="Commentaire"
      v-model="modalUpdateComment.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.commentaireOrder.adminNotes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Attributions from "../components/Attributions";
import AttributionListFilters from "../components/AttributionListFilters";
import AttributionsLive from "../components/AttributionsLive";
import Modal from "vue-strap/src/Modal";
import Loader from "../components/Loader";

export default {
  components: {
    Attributions,
    AttributionListFilters,
    AttributionsLive,
    Tabs,
    Tab,
    Modal,
    Loader,
  },
  data() {
    return {
      filters: {
        commercials: [],
      },
      activeTab: 0,
      isLoading: false,
      Loader: false,
      pushLoading: false,
      clients: [],
      prospects: [],
      orders: [],
      notPayed: [],
      payments: [],
      recalls: [],
      lives: [],
      modalSendAttribution: {
        allowType: false,
        attribution: null,
        coachs: [],
      },
      modalUpdateComment: {
        showComment: false,
        commentaireOrder: null,
      },
    };
  },
  computed: {
    ordersHeader() {
      return `Commandes (${this.orders.length})`;
    },
    notPayedHeader() {
      return `Non payées (${this.notPayed.length})`;
    },
    clientsHeader() {
      return `Clients (${this.clients.length})`;
    },
    prospectsHeader() {
      return `Prospects (${this.prospects.length})`;
    },
    paymentsHeader() {
      return `Paiements (${this.payments.length})`;
    },
    recallsHeader() {
      return `Rappels (${this.recalls.length})`;
    },
    livesHeader() {
      return `Lives & Visio (${this.lives.length})`;
    },

    attributionCoachsLabel() {
      if (
        !this.modalSendAttribution.coachs ||
        this.modalSendAttribution.coachs < 0
      ) {
        return "";
      }

      return this.modalSendAttribution.coachs.length > 1
        ? this.modalSendAttribution.coachs.length +
            " coach(s) seronts ajouté(s)."
        : "1 coach sera ajouté.";
    },
  },
  created() {
    let activeTab = sessionStorage.getItem("attributionsTab");
    if (activeTab) {
      this.activeTab = parseInt(activeTab);
    }
  },
  methods: {
    async loadLists() {
      this.isLoading = true;

      const commercials = this.filters.commercials.map((c) => c.value);
      const data = {
        params: { commercials },
      };

      await this.$api.get("/orders/attribution", data).then((res) => {
        this.orders = res.data.filter(
          (attr) =>
            !attr.recall &&
            !attr.isNewPayment &&
            attr.order.status !== "pending" &&
            !attr.order.live
        );
        this.notPayed = res.data.filter(
          (attr) =>
            !attr.recall && attr.order.status === "pending" && !attr.order.live
        );
        this.recalls = res.data.filter((attr) => attr.recall);
        this.lives = res.data.filter((attr) => attr.order.live);

        this.payments = res.data.filter(
          (attr) => attr.isNewPayment && !attr.recall
        );
      });

      await this.$api.get("/clients/attribution", data).then((res) => {
        this.clients = res.data;
      });

      await this.$api.get("/prospects/attribution", data).then((res) => {
        this.prospects = res.data;
      });

      this.isLoading = false;
    },

    deleteAttribution(attributionId, type) {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete(`/attributions/${attributionId}`);

          this[type] = this[type].filter((v) => v.id !== attributionId);
        },
      });
    },

    changeTab(index) {
      sessionStorage.setItem("attributionsTab", index);
    },

    goToDetails(attribution, details) {
      const route = this.$router.resolve({
        name: "attributions-" + details,
        params: {
          id: attribution.id,
        },
      });

      window.open(route.href, "_blank");
    },

    moveAttribution(attribution) {
      if (
        !attribution.recall &&
        !attribution.isNewPayment &&
        attribution.order.status !== "pending"
      ) {
        this.orders.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else if (!attribution.recall && attribution.isNewPayment) {
        this.payments.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else if (
        !attribution.recall &&
        attribution.order.status === "pending"
      ) {
        this.notPayed.push(attribution);
        this.recalls = this.recalls.filter(
          (attr) => attribution.id !== attr.id
        );
      } else {
        this.recalls.push(attribution);
        this.orders = this.orders.filter((attr) => attribution.id !== attr.id);
        this.notPayed = this.notPayed.filter(
          (attr) => attribution.id !== attr.id
        );
        this.payments = this.payments.filter(
          (attr) => attribution.id !== attr.id
        );
      }
    },
    pushAll() {
      let targets;
      this.pushLoading = true;

      switch (this.activeTab) {
        case 0:
          targets = this.orders;
          break;
        case 1:
          targets = this.notPayed;
          break;
        case 2:
          targets = this.clients;
          break;
        case 3:
          targets = this.recalls;
          break;
      }

      this.$confirm({
        message: "Envoyer un rappel à tous les coachs qui n'ont pas répondu ?",
        onConfirm: async () => {
          for (const attribution of targets) {
            await this.$api.put(`/attributions/notification/${attribution.id}`);
          }
        },
      });

      this.pushLoading = false;
    },
    async allowAttribution(attribution) {
      this.modalSendAttribution.allowType = true;
      this.modalSendAttribution.attribution = attribution;
      const coachs = attribution.coachs.filter((c) => c.coach);

      const { data } = await this.$api.post("/sessions/coachs", {
        attributionId: attribution.id,
        email: attribution.order.client,
        appli: 1,
        gender: null,
        status: [],
        prestations: null,
        tags: [],
        lang: null,
        range: 1,
        credit: 1500,
        availability: "0",
        periods: null,
        concat: coachs.map((c) => c.coach.id),
      });

      this.modalSendAttribution.coachs = data.coachs;
    },

    async sendAttribution() {
      let coachs = [];
      this.modalSendAttribution.coachs.forEach((c) => {
        coachs.push(c.email);
      });

      await this.$api.post(
        "/attributions/coachs/" + this.modalSendAttribution.attribution.id,
        {
          emails: coachs,
        }
      );

      await this.$api.put(
        "/attributions/notification/" + this.modalSendAttribution.attribution.id
      );

      this.modalSendAttribution = {
        allowType: false,
        attribution: null,
        coachs: [],
      };
    },
    displayComment(attribution) {
      this.modalUpdateComment.showComment = true;
      this.modalUpdateComment.commentaireOrder = attribution;
    },
    async updateComment() {
      await this.$api
        .put("/orders/" + this.modalUpdateComment.commentaireOrder.id, {
          adminNotes: this.modalUpdateComment.commentaireOrder.adminNotes,
        })
        .catch((err) => {
          console.log(err);
        });

      this.modalUpdateComment.showComment = false;
    },

    async applyFilters(form) {
      this.filters = {
        ...this.filters,
        ...form,
      };

      await this.loadLists();
    },
  },
};
</script>

<style lang="css">
/*[TODO] May we improved */
.attrib-custom-wrapper {
  max-width: calc(100vw - 320px);
}

@media (max-width: 991px) {
  .attrib-custom-wrapper {
    max-width: calc(100vw - 70px);
  }
}

.aside-menu-fixed.sidebar-hidden .attrib-custom-wrapper {
  max-width: calc(100vw - 70px);
}
</style>
