<template>
  <div class="row">
    <div class="col-12">
      <AccountPromoCodesCard
        v-if="isInfluencer"
        :coach="coach"
        :disbursed="disbursed"
        @disbursed="addDisbursement"
      />

      <AccountPromoCodesDisbursement
        v-if="isInfluencer && disbursed"
        :disbursements="disbursed"
      />

      <AccountInvoicesCard
        v-if="coach.disbursement"
        :coach="coach"
        :sessions="sessions"
        :disbursed="disbursed"
      />
    </div>
  </div>
</template>

<script>
import AccountPromoCodesCard from "../components/AccountPromoCodesCard";
import AccountPromoCodesDisbursement from "../components/AccountPromoCodesDisbursement";
import AccountInvoicesCard from "../components/AccountInvoicesCard";

export default {
  components: {
    AccountPromoCodesCard,
    AccountPromoCodesDisbursement,
    AccountInvoicesCard,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sessions: [],
      disbursed: [],
    };
  },

  computed: {
    isInfluencer() {
      return this.coach.coach?.isInfluencer;
    },
  },

  created() {
    this.fetchSessions();
    this.fetchDisbursements();
  },

  methods: {
    async fetchSessions() {
      try {
        const { data } = await this.$api.get("/app/disbursement/sessions");

        this.sessions = data;
      } catch (e) {
        this.sessions = [];
      }
    },

    async fetchDisbursements() {
      try {
        const { data } = await this.$api.get("/app/disbursement");

        this.disbursed = data;
      } catch (e) {
        this.disbursed = [];
      }
    },

    addDisbursement(disbursement) {
      this.disbursed.push(disbursement);
    },
  },
};
</script>
