<template>
  <div>
    <div v-if="data.length === 0" class="text-muted text-center">
      {{ noData }}
    </div>
    <el-table
      v-else
      :data="data"
      :default-sort="{ prop: 'until', order: 'descending' }"
    >
      <el-table-column label="Début" width="100">
        <template slot-scope="scope">
          {{ formatBanDate(scope.row.startedAt) }}<br />
          {{ formatBanTime(scope.row.startedAt) }}
        </template>
      </el-table-column>

      <el-table-column label="Fin" width="100" prop="until">
        <template slot-scope="scope">
          {{ formatBanDate(scope.row.until) }}<br />
          {{ formatBanTime(scope.row.until) }}
          <button
            class="btn btn-sm btn-link"
            @click="editEndDate(scope.row)"
            :disabled="!hasPermission($store.state.user, 'CLIENTS_WRITE')"
          >
            <i class="fa fa-edit" />
          </button>
        </template>
      </el-table-column>

      <el-table-column label="Raison" width="220">
        <template slot-scope="scope">
          <span v-if="scope.row.reason">{{
            displayBanReason(scope.row.reason)
          }}</span>
          <i v-else>Non précisé</i>
        </template>
      </el-table-column>

      <el-table-column label="Coach">
        <template slot-scope="scope">
          <a
            v-if="sessionCoach(scope.row)"
            :href="coachHref(scope.row.coach.id)"
            target="_blank"
          >
            {{ displaySessionCoach(scope.row) }}
          </a>
          <i v-else>Non précisé</i>
        </template>
      </el-table-column>

      <el-table-column label="Session" width="100">
        <template slot-scope="scope">
          <span
            v-if="scope.row.sessionLive"
            class="text-primary"
            style="cursor: pointer;"
            @click="showSessionLiveDetails(scope.row)"
          >
            Détails
          </span>
          <i v-else>N/A</i>
        </template>
      </el-table-column>

      <el-table-column label="Notes" width="75">
        <template slot-scope="scope">
          <span
            class="text-primary"
            style="cursor: pointer;"
            @click.stop="openNotesModal(scope.row)"
          >
            Ouvrir
          </span>
        </template>
      </el-table-column>

      <el-table-column width="40">
        <template slot-scope="scope">
          <span
            @click.stop="deleteBanishment(scope.row.id)"
            class="bg-danger action-btn font-weight-bold"
            >X</span
          >
        </template>
      </el-table-column>
    </el-table>

    <modal
      title="Notes Ban"
      v-model="modalUpdateNotes.show"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateNotes()"
      @cancel="closeNotesModal()"
      effect="fade/zoom"
    >
      <div v-if="modalUpdateNotes.error" class="alert alert-danger">
        Un problème est survenu, la modification n'a pas pu aboutir.
      </div>
      <el-input
        v-else-if="modalUpdateNotes.banishment"
        v-model="modalUpdateNotes.banishment.notes"
        type="textarea"
        :rows="3"
      />
    </modal>

    <modal
      title="Détails Session Live"
      v-model="modalSessionLiveDetails.show"
      cancelText="Fermer"
      okText="OK"
      @ok="modalSessionLiveDetails.show = false"
      effect="fade/zoom"
    >
      <div v-if="modalSessionLiveDetails.sessionLive" class="card m-0">
        <div class="card-block">
          <dl class="row m-0">
            <dt class="col-4">Date</dt>
            <dd class="col-8">
              {{
                (modalSessionLiveDetails.sessionLive.session &&
                  modalSessionLiveDetails.sessionLive.session.scheduledAt &&
                  formatSessionDate(
                    modalSessionLiveDetails.sessionLive.session.scheduledAt
                  )) ||
                  ""
              }}
            </dd>
            <dt class="col-4">Cours</dt>
            <dd class="col-8">
              {{
                (modalSessionLiveDetails.sessionLive.live &&
                  modalSessionLiveDetails.sessionLive.live.name) ||
                  ""
              }}
            </dd>
            <dt class="col-4">Type</dt>
            <dd class="col-8">
              {{
                (modalSessionLiveDetails.sessionLive.live &&
                modalSessionLiveDetails.sessionLive.live.type &&
                modalSessionLiveDetails.sessionLive.live.type === "small"
                  ? "VISIO"
                  : "LIVE") || ""
              }}
            </dd>
            <dt class="col-4">Coach</dt>
            <dd class="col-8">
              {{
                modalSessionLiveDetails.coach &&
                  `${modalSessionLiveDetails.coach.firstName ||
                    ""} ${modalSessionLiveDetails.coach.lastName || ""}`
              }}
            </dd>
          </dl>
        </div>
      </div>
    </modal>

    <modal
      title="Modifier la date de fin du ban"
      v-model="modalUpdateUntil.show"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateUntil()"
      @cancel="closeUntilModal()"
      effect="fade/zoom"
    >
      <div v-if="modalUpdateUntil.error" class="alert alert-danger">
        Un problème est survenu, la modification n'a pas pu aboutir.
      </div>
      <div v-else-if="modalUpdateUntil.banishment">
        <div class="form-group">
          <label>Date</label>
          <datepicker
            v-model="modalUpdateUntil.banishment.until"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
          />
        </div>
        <div class="form-group">
          <label>Heure</label>
          <div class="row m-0">
            <div class="col-5 p-0">
              <el-input-number
                v-model="modalUpdateUntil.hours"
                class="w-100"
                controls-position="right"
                :min="0"
                :max="23"
                :step="1"
                step-strictly
              ></el-input-number>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              :
            </div>
            <div class="col-5 p-0">
              <el-input-number
                v-model="modalUpdateUntil.minutes"
                class="w-100"
                controls-position="right"
                :min="0"
                :max="59"
                :step="1"
                step-strictly
              ></el-input-number>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Modal,
    Datepicker
  },

  props: {
    data: {
      type: Array,
      required: true
    },

    past: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      modalUpdateNotes: {
        show: false,
        banishment: null,
        originalNotes: null,
        error: false,
        updated: false
      },
      modalSessionLiveDetails: {
        show: false,
        sessionLive: null,
        coach: null
      },
      modalUpdateUntil: {
        show: false,
        banishment: null,
        originalDate: null,
        hours: 0,
        minutes: 0,
        error: false,
        updated: false
      }
    };
  },

  computed: {
    ...mapGetters({
      liveBan: "getLiveBanSettings"
    }),

    noData() {
      return `Aucun ban ${this.past ? "passé" : "en cours"}`;
    }
  },

  watch: {
    modalSessionLiveDetails: {
      deep: true,
      handler: modal => {
        if (!modal.show) {
          modal.sessionLive = null;
          modal.coach = null;
        }
      }
    }
  },

  methods: {
    formatBanDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    formatBanTime(date) {
      return moment(date).format("HH:mm");
    },

    displayBanReason(reason) {
      return (
        this.liveBan.reasons.find(rsn => rsn.value === reason)?.label ||
        "Non défini"
      );
    },

    sessionCoach(row) {
      return row.coach?.coach;
    },

    displaySessionCoach(row) {
      return `${row.coach?.coach?.firstName || ""} ${row.coach?.coach
        ?.lastName || ""}`;
    },

    coachHref(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id }
      });

      return route.href;
    },

    async deleteBanishment(banId) {
      this.$emit("delete-ban", banId);
    },

    openNotesModal(banishment) {
      this.modalUpdateNotes = {
        banishment,
        originalNotes: banishment.notes,
        show: true,
        error: false,
        updated: false
      };
    },

    closeNotesModal() {
      if (!this.modalUpdateNotes.updated) {
        this.modalUpdateNotes.banishment.notes = this.modalUpdateNotes.originalNotes;
      }

      this.modalUpdateNotes = {
        banishment: null,
        originalNotes: null,
        show: false,
        error: false,
        updated: false
      };
    },

    async updateNotes() {
      if (
        this.modalUpdateNotes.banishment.notes !==
        this.modalUpdateNotes.originalNotes
      ) {
        try {
          const data = {
            notes: this.modalUpdateNotes.banishment.notes
          };

          await this.$api.put(
            `/clients/banishment/${this.modalUpdateNotes.banishment.id}`,
            data
          );

          this.modalUpdateNotes.updated = true;
          this.closeNotesModal();
        } catch (e) {
          this.modalUpdateNotes.error = true;
        }
      } else {
        this.closeNotesModal();
      }
    },

    showSessionLiveDetails(ban) {
      const sessionLive = ban.sessionLive;
      const coach = this.sessionCoach(ban);

      this.modalSessionLiveDetails = {
        sessionLive,
        coach,
        show: true
      };
    },

    formatSessionDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },

    editEndDate(banishment) {
      const hours = parseInt(moment(banishment.until).format("H"));
      const minutes = parseInt(moment(banishment.until).format("m"));

      this.modalUpdateUntil = {
        ...this.modalUpdateUntil,
        banishment,
        originalDate: banishment.until,
        hours,
        minutes,
        show: true,
        error: false,
        updated: false
      };
    },

    closeUntilModal() {
      if (!this.modalUpdateUntil.updated) {
        this.modalUpdateUntil.banishment.until = this.modalUpdateUntil.originalDate;
      }

      this.modalUpdateUntil = {
        ...this.modalUpdateUntil,
        banishment: null,
        originalDate: null,
        hours: 0,
        minutes: 0,
        show: false,
        error: false,
        updated: false
      };
    },

    async updateUntil() {
      const until = moment(this.modalUpdateUntil.banishment.until)
        .startOf("d")
        .add(this.modalUpdateUntil.hours, "h")
        .add(this.modalUpdateUntil.minutes, "m")
        .toDate();

      if (!moment(until).isSame(this.modalUpdateUntil.originalDate, "minute")) {
        try {
          await this.$api.put(
            `/clients/banishment/${this.modalUpdateUntil.banishment.id}`,
            { until }
          );

          this.modalUpdateUntil.banishment.until = until;
          this.modalUpdateUntil.updated = true;
          this.closeUntilModal();
        } catch (e) {
          this.modalUpdateUntil.error = true;
        }
      } else {
        this.closeUntilModal();
      }
    }
  }
};
</script>
