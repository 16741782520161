<template>
  <div class="animated fadeIn">
    <el-table :data="coachs" stripe max-height="500">
      <el-table-column fixed prop="point.rank" sortable width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.point.result">
            #{{ scope.row.point.rank }} / {{ coachs.length }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed label="Nom" prop="lastName" sortable width="150">
        <template slot-scope="scope">
          <span class="user-link" @click="goToCoach(scope.row.id)">
            {{ scope.row.lastName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        fixed
        label="Prénom"
        prop="firstName"
        sortable
        width="150"
      >
        <template slot-scope="scope">
          <span class="user-link" @click="goToCoach(scope.row.id)">
            {{ scope.row.firstName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="Département"
        prop="department"
        width="140"
        sortable
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.department }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="conversionRate.rank"
        sortable
        v-if="filters.display.conversionRate"
        width="150"
      >
        <template v-slot:header>
          <label>Conversion </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Le taux de conversion correspond au pourcentage de clients
                convertis par le coach.
              </p>
              <ul>
                <li class="mt-1">
                  Si le coach a fait la commande découverte et la commande
                  conversion alors le poucentage augmente.
                </li>
                <li class="mt-1">
                  Si le coach a fait la découverte et le client n'a pas repris
                  de commande conversion alors le pourcentage diminue.
                </li>
                <li class="mt-1">
                  Si le coach n'a pas fait la commande découverte et/ou la
                  commande conversion alors le client est exclu du calcul.
                </li>
              </ul>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <ConversionCard
            :row="scope.row.conversionRate"
            :name="coachName(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="fidelisationRate.rank"
        sortable
        v-if="filters.display.fidelisationRate"
        width="150"
      >
        <template v-slot:header>
          <label>Fidelisation </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Le taux de fidélisation correspond au poucentage de clients
                fidélisé par le coach.
              </p>
              <ul>
                <li class="mt-1">
                  Si la commande a été traitée en fidélisé dans le menu
                  `Fidélisation` alors le pourcentage augmente
                </li>
                <li class="mt-1">
                  Si la commande a été traitée en `Perdu` ou `Perdu coach` dans
                  le menu `Fidélisation` alors le pourcentage s'ajuste
                </li>
              </ul>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <FidelisationCard
            :row="scope.row.fidelisationRate"
            :name="coachName(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="resubscribe.rank"
        width="200"
        sortable
        v-if="filters.display.resubscribe"
      >
        <template v-slot:header>
          <label>Réabonnement </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Le réabonnement est la quantité additionnée de toutes les
                commandes dont le coach est référent (découverte exclu)
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <ResubscribeCard
            :row="scope.row.resubscribe"
            :name="coachName(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="packAverage.rank"
        sortable
        width="200"
        v-if="filters.display.packAverage"
      >
        <template v-slot:header>
          <label>Pack moyen </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Le pack moyen est la moyenne de toutes les séances des commandes
                dont le coach est référent.
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <PackAverageCard
            :row="scope.row.packAverage"
            :name="coachName(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="clientSessionLifecycle.rank"
        width="200"
        sortable
        v-if="filters.display.clientSessionLifecycle"
      >
        <template v-slot:header>
          <label>Longévité client </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Pour la longévité client on calcule pour chaque client la
                moyenne des séances de toutes ses commandes, puis on fait la
                moyenne de tous les clients
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <ClientSessionLifecycleCard
            :row="scope.row.clientSessionLifecycle"
            :name="coachName(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="frequency.rank"
        width="200"
        sortable
        v-if="filters.display.frequency"
      >
        <template v-slot:header>
          <label>Fréquence </label>
          <el-popover trigger="hover" placement="top" :width="510">
            <div class="p-1 text-break">
              <p>
                La fréquence correspond à l'interval de temps entre 2 séances en
                moyenne. (Ce champ n'est pas définit par la période)
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <span v-if="scope.row.frequency.result">
            <span>#{{ scope.row.frequency.rank }}</span> - 1 /
            {{ scope.row.frequency.result }} jours
          </span>
          <span v-else>Indéfini</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="rank.rank"
        width="200"
        sortable
        v-if="filters.display.rank"
      >
        <template v-slot:header>
          <label>Note </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                La note correspond à la moyenne de toute les notes reçu par le
                coach. (Ce champ n'est pas définit par la période)
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <RankCard
            :row="scope.row.rank"
            v-if="scope.row.rank.result"
            :name="coachName(scope.row)"
          />
          <span v-else>Indéfini</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="validate.rank"
        width="200"
        sortable
        v-if="filters.display.validate"
      >
        <template v-slot:header>
          <label>Validée(s) </label>
          <el-popover trigger="hover" placement="top" :width="500">
            <div class="p-1 text-break">
              <p>
                Décompte du nombre de séance(s) marquée(s) comme `Validée(s)`
                par le coach sur l'application
              </p>
            </div>
            <span slot="reference">
              <i class="fa fa-info-circle ml-05"></i>
            </span>
          </el-popover>
        </template>
        <template slot-scope="scope">
          <ValidateCard
            :row="scope.row.validate"
            v-if="scope.row.validate.result"
            :filters="filters"
            :name="coachName(scope.row)"
          />
          <span v-else>Indéfini</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ConversionCard from "../components/rank/ConversionCard";
import FidelisationCard from "../components/rank/FidelisationCard";
import ResubscribeCard from "../components/rank/ResubscribeCard";
import PackAverageCard from "../components/rank/PackAverageCard";
import ClientSessionLifecycleCard from "../components/rank/ClientSessionLifecycleCard";
import ValidateCard from "../components/rank/ValidateCard";
import RankCard from "../components/rank/RankCard";

export default {
  components: {
    ConversionCard,
    FidelisationCard,
    ResubscribeCard,
    PackAverageCard,
    ClientSessionLifecycleCard,
    RankCard,
    ValidateCard
  },

  props: {
    filters: {
      required: true,
      type: Object
    },
    data: {
      required: true,
      type: Array
    }
  },

  computed: {
    coachs() {
      return this.data.filter(c => {
        if (this.filters.search) {
          const name = `${c.firstName} ${c.lastName}`;

          return name.match(new RegExp(this.filters.search, "i"));
        }

        return true;
      });
    }
  },

  methods: {
    goToCoach(id) {
      const route = this.$router.resolve({
        name: "user-details",
        params: { id }
      });

      window.open(route.href, "_blank");
    },

    coachName(object) {
      return `${object.firstName} ${object.lastName}`;
    }
  }
};
</script>

<style type="text/css" scoped>
.card {
  z-index: -1;
}

.text-break {
  word-break: break-word;
}

.el-table {
  overflow-x: auto;
}

.el-table__header-wrapper,
.el-table__footer-wrapper {
  overflow-x: visible;
}
.el-table::after {
  position: relative !important;
}
</style>
