<template>
  <div class="card commercial-relation">
    <div class="card-header">
      <div class="d-flex justify-content-between">
        <span class="font-weight-bold"> Commercial</span>
        <el-select
          value=""
          size="mini"
          @change="addCommercial($event)"
          v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
        >
          <el-option
            :value="commercial.value"
            v-for="commercial in available"
            :key="commercial.value"
            >{{ commercial.label }}</el-option
          >
        </el-select>
      </div>
      <div v-if="related.length > 0" style="padding: 0.5rem 0">
        <div
          v-for="(commercial, index) in related"
          :key="`commercial-${commercial.value}`"
          :class="`d-flex justify-content-between ${
            index === 0 ? 'my-1' : 'mb-1'
          }`"
        >
          <span
            class="text-white rounded-3 mr-1 d-flex align-items-center"
            style="padding: 7px"
            :style="commercial.color && `background-color:${commercial.color}`"
            :class="!commercial.color && 'bg-primary'"
          >
            <span>{{ commercial.label }}</span>
            <span
              class="ml-1"
              style="cursor: pointer"
              @click="confirmRemoveCommercial(commercial.value)"
              v-if="hasPermission($store.state.user, 'CLIENTS_WRITE')"
              ><i class="icon-close"></i
            ></span>
          </span>

          <datepicker
            v-model="commercial.relatedSince"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            @input="newCommercialRelationDate($event, commercial)"
            v-if="hasPermission($store.state.user, 'CLIENTS_COMMERCIALS_DATE')"
            :disabled="disabledDates"
          />
          <span v-else
            >Ajouté le {{ formatDate(commercial.relatedSince) }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    commercials: {
      type: Array,
      default: () => [],
    },

    disabledDates: {
      type: Object,
      required: true,
    },
  },

  computed: {
    related() {
      return this.commercials
        .filter(({ value }) =>
          this.client.commercials?.some(({ user }) => user === value)
        )
        .map((com) => {
          const { relatedSince } = this.client.commercials?.find(
            ({ user }) => user === com.value
          );

          return { ...com, relatedSince };
        });
    },

    available() {
      return this.commercials.filter(
        ({ value }) => !this.related.some((com) => com.value === value)
      );
    },
  },

  methods: {
    async addCommercial(event) {
      const dataset = {
        email: this.client.email,
        commercial: event,
      };

      try {
        await this.$api.post("/clients/commercial", dataset);
      } catch (e) {
        this.$errorToast(
          "Impossible d'ajouter le commercial, recharger la page pour vérifier qu'il n'est pas déjà attribué."
        );
      }

      const currentCommercial = this.commercials.find(
        ({ value }) => value === event
      );

      this.client.commercials.push({
        user: currentCommercial.value,
        relatedSince: new Date(),
      });

      // Update note
      this.client.notes = `${currentCommercial.label} : ${currentCommercial.mobile}`;

      this.$emit("edit-notes");
    },

    confirmRemoveCommercial(commercial) {
      this.$confirm({
        message: "Supprimer le lien commercial ?",
        onConfirm: () => {
          this.removeCommercial(commercial);
        },
      });
    },

    async removeCommercial(commercial) {
      await this.$api.delete("/clients/commercial", {
        data: {
          email: this.client.email,
          commercial,
        },
      });

      const currentCommercial = this.commercials.find(
        ({ value }) => value === commercial
      );

      this.client.commercials = this.client.commercials.filter(
        ({ user }) => user !== currentCommercial.value
      );

      // Update note
      const lastCommercial = this.related.reduce((a, b) =>
        a.relatedSince > b.relatedSince ? a : b
      );

      this.client.notes = `${lastCommercial.label} : ${lastCommercial.mobile}`;

      this.$emit("edit-notes");
    },

    async newCommercialRelationDate(relatedSince, commercial) {
      if (relatedSince < moment(this.client.createdAt).startOf("day")) {
        this.$errorToast(
          `Impossible d'attribuer le client au commercial à une date antérieure à la création du client (${moment(
            this.client.createdAt
          ).format("DD-MM-YYYY")})`
        );
      } else if (relatedSince > moment().endOf("day")) {
        this.$errorToast(
          "Impossible d'attribuer le client au commercial à une date supérieure à aujourd'hui"
        );
      } else {
        const data = {
          client: this.client.email,
          commercial: commercial.value,
          relatedSince,
        };

        await this.$api.put(`/clients/commercial`, data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commercial-relation {
  .vdp-datepicker__calendar {
    right: 0;
  }
}
</style>
