<template>
  <div>
    <el-table
      style="width: 100%"
      :data="attributionsInPage"
      :default-sort="{ prop: 'order.createdAt', order: 'descending' }"
    >
      <el-table-column label="Date" width="100" sortable prop="order.createdAt">
        <template slot-scope="scope">
          <span class="table-link" @click="clickHandler(scope.row)">{{
            formatDate(scope.row.order.createdAt)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Nom du cours" width="140">
        <template slot-scope="scope">
          {{ scope.row.order.live.name }}
        </template>
      </el-table-column>
      <el-table-column label="Type" width="80">
        <template slot-scope="scope">
          {{ scope.row.order.live.type === "small" ? "Visio" : "Live" }}
        </template>
      </el-table-column>
      <el-table-column label="Jour " width="100">
        <template slot-scope="scope">
          {{ liveDay(scope.row.order.live.date) }}
        </template>
      </el-table-column>
      <el-table-column label="Horaire " width="100">
        <template slot-scope="scope">
          {{ scope.row.order.live.hour }}:{{
            displayMinutes(scope.row.order.live.minutes)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Nb séances" width="120">
        <template slot-scope="scope">
          <a
            href="#"
            class="order-link"
            @click="(e) => goToOrder(e, scope.row.order)"
          >
            {{ formatPack(scope.row.order.session) }}
          </a>
        </template>
      </el-table-column>
      <el-table-column
        label="Commercial"
        width="130"
        sortable
        prop="order.commercials.userEmail"
      >
        <template slot-scope="scope">
          <template v-for="(rel, index) of scope.row.order.commercials">
            <CommercialRel :key="`com-${index}`" :relation="rel" />
          </template>
        </template>
      </el-table-column>
      <el-table-column width="220" label="">
        <template
          slot-scope="scope"
          v-if="hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
        >
          <button
            class="btn btn-danger btn-sm"
            @click="deleteHandler(scope.row)"
          >
            <i class="icon-close"></i>
          </button>
          <button
            class="btn btn-secondary btn-sm"
            @click="clickHandler(scope.row)"
          >
            <i class="icon-user"></i>
            {{ status(scope.row) }}

            <span v-if="accept(scope.row)" class="text-success">
              <i class="icon-user-following"></i>
              {{ accept(scope.row) }}
            </span>

            <span v-if="reject(scope.row)" class="text-danger">
              <i class="icon-user-unfollow"></i>
              {{ reject(scope.row) }}
            </span>
          </button>
        </template>
      </el-table-column>
      <el-table-column
        label="1er attrib."
        width="120"
        sortable
        prop="firstSendAt"
      >
        <template slot-scope="scope">
          {{ getDelay(scope.row.firstSendAt) }}
        </template>
      </el-table-column>

      <el-table-column width="210" label="Notes Admin">
        <template slot-scope="scope">
          <NoteModal
            :adminNotes="scope.row.order.adminNotes"
            permission="ATTRIBUTIONS_WRITE"
            @showComment="showComment(scope.row.order)"
          />
        </template>
      </el-table-column>
      <el-table-column width="80" label="Attrib">
        <template slot-scope="scope">
          <button
            class="btn btn-sm button-transparent"
            @click="allowAttribution(scope.row)"
            :disabled="!hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          >
            <i class="icon-plus"></i>
          </button>
        </template>
      </el-table-column>
      <el-table-column width="80" label="Rappel">
        <template slot-scope="scope">
          <button
            class="btn btn-sm"
            :class="{ 'btn-success': scope.row.recall }"
            @click="updateRecall(scope.row)"
            :disabled="!hasPermission($store.state.user, 'ATTRIBUTIONS_WRITE')"
          >
            <i v-if="scope.row.recall" class="icon-check"></i>
            <i v-else class="icon-phone"></i>
          </button>
        </template>
      </el-table-column>
    </el-table>

    <paginate
      :page-count="count"
      :click-handler="paginationHandler"
      v-if="count"
      prev-text="Précédent"
      next-text="Suivant"
      container-class="pagination"
    ></paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import { mapState } from "vuex";
import moment from "moment-timezone";
import NoteModal from "./form/NoteModal";
import CommercialRel from "../components/CommercialRel";

export default {
  components: {
    Paginate,
    NoteModal,
    CommercialRel,
  },
  props: {
    attributions: {
      type: Array,
      default: null,
    },
    clients: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Boolean,
      default: false,
    },
    overridePage: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      page: this.$route.query.page || this.overridePage,
    };
  },
  computed: {
    attributionsInPage() {
      return this.attributions.slice(
        this.page * this.rows - this.rows,
        this.page * this.rows
      );
    },
    count() {
      return Math.ceil(this.attributions.length / this.rows);
    },
    ...mapState({
      concepts: (state) => state.concepts,
    }),
  },
  methods: {
    status(value) {
      if (value.coachs.length) {
        return value.coachs.length;
      }

      return "Aucun";
    },

    accept(value) {
      return value.coachs.filter((c) => c.accepted).length;
    },

    reject(value) {
      return value.coachs.filter((c) => c.rejected).length;
    },

    paginationHandler(page) {
      this.page = page;
    },

    deleteHandler(value) {
      let type = "orders";

      this.$emit("delete", value.id, type);
    },

    clickHandler(value) {
      if (this.clients) {
        this.$emit("click", value.client, "client");
      } else {
        this.$emit("click", value.order, "order");
      }
    },

    getDelay(value) {
      if (!value) {
        return "inconnu";
      }

      const now = moment();
      const duration = moment.duration(now.diff(value));
      const hours = duration.asHours();
      const minutes = duration.asMinutes();

      return hours > 1
        ? `${Math.round(hours)} h`
        : `${Math.round(minutes)} min`;
    },

    updateRecall(value) {
      value.recall = !value.recall;

      this.$api.put(`/attributions/${value.id}`, {
        recall: value.recall,
      });

      this.$emit("moveAttribution", value);
    },

    liveDay(date) {
      switch (new Date(date).getDay()) {
        case 0:
          return "Dimanche";
        case 1:
          return "Lundi";
        case 2:
          return "Mardi";
        case 3:
          return "Mercredi";
        case 4:
          return "Jeudi";
        case 5:
          return "Vendredi";
        case 6:
          return "Samedi";
      }
    },

    goToOrder(e, order) {
      e.preventDefault();

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: order.id,
        },
      });
      window.open(route.href, "_blank");
    },
    allowAttribution(attribution) {
      this.$emit("allowAttribution", attribution);
    },
    showComment(attribution) {
      this.$emit("showComment", attribution);
    },
  },
};
</script>

<style lang="css" scoped>
.user-link {
  padding-left: 5px;
}
.table-link,
.order-link {
  cursor: pointer;
  text-decoration: none;
}
.text-dark {
  color: black;
}

.button-transparent {
  background-color: transparent;
}
.ml-0-5 {
  margin-left: 0.5rem;
}
</style>
