<template>
  <div class="row">
    <div class="col-12">
      <ClientOrdersCard :client="client" @newOrder="newOrder" />
    </div>
  </div>
</template>

<script>
import ClientOrdersCard from "../components/ClientOrdersCard";

export default {
  components: {
    ClientOrdersCard,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  methods: {
    newOrder() {
      this.$emit("newOrder");
    },
  },
};
</script>
