<template>
  <div class="wrapper">
    <Tabs v-model="activeTab" @input="changeTab" class="empty-tab">
      <Tab
        :key="`tab-${index}`"
        v-for="(item, index) of authorizedTabs"
        :header="item.label"
      >
      </Tab>
    </Tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import { mapState } from "vuex";

export default {
  components: {
    Tabs,
    Tab,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
        {
          label: "Prestataires",
          name: "users",
          requiredPermission: "USERS_READ",
        },
        {
          label: "Documents",
          name: "documents",
          requiredPermission: "DOCUMENTS_READ",
        },
        {
          label: "Avoirs",
          name: "disbursements",
          requiredPermission: "DISBURSEMENTS_READ",
          children: [
            "pending-disbursements",
            "processed-disbursements",
            "payed-disbursements",
          ],
        },
        {
          label: "Remplacements",
          name: "substitutions",
          requiredPermission: "SUBSTITUTIONS_READ",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),

    authorizedTabs() {
      return this.tabs.filter((tab) => {
        return this.hasPermission(
          this.$store.state.user,
          tab.requiredPermission
        );
      });
    },
  },

  created() {
    if (this.$route.name === "usersNav" && this.authorizedTabs.length > 0) {
      this.$router.push({ name: this.authorizedTabs[0].name });
    }

    this.setCurrentTab(this.$route.name);
  },

  methods: {
    changeTab(index) {
      if (index >= 0) {
        this.$router.push({ name: this.authorizedTabs[index].name });
      }
    },

    setCurrentTab(currentPath) {
      this.activeTab = this.authorizedTabs.findIndex(
        (tab) => tab.name === currentPath || tab.children?.includes(currentPath)
      );
    },
  },

  watch: {
    $route(current) {
      if (current.name === "usersNav") {
        this.$router.push({ name: this.authorizedTabs[0].name });
      }

      this.setCurrentTab(current.name);
    },
  },
};
</script>

<style lang="scss">
.empty-tab {
  .nav.nav-tabs {
    margin-bottom: 15px;
  }

  & > .tab-content {
    display: none;
  }
}

.display-tab {
  .tab-content {
    display: block !important;
  }
}
</style>
