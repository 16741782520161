<template>
  <div v-if="coach" class="row" style="position: relative">
    <div id="userPicture" v-if="coach.coach && coach.coach.picture">
      <img :src="getPicture" @click="showPicture = true" />
    </div>

    <div class="col-sm-12" v-if="coach.coach.status === 'suspended'">
      <div class="card card-inverse card-danger text-center">
        <div class="card-block">
          <blockquote class="card-blockquote">
            Le coach est bloqué, il ne peut plus accéder à son compte.
          </blockquote>
        </div>
      </div>
    </div>

    <div class="col-sm-7">
      <div class="card">
        <div class="card-header">
          <i class="icon-user"></i>
          Coach : {{ coach.email }}
          <el-select
            :value="currentStatus && currentStatus.value"
            size="mini"
            @change="handleStatus($event)"
            :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
            class="current-status text-white float-right"
            :style="`background-color: ${
              currentStatus && currentStatus.color
            }; border-radius: 5px;`"
          >
            <el-option
              v-for="status in coachStatus"
              :key="status.value"
              :value="status.value"
              :label="status.label"
              class="text-white"
              :style="`background-color: ${status.color}`"
            />
          </el-select>
          <button
            type="button"
            class="btn btn-sm btn-danger float-right"
            v-if="
              !coach.clients && hasPermission($store.state.user, 'USERS_WRITE')
            "
            @click="removeUser()"
          >
            Supprimer
          </button>
          <div class="saving-indicator float-right">
            {{ formSaving ? "Enregistrement en cours..." : "Enregistré" }}
          </div>
        </div>
        <div class="card-block">
          <dl class="row">
            <dt class="col-sm-4" v-if="hasRole($store.state.user, 'admin')">
              Connexion
            </dt>
            <dd class="col-sm-8" v-if="hasRole($store.state.user, 'admin')">
              <button
                type="button"
                class="btn btn-danger float-right"
                @click="loginToCoach(coach)"
                :disabled="!hasRole($store.state.user, 'admin')"
              >
                <i class="icon-lock-open"></i>&nbsp; Se connecter
              </button>
            </dd>
            <dt class="col-sm-4">Type de prestataire</dt>
            <dd class="col-sm-8">
              <div class="row">
                <div class="col-sm-4 text-center">
                  <label>Intervenant</label>
                  <el-switch
                    style="padding-left: 5px"
                    :value="coach.coach.isCoach"
                    @change="editIsCoach()"
                    :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
                  />
                </div>
                <div class="col-sm-4 text-center">
                  <label>Formateur</label>
                  <el-switch
                    style="padding-left: 5px"
                    :value="coach.coach.isFormer"
                    @change="editIsFormer()"
                    :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
                  />
                </div>
                <div class="col-sm-4 text-center">
                  <label>Influenceur</label>
                  <el-switch
                    style="padding-left: 5px"
                    :value="coach.coach.isInfluencer"
                    @change="editIsInfluencer()"
                    :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
                  />
                </div>
              </div>
            </dd>
            <dt class="col-sm-4">Email de contact</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.contactEmail"
                size="mini"
                @change="editCoach('contactEmail', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              />
              <span v-else>{{ coach.coach.contactEmail }}</span>
            </dd>
            <dt class="col-sm-4">Prénom</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.firstName"
                size="mini"
                @change="editCoach('firstName', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              />
              <span v-else>{{ coach.coach.firstName }}</span>
            </dd>
            <dt class="col-sm-4">Nom de famille</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.lastName"
                size="mini"
                @change="editCoach('lastName', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              />
              <span v-else>{{ coach.coach.lastName }}</span>
            </dd>
            <dt class="col-sm-4">Genre</dt>
            <dd class="col-sm-8">
              <el-select
                v-model="coach.coach.gender"
                size="mini"
                @change="editCoach('gender', $event)"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              >
                <el-option value="Homme" />
                <el-option value="Femme" />
              </el-select>
            </dd>
            <dt class="col-sm-4">Téléphone</dt>
            <dd class="col-sm-8">
              <PhoneNumberInput
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
                :phone-number="coach.coach.phone"
                :phone-code="coach.coach.phoneCode"
                size="mini"
                copy
                required
                @changeNumber="editCoach('phone', $event)"
                @changeCode="editCoach('phoneCode', $event)"
              />
              <span v-else
                ><span v-if="coach.coach.phoneCode"
                  >({{ coach.coach.phoneCode }}) </span
                >{{ coach.coach.phone }}</span
              >
            </dd>
            <dt class="col-sm-4">Date de naissance</dt>
            <dd class="col-sm-8">
              <el-date-picker
                v-model="coach.coach.dob"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                type="date"
                size="small"
                @change="editCoach('dob', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              />
              <span v-else>{{ formatDate(coach.coach.dob) }}</span>
            </dd>
            <dt class="col-sm-4">Découvert par</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.discoveredBy"
                size="mini"
                type="text"
                @change="editCoach('discoveredBy', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Rayon d'action</dt>
            <dd class="col-sm-8">
              <el-select
                v-model="coach.coach.range"
                size="mini"
                @change="editCoach('range', $event)"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              >
                <el-option :value="1" label="1 Km" />
                <el-option :value="2" label="2 Km" />
                <el-option :value="5" label="5 Km" />
                <el-option :value="10" label="10 Km" />
                <el-option :value="15" label="15 Km" />
                <el-option :value="20" label="20 Km" />
                <el-option :value="25" label="25 Km" />
                <el-option :value="30" label="30 Km" />
                <el-option :value="40" label="40 Km" />
                <el-option :value="50" label="50 Km" />
                <el-option :value="75" label="75 Km" />
                <el-option :value="100" label="100 Km" />
              </el-select>
            </dd>
            <dt class="col-sm-4">Nom de la société</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.companyName"
                size="mini"
                type="text"
                @change="editCoach('companyName', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Siret</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.siret"
                size="mini"
                type="text"
                @change="editCoach('siret', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Société soumise à la TVA</dt>
            <dd class="col-sm-8">
              <el-switch
                v-model="coach.coach.isTva"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="editCoach('isTva', $event)"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
            <dt class="col-sm-4">Adresse de la société</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.companyAddress"
                size="mini"
                type="text"
                @change="editCoach('companyAddress', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Ville de la société</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.companyCity"
                size="mini"
                type="text"
                @change="editCoach('companyCity', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Code postal de la société</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.companyZipcode"
                size="mini"
                type="text"
                @change="editCoach('companyZipcode', $event)"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
              ></el-input>
            </dd>
            <dt class="col-sm-4">Code PIN</dt>
            <dd class="col-sm-8">
              <el-input
                v-model="coach.coach.pincode"
                size="mini"
                @change="editCoach('pincode', $event)"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
            <dt class="col-sm-4" v-if="coach.coach.deviceTokenAt">
              Application
            </dt>
            <dd class="col-sm-8" v-if="coach.coach.deviceTokenAt">
              <button class="btn btn-sm btn-primary" @click="sendPush = true">
                <i class="icon-screen-smartphone"></i>App notification
              </button>
              <span class="btn btn-sm">
                Enregistrement le
                {{ formatDate(coach.coach.deviceTokenAt) }}
              </span>
            </dd>
            <dt class="col-sm-4">
              Offre SMS/Email
              <i
                class="fa fa-info-circle ml-05"
                v-tooltip="{
                  content:
                    'Important en cas d\'export pour la campagne de newsletter.',
                  placement: 'right',
                }"
              ></i>
            </dt>
            <dd class="col-sm-8">
              <el-switch
                v-model="coach.coach.newsletter"
                active-text="Oui"
                inactive-text="Non"
                size="mini"
                @change="editCoach('newsletter', $event)"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
            <dt class="col-sm-4">Coach live</dt>
            <dd class="col-sm-8">
              <el-switch
                :value="coach.coach.live"
                @change="editLive()"
                active-text="Oui"
                inactive-text="Non"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
            <dt v-if="coach.coach.live" class="col-sm-4">Photo live</dt>
            <dd v-if="coach.coach.live" class="col-sm-8">
              <div class="row">
                <div v-if="coach.coach.livePicture" class="col-sm-6">
                  <img :src="getLivePicture" class="rounded img-responsive" />
                </div>
                <div class="col-sm-6">
                  <dropzone
                    :url="apiUploads()"
                    :headers="apiAuthorization()"
                    id="photo"
                    v-on:vdropzone-success="photoUploaded"
                  >
                    <div class="dz-default dz-message">
                      <span
                        ><i class="icon-cloud-upload"></i><br />Glissez l'image
                        ici</span
                      >
                    </div>
                  </dropzone>
                </div>
              </div>
            </dd>
            <dt class="col-sm-4">Compte créé</dt>
            <dd class="col-sm-8">{{ formatDate(coach.createdAt) }}</dd>
            <dt class="col-sm-4">
              <span
                v-tooltip="
                  'La régularité est la moyenne de toutes les séances effectuées par le coach, exprimé en nombre de jours.'
                "
              >
                Régularité du coach
              </span>
            </dt>
            <dd
              class="col-sm-8"
              v-if="
                (coachClients && coachClients.length > 0) || displayCoachClients
              "
            >
              {{ getCoachFrequence }}
            </dd>
            <dd class="col-sm-8" v-else>
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </dd>
            <dt class="col-sm-4">
              <span
                v-tooltip="
                  'Cette fréquence est la moyenne des séances effectuées par le coach pour chaqu\'un de ses clients.'
                "
              >
                Fréquence des clients
              </span>
            </dt>
            <dd
              class="col-sm-8"
              v-if="
                (coachClients && coachClients.length > 0) || displayCoachClients
              "
            >
              {{ getCoachClientsFrequence }}
            </dd>
            <dd class="col-sm-8" v-else>
              <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
            </dd>
            <dt class="col-sm-4">Blocage attribution</dt>
            <dd class="col-sm-8">
              <el-switch
                :value="coach.coach.blockedAttribution"
                @change="editBlockedAttribution()"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
            <dt class="col-sm-4">Blocage avoirs</dt>
            <dd class="col-sm-8">
              <el-switch
                :value="coach.coach.blockedRefund"
                @change="editBlockedRefund()"
                :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
              />
            </dd>
          </dl>
          <div class="mb-1">
            <button
              type="button"
              class="btn btn-secondary"
              v-if="dateLastCompletedSession"
            >
              Séance validée {{ dateLastCompletedSession.format("DD.MM.YYYY") }}
            </button>
            <button
              type="button"
              class="btn btn-info"
              v-if="dateNextScheduledSession"
            >
              Programmé le {{ dateNextScheduledSession.format("DD.MM.YYYY") }}
            </button>
            <button
              type="button"
              class="btn btn-danger"
              v-if="dateLastCompletedSession && !dateNextScheduledSession"
            >
              Inactif depuis {{ daysInactive }} jours
            </button>
          </div>
          <div
            class="mb-1"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            <form style="margin-top: 5px">
              <input type="hidden" name="coach" v-model="coach.email" />
              <button
                type="button"
                class="btn btn-secondary"
                @click="goToClients(coach)"
              >
                <i class="icon-people"></i>
                &nbsp; Voir la liste des clients
                <i
                  v-if="!displayCoachClients"
                  class="fa fa-spinner fa-spin"
                  aria-hidden="true"
                ></i>
                <span v-else>({{ coachClients.length }})</span>
              </button>
              <button
                v-if="coachClients && displayCoachClients"
                type="button"
                class="btn btn-secondary"
                @click="showClients = true"
              >
                {{ coachClientsStatus }}
              </button>
            </form>
          </div>
        </div>
      </div>

      <InfluencerPromoDetails
        v-if="promoCodes.length > 0"
        :promoCodes="promoCodes"
      />

      <UsersStats :coach="coach" />

      <div class="card">
        <div class="card-header"><i class="icon-note"></i> Notes admin</div>
        <div class="card-block">
          <div v-if="coach.coach.notes" v-html="nl2br(coach.coach.notes)" />
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="editNotes = true"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            Modifier
          </button>
        </div>
      </div>

      <UserMarketing
        :createdAt="coach.createdAt"
        :user="coach.coach"
        @updateMarketing="(marketing) => editCoach('marketing', marketing)"
      />
    </div>

    <div class="col-sm-5">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <span class="font-weight-bold"> Commercial Référent</span>
            <el-select
              value=""
              size="mini"
              @change="changeRefCommercial($event)"
              :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
            >
              <el-option
                :value="commercial.value"
                v-for="commercial in commercialsOptions"
                :key="commercial.value"
                >{{ commercial.pseudo }}</el-option
              >
            </el-select>
          </div>
          <div v-if="refCommercial" class="d-flex" style="padding: 0.5rem 0">
            <span
              class="text-white rounded-3 mr-1 d-flex align-items-center"
              style="padding: 7px"
              :style="
                refCommercial.color && `background-color:${refCommercial.color}`
              "
              :class="!refCommercial.color && 'bg-primary'"
            >
              <span>{{ refCommercial.pseudo }}</span>
              <span
                class="ml-1"
                style="cursor: pointer"
                @click="removeRefCommercial()"
                v-if="hasPermission($store.state.user, 'USERS_WRITE')"
                ><i class="icon-close"></i
              ></span>
            </span>
          </div>
        </div>
      </div>

      <geoloc
        :geoloc="geoloc"
        :address="formatAddress(coach.coach)"
        :displayAddress="true"
        :range="coach.coach.range"
        placeholder="Adresse du coach"
        @placeChanged="handleAddress"
        :disabled="!hasPermission($store.state.user, 'USERS_WRITE')"
      />

      <div class="card">
        <div class="card-header com-header">
          <div><i class="fa fa-comment"></i> Commentaires</div>
          <div class="tabs" v-if="coach.comments">
            <span
              @click="updateFilteredComments(0)"
              :class="{ active: activeComments[1] }"
            >
              Tous ({{ coach.comments.length }})
            </span>
            <span
              @click="updateFilteredComments(1)"
              :class="{ active: activeComments[2] }"
            >
              Positifs ({{
                coach.comments.filter((com) => {
                  return com.type === 1;
                }).length
              }})
            </span>
            <span
              @click="updateFilteredComments(-1)"
              :class="{ active: activeComments[0] }"
            >
              Négatifs ({{
                coach.comments.filter((com) => {
                  return com.type === -1;
                }).length
              }})
            </span>
          </div>
          <button
            class="btn btn-warning btn-sm float-right"
            @click="showNewComment()"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            <i class="fa fa-edit"></i> Rédiger
          </button>
        </div>

        <div class="card-block">
          <table class="table table-hover">
            <tr
              v-for="(c, index) in filteredComments"
              :key="c.fileId"
              class="comments"
            >
              <td class="com-thumb">
                <p v-if="c.type == -1">
                  <i class="fa fa-thumbs-down" />
                </p>
                <p v-if="c.type == 1">
                  <i class="fa fa-thumbs-up" />
                </p>
              </td>
              <td>
                <p>
                  {{ c.author }}
                </p>
              </td>
              <td>
                <p>
                  {{ c.label }}
                </p>
              </td>
              <td class="com-content">
                <p v-tooltip="{ content: c.text, placement: 'left' }">
                  {{ c.text }}
                </p>
              </td>
              <td class="com-remove text-right">
                <button
                  class="btn btn-danger btn-sm"
                  @click="removeComment(c.id, index)"
                  v-if="hasPermission($store.state.user, 'USERS_WRITE')"
                >
                  <i class="fa fa-trash" />
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="card" v-if="langs">
        <div class="card-header"><i class="icon-globe"></i> Langues</div>
        <div class="card-block">
          <div v-if="coach.coach.langs">{{ coach.coach.langs.join(", ") }}</div>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="editLangs = true"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            Modifier
          </button>
        </div>
      </div>

      <div class="card" v-if="settings && settings['intervenants_prestations']">
        <div class="card-header"><i class="icon-puzzle"></i> Prestations</div>
        <div class="card-block">
          <div v-if="coach.coach.prestations">
            {{ coach.coach.prestations.join(", ") }}
          </div>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="editPrestations = true"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            Modifier
          </button>
        </div>
      </div>

      <div class="card" v-if="settings && settings['intervenants_tags']">
        <div class="card-header"><i class="icon-puzzle"></i> Tags</div>
        <div class="card-block">
          <div v-if="coach.coach.tags">{{ coach.coach.tags.join(", ") }}</div>
        </div>
        <div class="card-footer">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="editTags = true"
            v-if="hasPermission($store.state.user, 'USERS_WRITE')"
          >
            Modifier
          </button>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="card card-accent-danger" v-if="sessionsWatch">
        <div class="card-header">
          <i class="icon-clock"></i> Séances en cours
        </div>
        <div class="card-block">
          <sessions
            :sessions="sessionsWatch"
            :coach="coach"
            :client="true"
            :disable-edition="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-7">
          <div class="card">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <div><i class="fa fa-align-justify"></i> Liste des avoirs</div>
              <div>
                <i class="icon-tag"></i>
                <span>Cumule non facturé en cours</span>
                <br />
                <div v-for="(item, index) in coach.coach.credit" :key="index">
                  <span>
                    {{ merchantName(item.merchantId) }} :
                    <strong>{{ formatPrice(item.credit, true) }}</strong>
                  </span>
                  <br />
                </div>
              </div>
            </div>
            <div class="card-block" v-if="coach.disbursements">
              <disbursements :disbursements-data="coach.disbursements" />
            </div>
          </div>
        </div>

        <div class="col-sm-5">
          <UserDocuments :coach="coach" />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-5">
          <div class="card">
            <div class="card-header">
              <i class="fa fa-info-circle mr-05"></i> Historique
            </div>
            <div class="card-block">
              <log :user="coach" v-if="coach.role" />
              <log :coach="coach" v-else />
            </div>
          </div>
        </div>
        <div class="col-sm-7">
          <RingoverCard
            v-if="coach && coach.coach.phone"
            :phone="coach.coach.phoneInternational || coach.coach.phone"
          />
        </div>
      </div>
    </div>

    <modal
      title="Rédiger un commentaire"
      large
      v-model="newComment"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleNewComment()"
      effect="fade/zoom"
      class="form-comment"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Rédiger un commentaire</h4>
      </div>
      <div class="form-group">
        <label>Positif/Négatif*</label>
        <div class="type">
          <input
            type="radio"
            id="pos"
            v-model="formComment.type"
            v-bind:value="1"
          />
          <label for="pos"><i class="fa fa-thumbs-up" /></label>
          <input
            type="radio"
            id="neg"
            v-model="formComment.type"
            v-bind:value="-1"
          />
          <label for="neg"><i class="fa fa-thumbs-down" /></label>
        </div>
        <div v-if="typeError" class="alert alert-danger">
          Veuillez préciser la nature de votre commentaire.
        </div>
      </div>
      <div class="form-group">
        <label>Qui ?*</label>
        <v-select
          v-model="formComment.author"
          class="form-control"
          :options="this.users"
        ></v-select>
        <div v-if="authorError" class="alert alert-danger">
          Veuillez préciser qui poste ce commentaire.
        </div>
      </div>
      <div class="form-group">
        <label>Catégorie*</label>
        <input
          v-model="formComment.label"
          type="text"
          class="form-control"
          placeholder="exemple : Fidélisation"
        />
        <div v-if="labelError" class="alert alert-danger">
          Veuillez préciser la catégorie de votre commentaire.
        </div>
      </div>
      <div class="form-group">
        <label>Remarque*</label>
        <input
          v-model="formComment.text"
          type="text"
          class="form-control"
          placeholder="exemple : Denis est un très bon coach"
        />
        <div v-if="textError" class="alert alert-danger">
          Veuillez préciser les détails de votre commentaire.
        </div>
      </div>
    </modal>

    <modal
      title="Modifier les notes"
      v-model="editNotes"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handlEditNotes()"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="form-group">
        <textarea
          v-model="coach.coach.notes"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
    </modal>
    <modal
      title="Modifier les prestations"
      large
      v-model="editPrestations"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditPrestations()"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="row" v-if="settings">
        <div
          class="col-sm-3"
          v-for="(prestation, index) in settings['intervenants_prestations']"
          :key="index"
        >
          <div class="form-group">
            <label class="switch switch-default switch-primary-outline-alt">
              <input
                type="checkbox"
                v-model="coachPrestations[prestation]"
                class="switch-input"
              />
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
            {{ prestation }}
          </div>
        </div>
      </div>
    </modal>
    <modal
      title="Modifier les tags"
      large
      v-model="editTags"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditTags()"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="row" v-if="settings && settings['intervenants_tags']">
        <div
          class="col-sm-3"
          v-for="(tag, index) in settings['intervenants_tags']"
          :key="index"
        >
          <div class="form-group">
            <label class="switch switch-default switch-primary-outline-alt">
              <input
                type="checkbox"
                v-model="coachTags[tag]"
                class="switch-input"
              />
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
            {{ tag }}
          </div>
        </div>
      </div>
    </modal>
    <modal
      title="Modifier les langues"
      large
      v-model="editLangs"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditLangs()"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="row">
        <div class="col-sm-4" v-for="(lang, code) in langs" :key="code">
          <div class="form-group">
            <label class="switch switch-default switch-primary-outline-alt">
              <input
                type="checkbox"
                v-model="coachLangs[lang.name]"
                class="switch-input"
              />
              <span class="switch-label"></span>
              <span class="switch-handle"></span>
            </label>
            {{ lang.name }}
          </div>
        </div>
      </div>
    </modal>
    <modal
      title="Liste des clients"
      large
      v-model="showClients"
      cancelText="Fermer"
      okText="Ok"
      @ok="showClients = false"
      effect="fade/zoom"
    >
      <div slot="modal-header"></div>
      <div class="row">
        <div class="col-md-12">
          <el-table :data="coachClients">
            <el-table-column prop="email" label="Email">
              <template slot-scope="scope">
                <span
                  class="table-link"
                  @click="goToClient(null, scope.row.id)"
                >
                  {{ scope.row.firstName }}
                  {{ scope.row.lastName }}
                </span>
                <a
                  @click="(e) => goToClient(e, scope.row.id, true)"
                  class="user-link"
                  href="#"
                >
                  <i class="icon-user"></i>
                </a>
                <p class="text-muted">{{ scope.row.email }}</p>
              </template>
            </el-table-column>
            <el-table-column label="status">
              <template slot-scope="scope">
                <span
                  class="badge badge-danger"
                  v-if="scope.row.status === 'inactive'"
                >
                  Inactif
                </span>
                <span class="badge badge-info" v-else-if="active(scope.row)">
                  Actif ({{ active(scope.row) }})
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </modal>

    <modal
      title="Photo"
      v-model="showPicture"
      large
      effect="fade/zoom"
      v-if="coach && coach.coach.picture"
    >
      <div class="text-center">
        <img :src="getPicture" class="img-responsive" />
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          @click="showPicture = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <modal
      title="Notification push"
      v-model="sendPush"
      cancelText="Annuler"
      okText="Envoyer"
      @ok="handleSendPush()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <div slot="modal-title" class="modal-title">
          Envoyer une notification
        </div>
      </div>
      <div class="form-group" v-if="coach.coach.deviceToken">
        <textarea
          v-model="pushMessage"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

import Sessions from "../components/Sessions";
import Geoloc from "../components/Geoloc";
import Log from "../components/Log";
import InfluencerPromoDetails from "../components/InfluencerPromoDetails";
import UserMarketing from "../components/UserMarketing";
import RingoverCard from "../components/RingoverCard";
import UsersStats from "../components/UsersStats";
import Disbursements from "../components/Disbursements";
import PhoneNumberInput from "../components/PhoneNumberInput";
import UserDocuments from "../components/UserDocuments";

import Langs from "../assets/json/langs.json";
import moment from "moment-timezone";
import axios from "axios";
import FrequencyMixin from "../mixin/FrequencyMixin";

import Dropzone from "vue2-dropzone";

import { mapGetters } from "vuex";

export default {
  components: {
    Modal,
    Sessions,
    Geoloc,
    Log,
    Disbursements,
    Dropzone,
    InfluencerPromoDetails,
    UserMarketing,
    RingoverCard,
    UsersStats,
    PhoneNumberInput,
    UserDocuments,
  },
  mixins: [FrequencyMixin],
  data() {
    return {
      clients: null,
      settings: null,
      sessions: null,
      coach: null,
      coachPrestations: {},
      coachTags: {},
      coachLangs: {},
      coachClients: [],
      commercials: [],
      refCommercial: null,
      geoloc: null,
      editNotes: false,
      editPrestations: false,
      editTags: false,
      editLangs: false,
      editGender: false,
      editRange: false,
      showClients: false,
      formSaving: false,
      showPicture: false,
      sendPush: false,
      pushMessage: null,
      recapView: "company_orders",
      promoCodes: [],
      newComment: false,
      formComment: {},
      typeError: null,
      authorError: null,
      labelError: null,
      textError: null,
      users: [],
      filteredComments: [],
      activeComments: [false, true, false],
      displayCoachClients: false,
    };
  },
  async created() {
    await this.loadCoach();
    await this.loadCoachClient();
    await this.fetchCommercials();
  },
  computed: {
    ...mapGetters({
      coachStatus: "getCoachStatus",
    }),

    currentStatus() {
      if (this.coach?.coach) {
        const status = this.coachStatus.find(
          (status) => this.coach.coach.status === status.value
        );

        return status;
      }

      return null;
    },

    rateAverage() {
      let average = 0;

      if (this.coach.ratings?.length > 0) {
        let total = 0;

        for (const rating of this.coach.ratings) {
          total += rating.average;
        }

        average = total / this.coach.ratings.length;
      }

      return +average.toFixed(2);
    },

    getPicture() {
      return `${axios.defaults.baseURL}users/${this.coach.id}/picture`;
    },

    getLivePicture() {
      return `${axios.defaults.baseURL}users/${this.coach.id}/live-picture`;
    },

    langs() {
      return Langs;
    },

    sessionsScheduled() {
      let events = [];
      let sessions = this.sessions.filter((s) => !!s.scheduledAt);

      sessions.sort(function (a, b) {
        a = new Date(a.scheduledAt);
        b = new Date(b.scheduledAt);
        return a > b ? -1 : a < b ? 1 : 0;
      });

      sessions.forEach((session) => {
        if (!session.client) {
          return;
        }
        events.push({
          date: moment(session.scheduledAt).format("YYYY/MM/DD HH:mm:00"),
          time: moment(session.scheduledAt).format("DD MMM. à HH:mm"),
          completed: session.completed,
          clientId: session.client.id,
          title: session.client.fullName,
          counter: session.counter,
          order: session.order,
        });
      });

      return events;
    },

    sessionsWatch() {
      if (this.sessions) {
        let sessions = this.sessions.filter(
          (s) =>
            (s.scheduledAt && !s.completedAt) || (s.completedAt && !s.disbursed)
        );
        sessions.sort(function (a, b) {
          a = new Date(a.scheduledAt);
          b = new Date(b.scheduledAt);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        return sessions;
      }

      return undefined;
    },

    dateNextScheduledSession() {
      let dateCurrent = moment();
      let date = null;

      if (!this.sessions) return null;
      let sessions = this.sessions.filter((s) => !!s.scheduledAt);

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);
        if (
          scheduledAt >= dateCurrent &&
          (date == null || scheduledAt < date)
        ) {
          date = scheduledAt;
        }
      });
      return date;
    },

    dateLastCompletedSession() {
      let dateCurrent = moment();
      let date = null;

      if (!this.sessions) return null;
      let sessions = this.sessions.filter(
        (s) => !!s.scheduledAt && s.completed
      );

      sessions.forEach((s) => {
        let scheduledAt = moment(s.scheduledAt);
        if (
          scheduledAt <= dateCurrent &&
          (date == null || scheduledAt > date)
        ) {
          date = scheduledAt;
        }
      });
      return date;
    },

    daysInactive() {
      if (!this.dateLastCompletedSession) return null;
      return moment().diff(this.dateLastCompletedSession, "days");
    },

    coachClientsStatus() {
      let inactive = 0;
      let active = 0;

      for (const client of this.coachClients) {
        if (client.status === "inactive") {
          inactive++;
          continue;
        }

        if (this.active(client)) {
          active++;
          continue;
        }
      }

      return `${active} actif(s) / ${inactive} inactif(s)`;
    },

    getCoachFrequence() {
      if (this.coachClients.length > 0) {
        let sessions = [];

        for (const client of this.coachClients) {
          client.sessions.map((s) => {
            if (!!s.scheduledCoachId && s.scheduledCoachId === this.coach.id) {
              sessions.push(s);
            }
          });
        }

        const frequency = this.frequency(sessions);

        return frequency ? frequency.toFixed(2) : "Aucune";
      }

      return "Aucune";
    },

    getCoachClientsFrequence() {
      const frequency = this.prestataireClientFrequency(
        this.coachClients,
        this.coach.id
      );

      return frequency ? frequency : "Aucune";
    },

    commercialsOptions() {
      if (this.refCommercial) {
        return this.commercials.filter(
          (commercial) => commercial.value !== this.refCommercial.value
        );
      } else {
        return this.commercials;
      }
    },
  },
  methods: {
    editLive() {
      this.coach.coach.live = !this.coach.coach.live;
      this.editCoach("live", this.coach.coach.live);
    },

    editIsCoach() {
      this.coach.coach.isCoach = !this.coach.coach.isCoach;
      this.editCoach("isCoach", this.coach.coach.isCoach);
    },

    editIsFormer() {
      this.coach.coach.isFormer = !this.coach.coach.isFormer;
      this.editCoach("isFormer", this.coach.coach.isFormer);
    },

    editIsInfluencer() {
      this.coach.coach.isInfluencer = !this.coach.coach.isInfluencer;
      this.editCoach("isInfluencer", this.coach.coach.isInfluencer);
    },

    editBlockedAttribution() {
      this.coach.coach.blockedAttribution =
        !this.coach.coach.blockedAttribution;
      this.editCoach("blockedAttribution", this.coach.coach.blockedAttribution);
    },

    editBlockedRefund() {
      this.coach.coach.blockedRefund = !this.coach.coach.blockedRefund;
      this.editCoach("blockedRefund", this.coach.coach.blockedRefund);
    },

    // manage coach live Picture's upload
    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    photoUploaded(file, response) {
      this.editCoach("livePicture", response.id);
    },

    async loadCoach() {
      await this.getCoach();
      await this.getPromoCodes();
      await this.loadSettings();
      await this.loadSessions();
    },

    async loadCoachClient() {
      const { data } = await this.$api.get(
        `/clients?coachId=${this.$route.params.id}`
      );

      this.coachClients = data;
      this.displayCoachClients = true;
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        pseudo: c.commercial.pseudo,
        color: c.commercial.color,
      }));
    },

    active(client) {
      let sessions = 0;
      let completed = 0;

      if (client.orders) {
        client.orders.forEach((order) => {
          if (order.completed) {
            return;
          }
          sessions += order.session;
          completed += order.sessions.filter((s) => s.completed).length;
        });
        if (sessions !== completed) {
          return completed + "/" + sessions;
        }
      }
    },

    goToClient(e, id, newTab) {
      if (e) e.preventDefault();

      if (newTab) {
        const route = this.$router.resolve({
          name: "client-details",
          params: { id },
        });
        window.open(route.href, "_blank");

        return;
      }

      this.$router.push({
        name: "client-details",
        params: { id },
      });
    },

    async getPromoCodes() {
      const { data } = await this.$api.get(
        "/promo-codes/influencers/" + this.coach.id
      );
      data.map((promoCode) => {
        if (promoCode.discountUsages) {
          promoCode.totalCA = promoCode.discountUsages.reduce(
            (sum, usage) => sum + usage.amount,
            0
          );
        }
      });

      this.promoCodes = data;
    },
    async getCoach() {
      const { data } = await this.$api
        .get("/users/coachs/" + this.$route.params.id)
        .catch(() => {
          this.$router.push({
            name: "users",
          });
        });

      this.coach = data;
      this.filteredComments = this.coach.comments;
      this.coach.coach.contactEmail =
        this.coach.coach.contactEmail || this.coach.email;

      if (this.coach.coach.geoloc) {
        this.geoloc = {
          lat: this.coach.coach.geoloc.coordinates[1],
          lng: this.coach.coach.geoloc.coordinates[0],
        };
      }

      if (this.coach.coach.prestations) {
        for (let i in this.coach.coach.prestations) {
          this.coachPrestations[this.coach.coach.prestations[i]] = true;
        }
      }

      if (this.coach.coach.tags) {
        for (let i in this.coach.coach.tags) {
          this.coachTags[this.coach.coach.tags[i]] = true;
        }
      }

      if (this.coach.coach.langs) {
        for (let i in this.coach.coach.langs) {
          this.coachLangs[this.coach.coach.langs[i]] = true;
        }
      }

      if (this.coach.refCommercial) {
        this.refCommercial = {
          color: this.coach.refCommercial.commercial.color,
          pseudo: this.coach.refCommercial.commercial.pseudo,
          value: this.coach.refCommercial.email,
        };
      }
    },
    editCoach(name, value) {
      this.formSaving = true;

      var data = {
        email: this.coach.email,
        coach: {},
      };
      data.coach[name] = value;
      this.$api.put("/users", data).then(() => {
        this.formSaving = false;
      });
    },
    updateFilteredComments(type) {
      if (type === 0) {
        this.filteredComments = this.coach.comments;
      } else {
        this.filteredComments = this.coach.comments.filter((com) => {
          return com.type === type;
        });
      }

      this.activeComments.fill(false);
      this.activeComments[type + 1] = true;
    },
    showNewComment() {
      this.fetchUsers();
      this.newComment = true;
    },
    fetchUsers() {
      this.$api.get("/users/team").then((res) => {
        res.data.forEach((user) => {
          this.users.push(user.firstName);
        });
      });
    },
    handleNewComment() {
      if (!this.formComment.type) {
        this.typeError = true;
      } else {
        this.typeError = false;
      }

      if (
        !this.formComment.author ||
        this.formComment.author.toString().trim().length < 1
      ) {
        this.authorError = true;
      } else {
        this.authorError = false;
      }

      if (
        !this.formComment.label ||
        this.formComment.label.toString().trim().length < 1
      ) {
        this.labelError = true;
      } else {
        this.labelError = false;
      }

      if (
        !this.formComment.text ||
        this.formComment.text.toString().trim().length < 1
      ) {
        this.textError = true;
      } else {
        this.textError = false;
      }

      if (
        this.typeError ||
        this.authorError ||
        this.labelError ||
        this.textError
      ) {
        return false;
      }

      this.$api
        .post("/users/coach/comment", {
          coachId: this.$route.params.id,
          comment: {
            type: this.formComment.type,
            author: this.formComment.author,
            label: this.formComment.label,
            text: this.formComment.text,
          },
        })
        .then(() => {
          this.newComment = false;
          this.getCoach();
        })
        .catch((err) => {
          console.warn(err);
        });
    },

    handleStatus(event) {
      this.$confirm({
        message: "Attention, cela va changer le statut d'intervenant.",
        onConfirm: async () => {
          try {
            const { data } = await this.$api.put("/users", {
              email: this.coach.email,
              coach: {
                status: event,
              },
            });

            this.coach = data;
          } catch (e) {
            this.$errorToast(e);
          }
        },
      });
    },

    toggleRole(event) {
      this.$confirm({
        message: "Attention, cela va changer le statut Administrateur !",
        onConfirm: async () => {
          let newRole = this.coach.role;

          if (event) {
            newRole = newRole | this.getRole("admin");
          } else {
            newRole = newRole & ~this.getRole("admin");
            newRole = newRole | this.getRole("member");
          }

          try {
            const { data } = await this.$api.put("/users", {
              email: this.coach.email,
              role: newRole,
            });

            this.coach = data;
          } catch (e) {
            this.$errorToast(e);
          }
        },
      });
    },
    handleEditPrestations: function () {
      var data = {
        email: this.coach.email,
        coach: {
          prestations: [],
        },
      };

      for (var prestation in this.coachPrestations) {
        if (this.coachPrestations[prestation]) {
          data.coach.prestations.push(prestation);
        }
      }

      this.$api.put("/users", data).then(() => {
        this.editPrestations = false;
        this.coach.coach.prestations = data.coach.prestations;
      });
    },
    handleEditTags: function () {
      var data = {
        email: this.coach.email,
        coach: {
          tags: [],
        },
      };

      for (var tag in this.coachTags) {
        if (this.coachTags[tag]) {
          data.coach.tags.push(tag);
        }
      }

      this.$api.put("/users", data).then(() => {
        this.editTags = false;
        this.coach.coach.tags = data.coach.tags;
      });
    },
    handleEditLangs: function () {
      var data = {
        email: this.coach.email,
        coach: {
          langs: [],
        },
      };

      for (var lang in this.coachLangs) {
        if (this.coachLangs[lang]) {
          data.coach.langs.push(lang);
        }
      }

      this.$api.put("/users", data).then(() => {
        this.editLangs = false;
        this.coach.coach.langs = data.coach.langs;
      });
    },
    handleAddress(place) {
      var data = {
        email: this.coach.email,
        coach: place,
      };

      this.$api.put("/users", data).then((res) => {
        this.coach = res.data;

        this.geoloc = {
          lat: this.coach.coach.geoloc.coordinates[1],
          lng: this.coach.coach.geoloc.coordinates[0],
        };
      });
    },
    handlEditNotes() {
      this.$api
        .put("/users", {
          email: this.coach.email,
          coach: {
            notes: this.coach.coach.notes,
          },
        })
        .then(() => {
          this.editNotes = false;
        });
    },
    handleSendPush() {
      if (!this.pushMessage) {
        return;
      }

      this.$confirm({
        message: `Envoyer une notification push à ${this.coach.coach?.name} ?`,
        onConfirm: async () => {
          this.sendPush = false;

          try {
            await this.$api.put(`/users/coachs/notification/${this.coach.id}`, {
              message: this.pushMessage,
              email: this.coach.email,
            });

            this.$successToast("Notification envoyée");
          } catch (e) {
            this.$errorToast(
              "Une erreur est survenue lors de l'envoi de la notification"
            );
          } finally {
            this.pushMessage = null;
          }
        },
      });
    },
    async loadSettings() {
      this.settings = null;

      const { data } = await this.$api.get("/settings");

      this.settings = data;
    },
    async loadSessions() {
      const { data } = await this.$api.get(
        "/sessions?coach=" + this.coach.email
      );

      this.sessions = data;
    },
    async loginToCoach() {
      const { data } = await this.$api.put("/users/signin", {
        email: this.coach.email,
      });

      this.$store.commit("AUTH_TOKEN", data.token);
      window.location.reload(true);
    },
    removeUser() {
      this.$confirm({
        message: "Attention, cette action est irréversible !",
        onConfirm: async () => {
          await this.$api.delete("/users", {
            data: {
              email: this.coach.email,
            },
          });

          this.$router.push({
            name: "users",
          });

          window.location.reload(true);
        },
      });
    },
    goToClients(coach) {
      const route = this.$router.resolve({
        name: "clients",
        query: {
          coachId: coach.id,
        },
      });

      window.open(route.href, "_blank");
    },
    removeComment(commentId, index) {
      this.$confirm({
        message:
          "Êtes-vous sûr de vouloir supprimer ce commentaire définitivement ?",
        onConfirm: async () => {
          await this.$api.delete(`/users/coach/comment/${commentId}`);

          this.coach.comments.splice(index, 1);
        },
      });
    },
    async changeRefCommercial(commercial) {
      this.formSaving = true;

      const update = {
        email: this.coach.email,
        refCommercialEmail: commercial,
      };

      await this.$api.put("/users", update);

      this.refCommercial = this.commercialsOptions.find(
        (option) => option.value === commercial
      );
      this.formSaving = false;
    },
    removeRefCommercial() {
      this.$confirm({
        message: "Supprimer le commercial référent ?",
        onConfirm: async () => {
          this.formSaving = true;

          const update = {
            email: this.coach.email,
            refCommercialEmail: null,
          };

          await this.$api.put("/users", update);

          this.refCommercial = null;
          this.formSaving = false;
        },
      });
    },
  },
  watch: {
    $route() {
      this.loadCoach();
    },
  },
};
</script>

<style type="text/css">
.events-wrapper {
  background: #384042 !important;
  padding: 5px !important;
}

p.time {
  font-size: 12px !important;
  right: 20px !important;
}

h3.title a {
  color: #444 !important;
}

h2.date {
  display: none;
}

#userPicture {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: -56px;
  right: 15px;
  z-index: 99;
  cursor: pointer;
}

#userPicture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.current-status input {
  color: inherit;
  background-color: inherit;
}

.form-comment .type {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.form-comment .type i {
  margin-right: 10px;
  cursor: pointer;
}

.form-comment input[type="radio"] {
  display: none;
}

.form-comment input[type="radio"]:checked + label > i.fa-thumbs-up,
.card i.fa-thumbs-up {
  color: #79c447;
}

.form-comment input[type="radio"]:checked + label > i.fa-thumbs-down,
.card i.fa-thumbs-down {
  color: #ff5454;
}

tr.comments {
  display: flex;
}

tr.comments td {
  flex: 2;
}

tr.comments td.com-content {
  flex: 6;
}

tr.comments td.com-thumb,
tr.comments td.com-remove {
  flex: 1;
}

.com-header {
  display: flex;
  justify-content: space-between;
}

.com-header .tabs span {
  cursor: pointer;
  margin-left: 10px;
}

.com-header .tabs span.active {
  color: #20a8d8;
}
</style>
