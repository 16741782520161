<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8">
          <div class="card-group mb-0">
            <form class="card p-2" @submit.prevent="submitForm">
              <div class="card-block">
                <h1>Login</h1>
                <p class="text-muted">Veuillez vous identifier</p>
                <div class="input-group mb-1">
                  <span class="input-group-addon"
                    ><i class="icon-user"></i
                  ></span>
                  <input
                    v-model="email"
                    type="text"
                    class="form-control"
                    placeholder="Adresse email"
                  />
                </div>
                <div class="input-group mb-2">
                  <span class="input-group-addon"
                    ><i class="icon-lock"></i
                  ></span>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    placeholder="Mot de passe"
                  />
                </div>
                <div v-if="formError" class="alert alert-danger">
                  {{ formError }}
                </div>
                <div v-if="passwordResetValidation" class="alert alert-info">
                  {{ passwordResetValidation }}
                </div>
                <div class="row">
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary px-2">
                      Valider
                    </button>
                  </div>
                  <div class="col-6 text-right">
                    <button
                      type="button"
                      class="btn btn-link px-0"
                      @click="passwordModal = true"
                    >
                      Mot de passe oublié ?
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <div
              class="card card-inverse card-primary py-3 hidden-md-down"
              style="width: 44%"
            >
              <div class="card-block text-center">
                <div>
                  <h2>Just Coaching</h2>
                  <p>Interface de gestion.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      title="Mot de passe oublié"
      v-model="passwordModal"
      cancelText="Annuler"
      okText="Réinitialiser mon mot de passe"
      @ok="sendEmailPassword()"
      effect="fade/zoom"
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title">Saissisez votre email</h4>
      </div>
      <input
        class="form-control"
        v-model="emailToReset"
        placeholder="Email de votre compte"
        type="email"
        v-on:keyup.enter="sendEmailPassword()"
        required
      />
      <div v-if="passwordResetError" class="alert alert-danger">
        {{ passwordResetError }}
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },
  name: "Login",
  data() {
    return {
      formError: null,
      email: "",
      password: "",
      passwordModal: false,
      emailToReset: null,
      passwordResetError: null,
      passwordResetValidation: null,
    };
  },
  methods: {
    async submitForm() {
      this.formError = "";
      if (this.email.length === 0) {
        this.formError = "L'email est obligatoire";
        return;
      }
      if (this.password.length === 0) {
        this.formError = "Le mot de passe est obligatoire";
        return;
      }

      try {
        const { data } = await this.$api.post("/users/signin", {
          email: this.email,
          password: this.password,
        });

        if (typeof data.token === "undefined") {
          throw new Error("No token found");
        }
        await this.$store.commit("AUTH_TOKEN", data.token);
        await this.$store.commit("AUTH_CHANGE_PASSWORD", data.changePassword);

        await this.$store.dispatch("loadUser");

        if (!this.$route.query.redirect) {
          this.$router.push(
            this.$store.state.user && this.$store.state.user.admin
              ? "/"
              : "/account"
          );
        } else {
          this.$router.push(this.$route.query.redirect);
        }
      } catch (e) {
        this.formError = "Impossible de se connecter avec ces identifiants.";
      }
    },
    async sendEmailPassword() {
      if (!this.emailToReset) {
        this.passwordResetError = "Veuillez saisir un email valide.";
      } else {
        try {
          await this.$api.post("/users/resetPassword", {
            email: this.emailToReset,
          });

          this.passwordResetValidation =
            "Vous allez recevoir un email avec votre nouveau mot de passe.";
        } catch (e) {
          this.passwordResetValidation =
            "Votre email " + this.emailToReset + " n'a pas été reconnu";
        }

        this.passwordModal = false;
        this.emailToReset = "";
      }
    },
  },
};
</script>
