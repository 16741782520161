<template>
  <div>
    <loader :isVisible="loading" />
    <StatsFilters
      :concepts="concepts"
      :commercials="commercials"
      :ranks="ranks"
      :show-date="true"
      :dateDefault="defaultDates"
      @apply-filters="load"
    />
    <div class="card">
      <div class="card-block">
        <tabs v-model="activeTab" @input="handleNewActiveTab">
          <tab :header="endedHeader">
            <Fidelisation
              :orders="fidelisations.ended"
              :count="totals.ended"
              @showComment="displayComment"
              @updateRank="updateRank"
              @removeEntry="(...args) => this.removeEntry('ended', ...args)"
              @changePage="changePage"
              @sort="sortField"
            />
          </tab>
          <tab :header="inProgressHeader">
            <Fidelisation
              :orders="fidelisations.inProgress"
              :count="totals.inProgress"
              @showComment="displayComment"
              @updateRank="updateRank"
              @removeEntry="
                (...args) => this.removeEntry('inProgress', ...args)
              "
              @changePage="changePage"
              @sort="sortField"
            />
          </tab>
          <tab :header="followedHeader">
            <Fidelisation
              :orders="fidelisations.followed"
              :count="totals.followed"
              :followed="true"
              @showComment="displayComment"
              @updateRank="updateRank"
              @removeEntry="(...args) => this.removeEntry('followed', ...args)"
              @changePage="changePage"
              @sort="sortField"
            />
          </tab>
          <tab :header="archivedHeader">
            <Fidelisation
              :orders="fidelisations.archived"
              :count="totals.archived"
              @showComment="displayComment"
              @updateRank="updateRank"
              @removeEntry="(...args) => this.removeEntry('archived', ...args)"
              @changePage="changePage"
              @sort="sortField"
            />
          </tab>
        </tabs>
      </div>
    </div>
    <modal
      v-if="modalUpdateComment.order"
      title="Commentaire"
      v-model="modalUpdateComment.showComment"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateComment()"
      effect="fade/zoom"
    >
      <textarea
        v-model="modalUpdateComment.order.fidelisation.notes"
        cols="30"
        rows="10"
        class="form-control form-normalization"
      >
      </textarea>
    </modal>
    <modal
      v-if="
        modalUpdateRank.order &&
        hasPermission($store.state.user, 'CLIENTS_WRITE')
      "
      title="Confirmer"
      v-model="modalUpdateRank.show"
      cancelText="Annuler"
      okText="Enregistrer"
      @ok="updateLostRank()"
      effect="fade/zoom"
    >
      <p>Convertir le pack en "Perdu" ?</p>
      <el-select
        v-if="isProspect"
        v-model="modalUpdateRank.editClientLostReason"
        size="mini"
        class="w-100"
      >
        <el-option
          v-for="reason in prospectLostReasons"
          :key="reason.value"
          :value="reason.value"
          :label="reason.label"
        />
      </el-select>

      <el-select
        v-else
        v-model="modalUpdateRank.editClientLostReason"
        size="mini"
        class="w-100"
      >
        <el-option
          v-for="reason in lostReasons"
          :key="reason.value"
          :value="reason.value"
          :label="reason.label"
        />
      </el-select>
    </modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Modal from "vue-strap/src/Modal";
import StatsFilters from "../components/StatsFilters";
import mixin from "../mixin";
import { mapGetters } from "vuex";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Fidelisation from "../components/Fidelisation";
import Loader from "../components/Loader";

export default {
  components: {
    StatsFilters,
    Tabs,
    Tab,
    Fidelisation,
    Modal,
    Loader,
  },

  mixins: [mixin],

  data() {
    return {
      defaultDates: {
        begin: moment().subtract(1, "y").toLocaleString(),
        end: moment().toLocaleString(),
      },
      concepts: [],
      commercials: [],
      activeTab: 0,
      fidelisations: {
        inProgress: [],
        ended: [],
        followed: [],
        archived: [],
      },
      totals: {
        inProgress: 0,
        ended: 0,
        followed: 0,
        archived: 0,
      },
      modalUpdateComment: {
        showComment: false,
        order: null,
      },
      modalUpdateRank: {
        show: false,
        order: null,
        editClientLostReason: null,
      },
      page: 1,
      form: {},
      loading: false,
      sort: {},
    };
  },

  computed: {
    fidelisationStatus() {
      switch (this.activeTab) {
        case 0:
          return "ended";
        case 1:
          return "inProgress";
        case 2:
          return "followed";
        case 3:
          return "archived";
        default:
          return null;
      }
    },

    ...mapGetters({
      lostReasons: "getLostReasons",
      ranks: "getFidelisationRanks",
    }),

    inProgressHeader() {
      return `<i class="icon-user"></i> Bientôt fini ${
        this.totals.inProgress !== 0 ? "(" + this.totals.inProgress + ")" : ""
      }`;
    },

    endedHeader() {
      return `<i class="icon-user"></i> Pack fini ${
        this.totals.ended !== 0 ? "(" + this.totals.ended + ")" : ""
      }`;
    },

    followedHeader() {
      return `<i class="icon-user"></i> Pack suivi ${
        this.totals.followed !== 0 ? "(" + this.totals.followed + ")" : ""
      }`;
    },

    archivedHeader() {
      return `<i class="icon-user"></i> Archivé ${
        this.totals.archived !== 0 ? "(" + this.totals.archived + ")" : ""
      }`;
    },
  },

  async created() {
    await this.fetchConcepts();
    await this.fetchCommercials();
  },

  methods: {
    async fetchConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.concepts = data.map((c) => c.name);
    },

    async fetchCommercials() {
      const { data } = await this.$api.get("/users/commercials/search");

      this.commercials = data.map((c) => ({
        value: c.email,
        label: c.commercial.pseudo,
      }));
    },

    async sortField(fields) {
      this.sort = fields;

      await this.applyFilters(1);
    },

    async changePage(page) {
      await this.applyFilters(page);
    },

    async load(form) {
      this.form = form;

      await this.applyFilters(1);
    },

    async applyFilters(page = 1) {
      this.loading = true;

      const { data } = await this.$api.get("/orders/fidelisation", {
        params: {
          begin: this.form.begin ? this.form.begin : null,
          end: this.form.end ? this.form.end : null,
          client: this.form.client ? this.form.client.value : null,
          coach: this.form.coach ? this.form.coach.value : null,
          status: this.form.status ? this.form.status.value : null,
          concept: this.form.concept,
          archived: this.fidelisationStatus === "archived" ? 1 : 0,
          completed: this.fidelisationStatus === "ended" ? 1 : 0,
          nearend: this.fidelisationStatus === "inProgress" ? 1 : 0,
          followed: this.fidelisationStatus === "followed" ? 1 : 0,
          rank: this.form.rank ? this.form.rank.value : null,
          commercial: this.form.commercial ? this.form.commercial.value : null,
          offset: page * 50 - 50,
          sort: this.sort.order ? this.sort.order : null,
          sortField: this.sort.prop ? this.sort.prop : null,
        },
      });

      switch (this.fidelisationStatus) {
        case "inProgress":
          this.fidelisations.inProgress = data.orders;
          this.totals.inProgress = data.count;
          break;
        case "ended":
          this.fidelisations.ended = data.orders;
          this.totals.ended = data.count;
          break;
        case "followed":
          this.fidelisations.followed = data.orders;
          this.totals.followed = data.count;
          break;
        case "archived":
          this.fidelisations.archived = data.orders;
          this.totals.archived = data.count;
          break;
      }

      this.loading = false;
    },

    handleNewActiveTab() {},
    displayComment(attribution) {
      this.modalUpdateComment.showComment = true;
      this.modalUpdateComment.order = attribution;
    },

    async updateComment() {
      await this.$api
        .put(`/orders/fidelisation/${this.modalUpdateComment.order.id}`, {
          fidelisation: this.modalUpdateComment.order.fidelisation,
        })
        .catch((err) => {
          console.log(err);
        });

      this.modalUpdateComment.showComment = false;
    },

    async updateRank(order, rank) {
      if (rank === "pending" || rank === "fidelisation") {
        const message =
          rank === "pending"
            ? 'Convertir le pack "En attente" ?'
            : 'Convertir le pack en "Fidélisation" ?';

        this.$confirm({
          message,
          onConfirm: async () => {
            await this.$api
              .put(`/orders/fidelisation/${order.id}`, {
                rank,
                fidelisation: order.fidelisation,
              })
              .catch((err) => {
                console.log(err);
              });

            order.rank = rank;
          },
        });
      }

      if (rank === "lost") {
        this.modalUpdateRank.show = true;
        this.modalUpdateRank.order = order;
      }
    },

    async updateLostRank() {
      this.modalUpdateRank.order.rank = "lost";
      const order = this.modalUpdateRank.order;
      order.fidelisation.lostReason = this.modalUpdateRank.editClientLostReason;

      await this.$api
        .put(`/orders/fidelisation/${order.id}`, {
          rank: "lost",
          fidelisation: order.fidelisation,
        })
        .catch((err) => {
          console.log(err);
        });

      this.modalUpdateRank.show = false;
    },

    removeEntry(array, id) {
      this.fidelisations[array] = this.fidelisations[array].filter(
        (f) => f.id !== id
      );
      this.totals[array] = this.fidelisations[array].length;
    },
  },
};
</script>

<style type="text/css">
.fidelisation-modal .modal-dialog {
  max-width: 80%;
}
</style>
