<template>
  <div>
    <p v-if="paginatedSessions.length === 0" class="text-center text-muted">
      Aucun cours Live / Visio {{ oldSession ? "passé" : "à venir" }}
    </p>

    <template v-else>
      <table class="table table-condensed">
        <thead>
          <tr>
            <th>Nom du cours</th>
            <th>Type de cours</th>
            <th>Avoir</th>
            <th>Durée</th>
            <th>Niveau</th>
            <th>Participants</th>
            <th>Programmée le</th>
            <th v-if="!oldSession">Lien Room</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(session, index) in paginatedSessions"
            :key="index"
            class="click"
          >
            <td>
              <span v-if="session.live">{{ session.live.name }}</span>
              <i v-else>Non défini</i>
            </td>
            <td>
              <span v-if="session.live">{{
                session.live.type === "small" ? "Visio" : "Live"
              }}</span>
              <i v-else>Non défini</i>
            </td>
            <td>{{ formatPrice(session.credit, true) }}</td>
            <td>{{ formatDuration(session.duration) }}</td>
            <td>
              <span v-if="session.live">{{ getLvl(session.live.lvl) }}</span>
              <i v-else>Non défini</i>
            </td>
            <td class="text-primary">
              <span @click="openBookingsModal(session.bookings)">
                {{ numberOfBookings(session) }}
                <span v-if="sessionQuotas(session)"
                  >/ {{ sessionQuotas(session) }}</span
                >
              </span>
            </td>
            <td>
              <strong>{{ getDay(session.scheduledAt) }}</strong>
              {{ formatDateTime(session.scheduledAt) }}
              <span v-if="session.canceledAt" class="text-warning">
                Session annulée
              </span>
            </td>
            <td v-if="!oldSession">
              <button class="btn btn-info" @click="goToRoom(session)">
                Rejoindre la séance
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <paginate
        v-model="currentPage"
        :page-count="pageCount"
        :click-handler="handleCurrentChange"
        prev-text="Précédent"
        next-text="Suivant"
        container-class="pagination"
      ></paginate>

      <SessionBookingsModal
        :show="bookingsModal.show"
        :bookings="bookingsModal.bookings"
        is-coach-account
        @close="closeBookingsModal"
      />
    </template>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";
import SessionBookingsModal from "../components/SessionBookingsModal";

export default {
  components: {
    Paginate,
    SessionBookingsModal,
  },

  props: {
    sessions: {
      type: Array,
      default: () => [],
    },
    oldSession: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentPage: 1,
      limit: 10,
      bookingsModal: {
        show: false,
        bookings: [],
      },
    };
  },

  computed: {
    paginatedSessions() {
      return this.sessions.slice(
        this.currentPage * this.limit - this.limit,
        this.currentPage * this.limit
      );
    },

    pageCount() {
      return Math.ceil(this.sessions.length / this.limit);
    },

    clientAppUrl() {
      return process.env.VUE_CLIENT_APP_URL || "https://app.justcoaching.fr/";
    },
  },

  methods: {
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    goToRoom(session) {
      const route = `${this.clientAppUrl}live/rooms/${session.id}?secret=${session.coachSecret}`;

      window.open(route);
    },

    getLvl(lvl) {
      switch (lvl) {
        case 0:
          return "Débutant";
        case 1:
          return "Intermédiaire";
        case 2:
          return "Confirmé";
        default:
          return "Tous niveaux";
      }
    },

    numberOfBookings(session) {
      return session.bookings?.length || 0;
    },

    sessionQuotas(session) {
      return session.sessionLive?.live?.quotas;
    },

    openBookingsModal(bookings) {
      this.bookingsModal.bookings = bookings;
      this.bookingsModal.show = true;
    },

    closeBookingsModal() {
      this.bookingsModal.show = false;
      this.bookingsModal.bookings = [];
    },
  },
};
</script>

<style scoped>
.table {
  overflow-x: auto;
}
</style>
