<template>
  <div class="animated fadeIn">
    <loader :isVisible="isLoading" />
    <div class="row">
      <div class="col-md-3">
        <el-switch
          v-model="advanced"
          active-text="Avancé"
          inactive-text="Minimal"
          size="mini"
        />
      </div>
    </div>
    <div class="row mt-1">
      <div class="form-group col-sm-4">
        <label>Date de début</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.dataBegin, placement: 'right' }"
        ></i>
        <datepicker
          v-model="filters.dateBegin"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4">
        <label>Date de fin</label>
        <datepicker
          v-model="filters.dateEnd"
          format="dd.MM.yyyy"
          language="fr"
          input-class="form-control"
        ></datepicker>
      </div>
      <div class="form-group col-sm-4 d-flex flex-column">
        <label class="mb-1">Archives</label>
        <el-radio-group v-model="filters.archives">
          <el-radio :label="'hidden'">Sans</el-radio>
          <el-radio :label="'displayed'">Avec</el-radio>
          <el-radio :label="'archivesOnly'">Archives uniquement</el-radio>
        </el-radio-group>
      </div>
      <div class="form-group col-sm-4">
        <label>Concept</label>
        <multiselect
          v-model="filters.concepts"
          :options="conceptOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Statut</label>
        <multiselect
          v-model="filters.status"
          :options="statusOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Statut du paiement</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.paymentStatus, placement: 'right' }"
        ></i>
        <multiselect
          v-model="filters.paymentStatus"
          :options="paymentStatusOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Type de commande</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.orderType, placement: 'right' }"
        ></i>
        <multiselect
          v-model="filters.orderTypes"
          :options="orderTypes"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Status du pack</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.packs, placement: 'right' }"
        ></i>
        <multiselect
          v-model="filters.packs"
          :options="packsOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Inactif</label>
        <multiselect
          v-model="filters.inactiveSince"
          :options="inactiveSinceOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Département</label>
        <multiselect
          v-model="filters.departments"
          :options="departmentsOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Sexe</label>
        <multiselect
          v-model="filters.genders"
          :options="gendersOptions"
          label="label"
          track-by="label"
          :multiple="true"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4" v-if="advanced">
        <label>Coachs</label>
        <multiselect
          v-model="filters.coachs"
          :options="coachsOptions"
          label="label"
          track-by="label"
          :multiple="true"
          :searchable="true"
          :loading="searchCoachsLoading"
          :internal-search="false"
          :close-on-select="false"
          selectLabel=""
          deselectLabel=""
          @search-change="searchCoachs"
        >
          <template slot="noOptions"> Rechercher un coach </template>
          <template slot="noResult">
            Aucun résultat (Attention la recherche dois faire plus de 2
            caractères)
          </template>
        </multiselect>
      </div>

      <div class="form-group col-sm-4" v-if="advanced">
        <label>Séances restantes</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.remainingSessions, placement: 'right' }"
        ></i>
        <multiselect
          v-model="filters.remainingSession"
          :options="remainingSessionOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="form-group col-sm-4">
        <label>Offre SMS / Mail</label>
        <multiselect
          v-model="filters.newsletter"
          :options="newsletterOptions"
          label="label"
          track-by="label"
        >
        </multiselect>
      </div>
      <div class="col-sm-4" v-if="advanced">
        <label>Date de la derniere séance validée entre le</label>
        <div class="row">
          <div class="form-group col-sm-6">
            <datepicker
              v-model="filters.dateLastSessionBegin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-sm-6">
            <datepicker
              v-model="filters.dateLastSessionEnd"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-3" v-if="advanced">
        <label>Fréquence</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.frequency, placement: 'right' }"
        ></i>
        <div class="row">
          <div class="form-group col-sm-12">
            <el-input-number
              placeholder="min"
              v-model="filters.frequencyMin"
              size="small"
              controls-position="right"
            ></el-input-number>
            <el-input-number
              placeholder="max"
              v-model="filters.frequencyMax"
              size="small"
              controls-position="right"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="advanced">
        <label>Nombre de pack fidélisés</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.nbPackFidelise, placement: 'right' }"
        ></i>
        <div class="row justify-content-md-center">
          <div class="form-group col-sm-12">
            <el-input-number
              placeholder="min"
              v-model="filters.nbPackFideliseMin"
              size="small"
              controls-position="right"
            ></el-input-number>
            <el-input-number
              placeholder="max"
              v-model="filters.nbPackFideliseMax"
              size="small"
              controls-position="right"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="advanced">
        <label>Nombre de sessions faites</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.nbSessionsDone, placement: 'right' }"
        ></i>
        <div class="row justify-content-md-center">
          <div class="form-group col-sm-12">
            <el-input-number
              placeholder="min"
              v-model="filters.nbSessionsDoneMin"
              size="small"
              controls-position="right"
            ></el-input-number>
            <el-input-number
              placeholder="max"
              v-model="filters.nbSessionsDoneMax"
              size="small"
              controls-position="right"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="col-sm-3" v-if="advanced">
        <label>Nombre de session moyenne par pack</label>
        <i
          class="fa fa-info-circle ml-05"
          v-tooltip="{ content: helpers.nbAverageSessions, placement: 'right' }"
        ></i>
        <div class="row justify-content-md-center">
          <div class="form-group col-sm-12">
            <el-input-number
              placeholder="min"
              v-model="filters.nbAverageSessionsMin"
              size="small"
              controls-position="right"
            ></el-input-number>
            <el-input-number
              placeholder="max"
              v-model="filters.nbAverageSessionsMax"
              size="small"
              controls-position="right"
            ></el-input-number>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6"></div>
      <div class="col-sm-6">
        <button
          type="button"
          class="btn btn-sm btn-primary float-right mx-1"
          @click="download()"
        >
          <i class="icon-cloud-download"></i>&nbsp; Télécharger
        </button>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-sm-12">
        <h5>En-tête du fichier Excel</h5>
      </div>
      <div class="col-sm-12">
        <ul>
          <li>Nom</li>
          <li>Prénom</li>
          <li>Email</li>
          <li>Email de contact</li>
          <li>Adresse</li>
          <li>Code postal</li>
          <li>Ville</li>
          <li>Téléphone</li>
          <li>Créé le</li>
          <li>Converti en client le</li>
          <li>Sexe</li>
          <li class="text-primary" v-if="advanced">Coach</li>
          <li>Statut</li>
          <li class="text-primary" v-if="advanced">Fréquence</li>
          <li>Concepts</li>
          <li class="text-primary" v-if="advanced">
            Nombre de pack (Sans découverte) payé
          </li>
          <li class="text-primary" v-if="advanced">
            Nombre de séances faites (Avec la séance découverte)
          </li>
          <li class="text-primary" v-if="advanced">
            Nombre de séances moyenne par pack (Sans découverte)
          </li>
          <li class="text-primary" v-if="advanced">
            Nombre de séances restantes
          </li>
          <li class="text-primary" v-if="advanced">Séance payé Découverte</li>
          <li class="text-primary" v-if="advanced">
            Séance non payé Découverte
          </li>
          <li class="text-primary" v-if="advanced">Séance payé Conversion</li>
          <li class="text-primary" v-if="advanced">
            Séance non payé Conversion
          </li>
          <li class="text-primary" v-if="advanced">Séance payé OneShot</li>
          <li class="text-primary" v-if="advanced">Séance non payé OneShot</li>
          <li class="text-primary" v-if="advanced">Séance payé Fidélisation</li>
          <li class="text-primary" v-if="advanced">
            Moyenne séance payé Fidélisation
          </li>
          <li class="text-primary" v-if="advanced">
            Séance non payé Fidélisation
          </li>
          <li class="text-primary" v-if="advanced">Séance payé Autre</li>
          <li class="text-primary" v-if="advanced">Séance non payé Autre</li>
          <li>Administrateur</li>
          <li>Note coach</li>
          <li>Avis google</li>
          <li>Avis trustpilot</li>
          <li>Matricule</li>
          <li>Offre SMS/email</li>
          <li>Note</li>
          <li>Date de naissance</li>
          <li>Note client</li>
          <li>Disponibilité</li>
          <li>Objectif</li>
          <li>Lieux des séances</li>
          <li>Personnes / séances</li>
          <li>Note commercial</li>
          <li class="text-primary" v-if="advanced">Nombre de jours inactifs</li>
          <li>Archivé</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Multiselect from "vue-multiselect";
import debounce from "lodash.debounce";
import Loader from "../components/Loader";
import DownloadFile from "../mixin/DownloadFile";
import { mapGetters } from "vuex";

export default {
  components: {
    Datepicker,
    Multiselect,
    Loader,
  },
  mixins: [DownloadFile],
  data() {
    return {
      isLoading: false,
      filters: {
        dateBegin: moment()
          .subtract(1, "months")
          .startOf("day")
          .format("YYYY-MM-DD"),
        dateEnd: moment().endOf("day").format("YYYY-MM-DD"),
        archives: "hidden",
        status: [],
        concepts: [],
        paymentStatus: [],
        packs: [],
        genders: [],
        inactiveSince: [],
        departments: [],
        remainingSession: [],
        newsletter: [],
        coachs: [],
        dateLastSessionBegin: null,
        dateLastSessionEnd: null,
        frequencyMin: undefined,
        frequencyMax: undefined,
        nbPackFideliseMin: undefined,
        nbPackFideliseMax: undefined,
        nbSessionsDoneMin: undefined,
        nbSessionsDoneMax: undefined,
        nbAverageSessionsMin: undefined,
        nbAverageSessionsMax: undefined,
        orderTypes: [],
      },
      conceptOptions: [],
      paymentStatusOptions: [
        { label: "Non payé", value: "pending" },
        { label: "Paiement partiel", value: "partial" },
        { label: "Payé", value: "payed" },
      ],
      packsOptions: [
        { label: "Pas de pack", value: "no_pack" },
        { label: "En cours", value: "in_progress" },
        { label: "Bientôt fini", value: "finished_soon" },
        { label: "Fini", value: "finished" },
      ],
      gendersOptions: [
        { label: "Homme", value: "Homme" },
        { label: "Femme", value: "Femme" },
      ],
      inactiveSinceOptions: [
        { label: "Tous les inactifs", value: "all" },
        { label: "Moins d'1 mois", value: "less_then_one_month" },
        { label: "1 mois et plus", value: "one_month_and_more" },
        { label: "2 mois et plus", value: "two_month" },
        { label: "3 mois et plus", value: "three_month" },
        { label: "4 mois et plus", value: "four_month" },
        { label: "5 mois et plus", value: "five_month" },
        { label: "6 mois et plus", value: "six_month" },
      ],
      departmentsOptions: [],
      coachsOptions: [],
      searchCoachsLoading: false,
      remainingSessionOptions: [
        { label: "Oui", value: 1 },
        { label: "Non", value: 0 },
      ],
      newsletterOptions: [
        { label: "Oui", value: 1 },
        { label: "Non", value: 0 },
      ],
      orderTypes: [
        { label: "Découverte", value: "discovery" },
        { label: "Pack Conversion", value: "conversion" },
        { label: "Pack OneShot", value: "oneshot" },
        { label: "Pack Fidélisation", value: "fidelisation" },
        { label: "Pack Autre", value: "other" },
      ],
      helpers: {
        dataBegin:
          "Date de conversion d'un prospect en client. Attention ! Les clients n'ayant pas de date de conversion ne pourront pas être pris en compte.",
        paymentStatus: "Statut du paiement de la dernière commande du client.",
        packs:
          "Tous packs confondus, si le client à une seule commande (ou plus) correspondante au filtre alors il sera présent dans l'export.",
        frequency:
          "La fréquence est le nombre de séance/jour du client, ex: 7 -> une séance par semaine",
        remainingSessions: "Tous packs confondus.",
        nbPackFidelise: "Nombre de packs, sans le pack découverte",
        nbSessionsDone:
          "Nombre de sessions effectuées par le client, incluant la session découverte",
        nbAverageSessions:
          "Nombre de sessions en moyenne par packs sans la session découverte.",
        orderType:
          "Retourne tous les clients qui possèdent au moins une commande de l'un des types séléctionnés. (Payé ou pas)",
      },
      advanced: false,
    };
  },

  async created() {
    await this.getConcepts();
    await this.getDepartment();
  },

  computed: {
    ...mapGetters({
      statusOptions: "getClientStatus",
    }),

    sanitarizeMinimalFilters() {
      return {
        dateBegin: this.filters.dateBegin,
        dateEnd: this.filters.dateEnd,
        archives: this.filters.archives,
        concepts: this.filters.concepts.map((c) => c.value),
        status: this.filters.status.map((s) => s.value),
        genders: this.filters.genders.map((g) => g.value),
        newsletter: this.filters.newsletter && this.filters.newsletter.value,
        filter: this.advanced ? 1 : 0,
      };
    },

    sanitarizeFilters() {
      return {
        ...this.sanitarizeMinimalFilters,
        inactiveSince:
          this.filters.inactiveSince && this.filters.inactiveSince.value,
        remainingSession:
          this.filters.remainingSession && this.filters.remainingSession.value,
        coachs: this.filters.coachs.map((c) => c.id),
        departments: this.filters.departments.map((d) => d.value),
        dateLastSessionBegin: this.filters.dateLastSessionBegin,
        dateLastSessionEnd: this.filters.dateLastSessionEnd,
        paymentStatus: this.filters.paymentStatus.map((p) => p.value),
        packs: this.filters.packs.map((p) => p.value),
        frequencyMin:
          this.filters.frequencyMin !== 0 ? this.filters.frequencyMin : null,
        frequencyMax:
          this.filters.frequencyMax !== 0 ? this.filters.frequencyMax : null,
        nbPackFideliseMin:
          this.filters.nbPackFideliseMin !== null
            ? this.filters.nbPackFideliseMin
            : null,
        nbPackFideliseMax:
          this.filters.nbPackFideliseMax !== null
            ? this.filters.nbPackFideliseMax
            : null,
        nbSessionsDoneMin:
          this.filters.nbSessionsDoneMin !== null
            ? this.filters.nbSessionsDoneMin
            : null,
        nbSessionsDoneMax:
          this.filters.nbSessionsDoneMax !== null
            ? this.filters.nbSessionsDoneMax
            : null,
        nbAverageSessionsMin:
          this.filters.nbAverageSessionsMin !== null
            ? this.filters.nbAverageSessionsMin
            : null,
        nbAverageSessionsMax:
          this.filters.nbAverageSessionsMax !== null
            ? this.filters.nbAverageSessionsMax
            : null,
        orderTypes: this.filters.orderTypes.map((s) => s.value),
      };
    },
  },

  methods: {
    async download() {
      this.isLoading = true;

      const params = this.advanced
        ? this.sanitarizeFilters
        : this.sanitarizeMinimalFilters;

      const { data } = await this.$api.get("/exports/clients", {
        params,
      });

      this.downloadXLSX(data, "client.xlsx");
      this.isLoading = false;
    },
    async getConcepts() {
      const { data } = await this.$api.get("/concepts");

      this.conceptOptions = data.map((c) => {
        return { label: c.name, value: c.name };
      });
    },

    async getDepartment() {
      const { data } = await this.$api.get("/clients/departments");

      this.departmentsOptions = data.map((z) => {
        return { label: z, value: z };
      });
    },

    async searchCoachs(search) {
      if (search !== "" && search.length > 2) {
        await this.fetchCoachs(search, this);
      }
    },

    fetchCoachs: debounce(async (search, vm) => {
      vm.searchCoachsLoading = true;

      const { data } = await vm.$api.post("/users/coachs/search", {
        terms: search,
      });

      vm.coachsOptions = data.map((c) => ({
        label: `${c.coach ? c.coach.name : ""} (${c.email})`,
        value: c.email,
        id: c.id,
      }));

      vm.searchCoachsLoading = false;

      return vm.coachsOptions;
    }, 300),
  },
};
</script>
