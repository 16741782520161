<template>
  <div class="card">
    <div class="card-header"><i class="icon-briefcase"></i> Mes factures</div>
    <div class="card-block">
      <el-table :data="disbursements" style="width: 100%">
        <el-table-column prop="createdAt" label="Crée le">
          <template slot-scope="scope">
            {{ formatDateTime(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Traité le">
          <template slot-scope="scope">
            {{
              scope.row.processedAt
                ? formatDateTime(scope.row.processedAt)
                : "En cours de traitement"
            }}
          </template>
        </el-table-column>
        <el-table-column label="Payé le">
          <template slot-scope="scope">
            {{
              scope.row.completedAt
                ? formatDateTime(scope.row.completedAt)
                : "En cours de traitement"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="credit" label="Valeur emise">
          <template slot-scope="scope">
            {{ formatPrice(scope.row.credit) }}
          </template>
        </el-table-column>
        <el-table-column prop="createdAt" label="Facture">
          <template slot-scope="scope">
            <a
              :href="goToPDF(scope.row.invoice)"
              class="btn btn-sm btn-outline-primary"
            >
              <i class="icon-cloud-download"></i> Télécharger
            </a>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {
    disbursements: {
      type: Array,
      required: true,
    },
  },

  methods: {
    goToPDF(pdf) {
      return `${axios.defaults.baseURL}uploads/invoice/${pdf}.pdf`;
    },
  },
};
</script>

<style></style>
