<template>
  <div class="card">
    <div class="card-header"><i class="icon-camrecorder"></i> Codes Promo</div>
    <div class="card-block">
      <PromoCodesTable
        :promo-codes="promoCodes"
        :total-sales="promoTotalSales"
        :total-ca="promoTotalCA"
        :total-commission="promoTotalBrutCommission"
        :total-available-commission="promoTotalCommission"
        :can-withdraw="isOnlyInfluencer"
        :hold="disburseCodeOnHold"
        is-influencer-account
        @disburse="handleDisburseCode()"
      />
    </div>

    <modal
      title="Commission code promo"
      v-model="disburseCodeNow"
      cancelText="Annuler"
      okText="Valider"
      @ok="handleDisburseCodeNow()"
      effect="fade/zoom"
      large
    >
      <div v-if="disburseCodeError" class="alert alert-danger">
        Une erreur est survenue lors de la demande de retrait de la commission.
        Veuillez réessayer.
      </div>

      <PromoCodesTable
        :promo-codes="promoCodes"
        :total-sales="promoTotalSales"
        :total-ca="promoTotalCA"
        :total-available-commission="promoTotalCommission"
        is-influencer-account
        show-only-available
      />

      <div class="form-group" v-if="coach">
        <dropzone
          :url="apiUploads()"
          :headers="apiAuthorization()"
          acceptedFileTypes="application/pdf"
          id="invoicecode"
          v-on:vdropzone-success="invoiceUploaded"
        >
          <div class="dz-default dz-message">
            <span
              ><i class="icon-cloud-upload"></i><br />Glissez votre facture au
              format PDF ici
            </span>
          </div>
        </dropzone>
        <div class="mt-2">
          <label>
            <input type="checkbox" v-model="cgu" />
            Je certifie ne pas dépendre économiquement de Just Coaching. Just
            Coaching ne constitue qu'une partie de ma clientèle.
          </label>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";
import PromoCodesTable from "../components/PromoCodesTable";

import CoachAccount from "../mixin/CoachAccount";

export default {
  components: {
    Modal,
    Dropzone,
    PromoCodesTable,
  },

  mixins: [CoachAccount],

  props: {
    coach: {
      type: Object,
      required: true,
    },

    disbursed: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      promoCodes: [],
      promoTotalSales: 0,
      promoTotalCA: 0,
      promoTotalCommission: 0,
      promoTotalBrutCommission: 0,
      disburseCodeNow: false,
      disburseCodeError: false,
      invoiceId: null,
      cgu: false,
    };
  },

  computed: {
    disburseCodeOnHold() {
      return this.disbursed.some(
        (disbursement) =>
          disbursement.promoCodes?.length > 0 &&
          !disbursement.completed &&
          !disbursement.rejected
      );
    },
  },

  mounted() {
    this.getPromoCodes();
  },

  methods: {
    async getPromoCodes() {
      const { data } = await this.$api.get(
        "/promo-codes/influencers/" + this.coach.id
      );

      data.map((promoCode) => {
        if (promoCode.discountUsages) {
          promoCode.totalCA = promoCode.discountUsages.reduce(
            (sum, usage) => sum + usage.amount,
            0
          );
          if (
            promoCode.influencerDiscount &&
            promoCode.influencerDiscount.euros
          ) {
            promoCode.commission =
              promoCode.influencerDiscount.euros *
              (promoCode.stripe.coupon.times_redeemed
                ? promoCode.stripe.coupon.times_redeemed
                : 0);
          } else if (
            promoCode.influencerDiscount &&
            promoCode.influencerDiscount.percentage
          ) {
            promoCode.commission =
              (promoCode.influencerDiscount.percentage / 100) *
              promoCode.totalCA;
          } else {
            promoCode.commission = 0;
          }

          promoCode.brutCommission = promoCode.commission;

          promoCode.commission -= promoCode.influencerDiscount.disbursed;
        }
      });

      this.promoCodes = data;

      this.promoCodes.forEach((promoCode) => {
        this.promoTotalSales += promoCode.stripe.coupon.times_redeemed;
        this.promoTotalCA += promoCode.totalCA;
        this.promoTotalBrutCommission += promoCode.brutCommission;
        this.promoTotalCommission += promoCode.commission;
      });
    },

    handleDisburseCode() {
      this.disburseCodeError = false;
      this.disburseCodeNow = true;
    },

    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    invoiceUploaded(file, response) {
      this.invoiceId = response.id;
    },

    async handleDisburseCodeNow() {
      if (!this.cgu) {
        this.$errorToast(
          "Vous devez certifier ne pas dépendre économiquement de Just Coaching"
        );

        return;
      }

      if (!this.invoiceId) {
        this.$errorToast("Vous devez uploader une facture au format PDF");

        return;
      }

      this.disburseCodeError = false;

      try {
        const { data } = await this.$api.post("/app/disbursecode/", {
          invoiceId: this.invoiceId,
          merchantId: this.disburseMerchant,
        });

        this.$emit("disbursed", data);
        this.disburseCodeNow = false;
      } catch (e) {
        this.disburseCodeError = true;
      }
    },
  },
};
</script>
