<template>
  <div class="animated fadeIn" active="0">
    <tabs>
      <tab
        header="Clients"
        v-if="clients && hasPermission($store.state.user, 'CLIENTS_READ')"
      >
        <clients
          :clients="clients"
          :rows="clients.length"
          :totalClients="clients.length"
          :page="1"
        />
      </tab>
      <tab
        header="Factures"
        v-if="orders && hasPermission($store.state.user, 'CLIENTS_READ')"
      >
        <Orders :orders="orders" :invoice="true" :status="false" />
      </tab>
      <tab
        header="Coachs"
        v-if="coachs && hasPermission($store.state.user, 'USERS_MENU_READ')"
      >
        <users :coachs="coachs" search-results />
      </tab>
    </tabs>
  </div>
</template>

<script>
import { tabs, tab } from "vue-strap";

import Clients from "../components/Clients";
import Users from "../components/Users";
import Orders from "../components/Orders";

export default {
  components: {
    Clients,
    Users,
    Orders,
    tabs,
    tab,
  },

  data() {
    return {
      clients: [],
      coachs: null,
      orders: null,
    };
  },

  async created() {
    const post = {
      terms: Array.isArray(this.$route.query.terms)
        ? this.$route.query.terms
        : [this.$route.query.terms],
      filterBy: Array.isArray(this.$route.query.filterBy)
        ? this.$route.query.filterBy
        : [this.$route.query.filterBy],
    };

    if (this.hasPermission(this.$store.state.user, "CLIENTS_READ")) {
      await this.loadClient(post);
      await this.loadInvoice(post);
    }

    if (this.hasPermission(this.$store.state.user, "USERS_MENU_READ")) {
      await this.loadCoach(post);
    }
  },

  methods: {
    async loadClient(post) {
      const { data } = await this.$api.post("/clients/search", post);

      this.clients = data;
    },

    async loadInvoice(post) {
      const { data } = await this.$api.post("/orders/search", post);

      this.orders = data;
    },

    async loadCoach(post) {
      const { data } = await this.$api.post("/users/coachs/search", post);

      this.coachs = data;
    },
  },
};
</script>
<style type="text/css">
.fade.in {
  opacity: 1;
}

.nav-tabs > li {
  padding: 10px 25px;
}

.nav-tabs > li:not(.active) a {
  color: #444;
}
</style>
