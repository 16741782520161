<template>
  <div class="client-details-header">
    <div class="py-1 px-2" style="position: relative">
      <h1 class="h3">
        <span v-if="client.company">{{ client.company }} / </span>
        {{ client.name }} ({{ client.email }})
        <i
          v-if="client.urssafInscription"
          class="fa fa-link"
          :class="client.urssafInscription.valid && 'text-success'"
        ></i>
      </h1>
      <div v-if="client.picture" class="img-wrapper" style="cursor: pointer">
        <img :src="client.picture" @click="showPicture = true" />
      </div>
    </div>

    <tabs v-model="activeTab" class="client-details" @input="changeTab">
      <tab
        :key="`tab-${index}`"
        v-for="(tab, index) of displayedTabs"
        :header="tab.label"
      >
      </tab>
    </tabs>

    <modal title="Photo" v-model="showPicture" large effect="fade/zoom">
      <div class="text-center">
        <img v-if="client.picture" :src="client.picture" class="img-full" />
      </div>

      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="showPicture = false"
        >
          Fermer
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";

export default {
  components: {
    Modal,
    Tabs,
    Tab,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },

    showLive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPicture: false,
      activeTab: 0,
      tabs: [
        {
          label: "Général",
          to: "global",
        },
        {
          label: "Commandes",
          to: "orders",
        },
        {
          label: "Paiements",
          to: "payments",
        },
        {
          label: "Suivi",
          to: "tracking",
        },
        {
          label: "Parrainages",
          to: "sponsorships",
        },
        {
          label: "Live",
          to: "live",
        },
      ],
    };
  },

  computed: {
    displayedTabs() {
      return this.showLive
        ? this.tabs
        : this.tabs.filter(({ label }) => label !== "Live");
    },
  },

  methods: {
    changeTab(index) {
      if (index >= 0) {
        const element = document.getElementById(this.displayedTabs[index].to);

        if (element)
          element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client-details-header {
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 100;
  background: #f2f4f8;
}

.img-wrapper {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.img-full {
  max-width: 400px;
}
</style>
