<template>
  <div class="card" v-if="coach.coach.isCoach || coach.coach.isFormer">
    <div class="card-header"><i class="icon-chart"></i> Statistiques</div>
    <div class="card-block">
      <dl class="row" v-if="!coach.coach.isInfluencer">
        <dt class="col-sm-6">Nombre de clients</dt>
        <dd class="col-sm-6">
          {{ clientsActive(coach.clients).length }} /
          {{ coach.clients.length }}
        </dd>
        <dt class="col-sm-6">Note générale</dt>
        <dd class="col-sm-6">
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          <i class="fa fa-star-o"></i>
          (0/5)
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    coach: {
      type: Object,
      required: true,
    },
  },
};
</script>
