<template>
  <div class="card">
    <div class="card-block">
      <div v-if="client.notes" v-html="nl2br(client.notes)" />
    </div>
    <div class="card-block card-inverse card-info">
      <div v-if="client.managerNotes" v-html="nl2br(client.managerNotes)" />
    </div>
    <div class="card-block">
      <div v-if="client.coachNotes" v-html="nl2br(client.coachNotes)" />
    </div>
    <div class="card-footer">
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="editNotes"
        v-if="checkPermission"
      >
        <i class="fa fa-pencil" /> Note Commercial [Appli.]
      </button>
      <button
        type="button"
        class="btn btn-sm btn-info"
        @click="editManagerNotes"
        v-if="checkPermission"
      >
        <i class="fa fa-pencil" /> Note Manager
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="editCoachNotes"
        v-if="checkPermission"
      >
        <i class="fa fa-pencil" /> Note du Coach [Appli.]
      </button>
    </div>

    <modal
      title="Notes sur l'application"
      v-model="edit"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditNotes()"
      effect="fade/zoom"
    >
      <div class="form-group">
        <textarea v-model="note" rows="4" class="form-control"></textarea>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      edit: false,
      type: null,
      note: null,
    };
  },

  computed: {
    modalTitle() {
      switch (this.type) {
        case "client":
          return "Notes sur l'application";
        case "manager":
          return "Notes sur le manager";
        case "coach":
          return "Notes du coach";
        default:
          return "Notes";
      }
    },

    checkPermission() {
      return this.hasPermission(this.$store.state.user, "CLIENTS_WRITE") && !this.client.deletedAt;
    },
  },

  methods: {
    editNotes() {
      this.type = "client";
      this.note = this.client.notes;
      this.edit = true;
    },

    editManagerNotes() {
      this.type = "manager";
      this.note = this.client.managerNotes;
      this.edit = true;
    },

    editCoachNotes() {
      this.type = "coach";
      this.note = this.client.coachNotes;
      this.edit = true;
    },

    async handleEditNotes() {
      let data = {
        email: this.client.email,
      };

      switch (this.type) {
        case "client":
          data = { ...data, notes: this.note };
          break;
        case "manager":
          data = { ...data, managerNotes: this.note };
          break;
        case "coach":
          data = { ...data, coachNotes: this.note };
          break;
      }

      await this.$api.put("/clients", data);

      switch (this.type) {
        case "client":
          this.client.notes = this.note;
          break;
        case "manager":
          this.client.managerNotes = this.note;
          break;
        case "coach":
          this.client.coachNotes = this.note;
          break;
      }

      this.edit = false;
    },
  },
};
</script>
