<template>
  <div class="card">
    <div class="card-header"><i class="icon-wallet"></i> Factures</div>
    <div class="card-block">
      <template v-if="merchants.length > 0">
        <dl
          class="row"
          v-for="(item, index) in coach.coach.credit"
          :key="index"
        >
          <dt class="col-sm-6">{{ merchantName(item.merchantId) }}</dt>
          <dd class="col-sm-6">
            <strong>{{ formatPrice(item.credit, true) }}</strong>
            <button
              class="btn btn-success btn-sm"
              @click="handleDisburse(item.merchantId)"
              v-if="coach.disbursement && item.credit > 0"
            >
              <i class="fa fa-download"></i>
              Retrait
            </button>
          </dd>
        </dl>
      </template>

      <disbursements
        :disbursements-data="disbursed"
        v-if="disbursed.length > 0"
      />
    </div>

    <modal
      title="Ajouter une facture"
      v-model="disburseNow"
      cancelText="Annuler"
      okText="Valider"
      @ok="handleDisburseNow()"
      effect="fade/zoom"
      large
    >
      <div slot="modal-header" class="modal-header">
        <h4 class="modal-title" v-if="disburseNow && disburseMerchant">
          {{ merchantName(disburseMerchant) }}
        </h4>
      </div>
      <table class="table" v-if="disburseNow && sessions && disburseMerchant">
        <thead>
          <th>Description</th>
          <th>Montant HT</th>
        </thead>
        <tbody>
          <tr
            v-for="(session, index) in sessionsMerchant(disburseMerchant)"
            :key="index"
          >
            <td>
              Séance effectuée le {{ formatDateTime(session.scheduledAt)
              }}<br />
              <small v-if="session.client && session.client.company"
                ><u>Société</u> : {{ session.client.company }}</small
              >
              <small v-else
                >Client :
                <span v-if="session.client">{{ session.client.name }}</span
                >, Pack : {{ session.counter }}/{{
                  session.order.session
                }}</small
              >
            </td>
            <td>{{ formatPrice(session.credit, true) }}</td>
          </tr>
        </tbody>
        <tfoot>
          <th>Total HT</th>
          <th>
            {{ formatPrice(coach.coach.credit[disburseMerchant].credit, true) }}
          </th>
        </tfoot>
      </table>
      <div class="form-group" v-if="coach">
        <dropzone
          :url="apiUploads()"
          :headers="apiAuthorization()"
          acceptedFileTypes="application/pdf"
          id="invoice"
          v-on:vdropzone-success="invoiceUploaded"
        >
          <div class="dz-default dz-message">
            <span
              ><i class="icon-cloud-upload"></i><br />Glissez votre facture au
              format PDF ici
            </span>
          </div>
        </dropzone>
        <div>
          <label>
            <input type="checkbox" v-model="cgu" />
            Je certifie ne pas dépendre économiquement de Just Coaching. Just
            Coaching ne constitue qu'une partie de ma clientèle.
          </label>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";

import Modal from "vue-strap/src/Modal";
import Dropzone from "vue2-dropzone";
import Disbursements from "../components/Disbursements";

export default {
  components: {
    Modal,
    Dropzone,
    Disbursements,
  },

  props: {
    coach: {
      type: Object,
      required: true,
    },

    sessions: {
      type: Array,
      default: () => [],
    },

    disbursed: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      disburseNow: false,
      disburseMerchant: null,
      invoiceId: null,
      cgu: false,
    };
  },

  methods: {
    apiUploads() {
      return axios.defaults.baseURL + "uploads";
    },

    apiAuthorization() {
      return {
        Authorization: localStorage.getItem("token"),
      };
    },

    merchantName(merchantId) {
      let merchant = this.merchants.find((m) => m.id === merchantId);

      if (merchant) {
        return merchant.name;
      }
    },

    handleDisburse(merchantId) {
      this.disburseMerchant = merchantId;
      this.disburseNow = true;
    },

    invoiceUploaded(file, response) {
      this.invoiceId = response.id;
    },

    handleDisburseNow() {
      if (!this.cgu) {
        this.$errorToast(
          "Vous devez certifier ne pas dépendre économiquement de Just Coaching"
        );

        return;
      }

      if (!this.invoiceId) {
        this.$errorToast("Vous devez uploader une facture au format PDF");

        return;
      }

      this.$api
        .put(
          "/app/disbursement/" + this.disburseMerchant + "/" + this.invoiceId
        )
        .then(() => {
          window.location.reload(true);
        });
    },

    sessionsMerchant(merchantId) {
      return this.sessions.filter((s) => s.order.merchantId === merchantId);
    },
  },
};
</script>
