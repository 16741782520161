<template>
  <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe
      :src="
        `https://player.vimeo.com/video/${videoId}?h=67fa9223c4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
      "
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      :title="title"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true
    },

    title: {
      type: String,
      default: "Just Coaching"
    }
  }
};
</script>
