<template>
  <div class="card">
    <div class="card-header">
      <i class="icon-camrecorder"></i> Cours en solo
      <button
        v-if="!oldSession"
        class="btn btn-sm btn-primary float-right"
        @click="fetchSoloSessions(true)"
      >
        Voir les cours passés
      </button>
      <button
        v-if="oldSession"
        class="btn btn-sm btn-primary float-right"
        @click="fetchSoloSessions()"
      >
        Voir les cours à venir
      </button>
    </div>
    <div class="card-block">
      <CoachSessionSoloTabs
        style="max-width: 100%; overflow-x: scroll"
        :sessions="sessions"
        :old-session="oldSession"
      />
    </div>
  </div>
</template>

<script>
import CoachSessionSoloTabs from "../components/CoachSessionSoloTabs";

export default {
  components: {
    CoachSessionSoloTabs,
  },

  data() {
    return {
      oldSession: false,
      sessions: [],
    };
  },

  mounted() {
    this.fetchSoloSessions(this.oldSession);
  },

  methods: {
    async fetchSoloSessions(old) {
      if (old) {
        this.oldSession = true;
      } else {
        this.oldSession = false;
      }

      const { data } = await this.$api.get(
        `/users/live-sessions?solo=true${old ? "&old=true" : ""}`
      );

      this.sessions = data;
    },
  },
};
</script>
