<template>
  <div>
    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="col-md-4">
            <label v-if="edit">Titre</label>
            <input
              v-if="edit"
              class="form-control"
              v-model="stat.libelle"
              placeholder="Nom de la statistique"
              type="text"
            />
            <h2 v-else>{{ stat.libelle }}</h2>
          </div>
          <div class="form-group col-md-4" v-if="edit && !stat.id">
            <label>Date de début</label>
            <datepicker
              v-model="form.begin"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-md-4" v-if="edit && !stat.id">
            <label>Date de fin</label>
            <datepicker
              v-model="form.end"
              format="dd.MM.yyyy"
              language="fr"
              input-class="form-control"
            ></datepicker>
          </div>
          <div class="form-group col-md-4 mt-1" v-if="edit && !stat.id">
            <label>Commercials</label>
            <multiselect
              v-model="form.commercials"
              :options="form.commercialsOptions"
              :multiple="true"
              label="label"
              track-by="value"
            />
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-md-6">
            <span v-if="!stat.id">
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="$emit('updateForm', form)"
              >
                <i class="icon-eye" aria-hidden="true"></i>&nbsp; Visualiser
              </button>
            </span>
            <p v-else-if="!edit || (edit && stat.id)">
              {{ displayPeriod() }}
            </p>
          </div>
          <div class="col-md-6">
            <span v-if="!stat.id">
              <button
                type="button"
                class="btn btn-sm btn-primary float-right"
                @click="create()"
              >
                <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Créer
              </button>
            </span>
            <button
              v-if="stat.id && !toggleEdit"
              type="button"
              class="btn btn-sm btn-danger float-right"
              @click="remove(stat.id)"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>&nbsp; Supprimer
            </button>
            <span v-else-if="edit && stat.id">
              <button
                type="button"
                class="btn btn-sm btn-primary float-right"
                @click="editAction()"
              >
                <i class="fa fa-check-square-o" aria-hidden="true"></i>&nbsp;
                Enregistrer
              </button>
              <button
                type="button"
                class="btn btn-sm btn-default float-right mr-1"
                @click="toggleEdit = false"
              >
                <i class="fa fa-times" aria-hidden="true"></i>&nbsp; Annuler
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-block">
        <div class="row">
          <div class="col-md-12">
            <div class="alert alert-info" role="alert">
              Toutes les statistiques correspondent aux valeurs à l'instant de
              la génération. Si un commercial à traité 20 prospects dans la
              période mais que 10 sont passé clients, alors il y aura 10
              prospects
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row nopadding m-0">
            <div class="col-md-3">
              <div class="commercials-tab"></div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('prospect')"
              >
                <p>Nombre de prospect</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('sendDiscovery')"
              >
                <p>Découverte envoyées</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('sendDiscoveryPercent')"
              >
                <p>% de découverte envoyées</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('payedDiscovery')"
              >
                <p>Découverte payées</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('unpayedDiscovery')"
              >
                <p>Découverte non payés</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('payedDiscoveryPercent')"
              >
                <p>% de découverte payés</p>
              </div>
              <div
                class="commercials-tab discovery-pack"
                v-tooltip="getTooltip('discoveryCa')"
              >
                <p>CA découverte</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('conversionsPack')"
              >
                <p>Pack conversions</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('packMoyenConversion')"
              >
                <p>Pack moyen conversion</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('conversionSignaturePercent')"
              >
                <p>% signature conversion</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('conversionsRate')"
              >
                <p>Taux conversion</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('conversionCa')"
              >
                <p>CA Conversion</p>
              </div>
              <div
                class="commercials-tab conversion-pack"
                v-tooltip="getTooltip('conversionPaiement')"
              >
                <p>Paiement Conversion</p>
              </div>
              <div
                class="commercials-tab fidelisation-pack"
                v-tooltip="getTooltip('fidelisationPack')"
              >
                <p>Pack fidélisation</p>
              </div>
              <div
                class="commercials-tab fidelisation-pack"
                v-tooltip="getTooltip('packMoyenFidelisation')"
              >
                <p>Pack moyen fidelisation</p>
              </div>
              <div
                class="commercials-tab fidelisation-pack"
                v-tooltip="getTooltip('fidelisationSignaturePercent')"
              >
                <p>% de signature fidelisation</p>
              </div>
              <div
                class="commercials-tab fidelisation-pack"
                v-tooltip="getTooltip('fidelisationCa')"
              >
                <p>CA fidélisation</p>
              </div>
              <div
                class="commercials-tab fidelisation-pack"
                v-tooltip="getTooltip('fidelisationPaiement')"
              >
                <p>Paiement fidélisation</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('totalCa')"
              >
                <p>CA total</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('totalPaiement')"
              >
                <p>Paiement total</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('averageBasket')"
              >
                <p>Panier moyen</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('callTime')"
              >
                <p>Heure d'appel</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('caByHours')"
              >
                <p>CA heure</p>
              </div>
              <div
                class="commercials-tab other"
                v-tooltip="getTooltip('nbNotice')"
              >
                <p>NB avis clients</p>
              </div>
              <div
                class="commercials-tab unpayed-pack"
                v-tooltip="getTooltip('unpayedPacks')"
              >
                <p>Pack impayés</p>
              </div>
              <div
                class="commercials-tab unpayed-pack"
                v-tooltip="getTooltip('unpayedPacksCa')"
              >
                <p>Ca pack impayés</p>
              </div>
            </div>
            <div class="col-md-9">
              <div class="row flex-row flex-nowrap overflow-x nopadding m-0">
                <StatsCommercialsData
                  v-for="(commercial, index) in stat.statCommercialDatas"
                  :key="index"
                  :id="commercial.id"
                  :edit="edit"
                  :commercial-stat="commercial"
                  @update-commercial="updateCommercial"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment-timezone";
import Multiselect from "vue-multiselect";
import StatsCommercialsData from "../components/StatsCommercialsData";

export default {
  components: {
    Datepicker,
    Multiselect,
    StatsCommercialsData,
  },

  props: {
    defaultStat: {
      type: Object,
    },
    form: {
      type: Object,
    },
  },

  data() {
    return {
      toggleEdit: false,
      tooltips: {
        prospect: "Nombre de prospect créé dans la periode.",
        sendDiscovery:
          "Donnée calculé (Découverte payées + Découverte non payés).",
        sendDiscoveryPercent:
          "Donnée calculé (100 * Découverte envoyées) / Nombre de prospect).",
        payedDiscovery:
          "Nombre de commande découverte payé ou partiellement payé, créé dans la période.",
        unpayedDiscovery:
          "Nombre de commande découverte en attente de paiement ou non payé, créé dans la période.",
        payedDiscoveryPercent:
          "Donnée calculé (100 * Découverte payées) / Découverte envoyées.",
        discoveryCa:
          "Addition du montant de toutes les commandes découverte payé ou partiellement payé.",
        conversionsPack:
          "Nombre de pack conversion payé ou partiellement payé, créé dans la période.",
        packMoyenConversion:
          "Moyenne du nombre de sessions par pack conversion payé ou partiellement payé, créé dans la période.",
        conversionSignaturePercent:
          "Donnée calculé ((100 * Nb packs du menu conversion en status 'Converti') / (Nb pack conversion du menu conversion en status 'Perdu' ou 'Perdu autre' + Nb packs du menu conversion en status 'Converti')).",
        conversionsRate:
          "Donnée calculé (100 * Nombre de pack conversion payé ou partiellement payé) / Nombre de commande découverte payé ou partiellement payé.",
        conversionCa:
          "Addition du CA de toutes les commandes conversion payé ou partiellement payé, créé dans la période.",
        conversionPaiement:
          "Addition de tous les paiements capturé dans la liste des packs conversions payé ou partiellement payé.",
        fidelisationPack:
          "Nombre de pack fidelisation payé ou partiellement payé, créé dans la période.",
        packMoyenFidelisation:
          "Moyenne du nombre de sessions par pack conversion payé ou partiellement payé, créé dans la période.",
        fidelisationSignaturePercent:
          "Donnée calculé ((100 * Nb packs du menu fidelisation en status 'Fidélisé') / (Nb packs du menu fidelisation en status 'Perdu' ou 'Perdu autre' + Nb packs du menu fidélisation en status 'Fidélisé')).",
        fidelisationCa:
          "Addition du CA de toutes les commandes fidélisation payé ou partiellement payé, créé dans la période.",
        fidelisationPaiement:
          "Addition de tous les paiements capturé dans la liste des packs fidélisations payé ou partiellement payé.",
        totalCa:
          "Donnée calculé. Addition des champs 'CA découverte' + 'CA Conversion' + 'CA Fidélisation'.",
        totalPaiement:
          "Donnée calculé. Addition des champs 'Paiement Conversion' + 'Paiement Fidélisation'.",
        averageBasket:
          "Donnée calculé. Champs 'CA total' / (Packs conversions + Packs fidélisations).",
        callTime: "Temps passé en heure d'appel, remonté de ringover.",
        caByHours: "Donnée calculé. 'CA Total' / 'Heure d'appel'",
        nbNotice:
          "Nombre d'avis client déposé dans la période. (Peut importe la date de création du prospect / client)",
        unpayedPacks:
          "Nombre de commandes (Tous type confondu) ayant pour status 'En attente' ou 'Impayé', créé dans la période.",
        unpayedPacksCa:
          "Addition du CA de toutes les commandes impayé, créé dans la période.",
      },
    };
  },

  computed: {
    stat() {
      return this.defaultStat;
    },

    edit() {
      return !this.stat?.id || this.toggleEdit;
    },
  },

  methods: {
    create() {
      this.$emit("create", {
        stat: this.stat,
      });
    },

    remove(id) {
      this.$emit("remove", id);
    },

    reset() {
      this.$emit("reset");
    },

    editAction() {
      this.toggleEdit = false;
      this.$emit("update", this.stat);
    },

    displayPeriod() {
      const begin = moment(this.stat.begin).locale("fr").format("DD MMMM YYYY");
      const end = moment(this.stat.end).locale("fr").format("DD MMMM YYYY");

      return `Période du ${begin} au ${end}`;
    },

    updateCommercial(commercial) {
      if (!this.stat || !this.stat.statCommercialDatas) {
        return;
      }

      const objIndex = this.stat.statCommercialDatas.findIndex(
        (obj) => obj.email == commercial.email
      );

      if (objIndex) {
        this.stat.statCommercialDatas[objIndex] = commercial;
      }
    },

    getTooltip(key) {
      return {
        content: this.tooltips[key],
        placement: "left",
      };
    },
  },

  watch: {
    stat() {
      this.toggleEdit = false;
    },
  },
};
</script>

<style type="text/css">
.commercials-tab {
  display: flex;
  align-items: center;
  min-height: 50px;
  border: 1px solid black;
  padding: 0 10px;
}

.commercials-tab > p {
  margin-bottom: 0;
}

.nopadding > div[class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.discovery-pack {
  background-color: #36ad70;
  color: white;
}

.conversion-pack {
  background-color: #255dc8;
  color: white;
}

.fidelisation-pack {
  background-color: #18bdb4;
  color: white;
}

.unpayed-pack {
  background-color: #ff7f7f;
  color: white;
}

.other {
  background-color: #d3d3d3;
}

.row > .col-xs-3 {
  display: flex;
  flex: 0 0 25%;
  max-width: 25%;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-row {
  display: flex;
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.well {
  min-height: 300px;
  width: 100%;
}

.overflow-x {
  overflow-x: auto;
}
</style>
