import moment from "moment-timezone";
import { mapState } from "vuex";

const USER_ROLE_DEFAULT = 0;
const USER_ROLE_ADMIN = 1 << 0;
const USER_ROLE_MEMBER = 1 << 1;

export default {
  computed: mapState({
    conceptsList: (state) => state.concepts,
    merchants: (state) => state.merchants,
  }),
  methods: {
    formatDate: function (date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY");
      }
    },
    displayMinutes(minutes) {
      return minutes < 10 ? `0${minutes}` : `${minutes}`;
    },
    formatDateTime: function (date) {
      if (date) {
        return moment(date).format("DD.MM.YYYY à HH:mm");
      }
    },
    formatDateDays: function (date) {
      var diff = -moment().diff(date, "days");

      if (diff === 1) {
        return "demain";
      } else if (diff > 1) {
        return "dans " + diff + " jours";
      } else if (diff < 0) {
        return "il y a " + -diff + " jours";
      }

      return "aujourd'hui";
    },
    getDay(date) {
      const options = { weekday: "long" };

      return new Date(date).toLocaleString("fr-FR", options);
    },
    formatPrice: function (price, allowZero, noCurrency) {
      if (price || allowZero) {
        return (
          (parseFloat(price) / 100)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (noCurrency ? "" : "€")
        );
      }
    },
    formatPriceInt: function (price, allowZero, noCurrency) {
      if (price || allowZero) {
        return (
          (parseFloat(price) / 100)
            .toFixed()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (noCurrency ? "" : "€")
        );
      }
    },

    formatFormPrice(value) {
      return value.replace(/ /g, "").replace(",", ".");
    },
    formatPack: function (session) {
      if (session) {
        return session + " séance" + (session > 1 ? "s" : "");
      }
    },
    formatAddress(obj) {
      if (obj.address) {
        return obj.address + ", " + obj.city;
      } else if (obj.city && obj.zipcode) {
        return obj.city + ", " + obj.zipcode;
      } else if (obj.city) {
        return obj.city;
      }
    },
    nl2br(text) {
      return text.replace(/\n/g, "<br>");
    },
    clientsActive(clients) {
      return clients.filter((client) => {
        if (!client.active) {
          return false;
        }

        if (client.orders) {
          if (client.orders.find((o) => o.completed === false)) {
            return true;
          }

          return false;
        }

        return true;
      });
    },
    indexToDay(index) {
      switch (index) {
        case 0:
          return "Lundi";
        case 1:
          return "Mardi";
        case 2:
          return "Mercredi";
        case 3:
          return "Jeudi";
        case 4:
          return "Vendredi";
        case 5:
          return "Samedi";
        case 6:
          return "Dimanche";
      }
    },
    metaLabel(key) {
      switch (key) {
        case "birthDate":
          return "Date de naissance";
        case "size":
          return "Taille";
        case "weight":
          return "Poids";
        case "targets":
          return "Objectifs";
        case "summary":
          return "Informations complémentaires";
      }
    },
    formatDuration(mins, display) {
      if (mins < 60 && display) {
        return mins + "min";
      }

      const h = (mins / 60) | 0;
      const m = mins % 60 | 0;

      return moment.utc().hours(h).minutes(m).format("HH:mm");
    },
    conceptLogo(name, concepts) {
      const conceptsL = concepts || this.conceptsList;
      if (conceptsL) {
        let concept = conceptsL.find((c) => c.name === name);

        if (concept && concept.icon) {
          return (
            '<img src="data:' +
            concept.icon.mimetype +
            ";base64," +
            concept.icon.data +
            '" height="16" width="16" />'
          );
        }
      }
    },
    statusLabel(key) {
      switch (key) {
        case "active":
          return "Actif";
        case "inactive":
          return "Inactif";
        case "suspended":
          return "Bloqué";
        case "recruiting":
          return "Recrutement";
        case "contact":
          return "Contacté";
        case "recruited":
          return "Recruté";
      }
    },
    getRole(name) {
      switch (name) {
        case "default":
          return USER_ROLE_DEFAULT;
        case "admin":
          return USER_ROLE_ADMIN;
        case "member":
          return USER_ROLE_MEMBER;
        default:
          return 0;
      }
    },
    isTeam(user) {
      if (!user) return false;
      return this.hasRole(user, "admin") || this.hasRole(user, "member");
    },
    isAdmin(user) {
      if (!user) return false;
      return this.hasRole(user, "admin");
    },
    hasRole(user, name) {
      if (!user) return false;
      return !!(user.role & this.getRole(name));
    },
    hasPermission(user, permission) {
      if (!user) return false;
      const isAdmin = !!(user.role & USER_ROLE_ADMIN);
      const isMemberAndHasPermission =
        !!(user.role & USER_ROLE_MEMBER) &&
        user.permissions &&
        user.permissions.includes(permission);
      return isAdmin || isMemberAndHasPermission || false;
    },

    arrayEquals(a, b) {
      return (
        Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index])
      );
    },

    merchantName(merchantId) {
      let merchant = this.merchants.find((m) => m.id === merchantId);

      if (merchant) {
        return merchant.name;
      }
    },

    groupHasFeature(group, feature) {
      if (!group) return false;
      return group.features?.includes(feature);
    },
  },
};
