<template>
  <footer class="app-footer">
    Just Coaching &copy; 2017 -
    {{ new Date().getFullYear() }}
    : v1.14.0
  </footer>
</template>
<script>
export default {
  name: "footer",
};
</script>
