<template>
  <div class="card payments-custom-wrapper">
    <div class="card-header">
      <i class="fa fa-align-justify"></i> Liste des commandes

      <div v-tooltip="tooltipRequiredField" class="float-right">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="newOrder()"
          v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
          :disabled="!checkRequiredFields()"
        >
          <i class="fa fa-plus"></i>&nbsp; Ajouter
        </button>
      </div>
      <div
        v-if="client.prospect && client.prospect.isProspect"
        v-tooltip="tooltipRequiredField"
        class="float-right"
      >
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click="newDiscoveryOrder()"
          v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
          :disabled="!checkRequiredFields()"
        >
          <i class="fa fa-envelope"></i>&nbsp; Séance découverte
        </button>
      </div>
    </div>
    <div class="card-block">
      <div class="w-100 mw-100 overflow-auto">
        <Orders
          :orders="orders"
          :client="false"
          :notes="true"
          :state="true"
          :showFrequency="true"
          :showInvoiceFile="true"
          :showIntervenants="true"
        />
      </div>
    </div>

    <modal
      title="Création de commande bloquée"
      v-model="unpaidCommandModal"
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <p>
          Ce client cumule trop d’impayés, tu ne peux pas effectuer cette
          action.
          <br />
          Contacte ton responsable afin de l’en informer.
        </p>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          @click="unpaidCommandModal = false"
        >
          Fermer
        </button>
      </div>
    </modal>

    <modal
      title="Création de commande découverte"
      v-model="newDiscoveryOrderModal"
      large
      effect="fade/zoom"
    >
      <div slot="modal-body" class="modal-body">
        <div>
          <p>Type de la commande découverte ?</p>
          <el-switch
            v-model="discoveryType"
            inactive-text="Visio"
            active-text="Company"
            size="mini"
            @change="changeDiscoveryType($event)"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
        </div>
        <div v-if="discoveryType === true && client.urssafInscription && client.urssafInscription.valid" class="mt-3">
          <p>
            La commande découverte contient-elle un paiement "Avance immédiate"
            ?
          </p>
          <el-switch
            v-model="isDiscoverAi"
            inactive-text="Stripe"
            active-text="Avance immédiate"
            size="mini"
            :disabled="!hasPermission($store.state.user, 'ORDERS_WRITE')"
          />
        </div>
      </div>
      <div
        slot="modal-footer"
        class="modal-footer d-flex justify-content-between"
      >
        <div class="alert alert-warning">
          ℹ️ Attention, cette action envoie un email avec un lien de paiement au
          client.
        </div>
        <div>
          <button
            type="button"
            class="btn btn-default"
            @click="newDiscoveryOrderModal = false"
          >
            Fermer
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="createNewDiscoveryOrder()"
          >
            Envoyer
          </button>
        </div>
      </div>
    </modal>

    <OrderModal
      :modalShow="openModal"
      :client="client"
      :requiredSessionUserCount="requiredSessionUserCount"
      @close="reloadOrders"
      @closeModal="openModal = false"
    />
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

import Orders from "../components/Orders";
import OrderModal from "../components/OrderModal";

export default {
  components: {
    Orders,
    Modal,
    OrderModal,
  },

  props: {
    client: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      orders: [],
      unpaidOrdersQuantity: 0,
      unpaidCommandModal: false,
      openModal: false,
      newDiscoveryOrderModal: false,
      isDiscoverAi: false,
      discoveryType: true,
      createOrderLoader: false
    };
  },

  computed: {
    tooltipRequiredField() {
      return `Les champs Prénom / Nom / Sexe / Téléphone / Date de naissance /
      Adresse / Lieux des séances et Personnes / séances / Disponibilités /
      Objectifs et Details : sont obligatoires pour ajouter une commande.`;
    },

    newOrderBlocked() {
      return (
        this.unpaidOrdersQuantity &&
        this.clientHasConceptAffectedByOrderBlocking &&
        !this.hasPermission(
          this.$store.state.user,
          "BLOCKED_IMPAYED_ORDERS_READ"
        )
      );
    },

    clientHasConceptAffectedByOrderBlocking() {
      const conceptsNames = [
        "justcoaching.fr",
        "Body Trainers",
        "luxurycoaching.fr",
      ];

      return this.client.concepts?.some((concept) =>
        conceptsNames.includes(concept.name)
      );
    },

    requiredSessionUserCount() {
      return this.client.groups?.some((group) =>
        group.features?.includes("SESSION_USER_COUNT")
      );
    },
  },

  mounted() {
    this.getUnpaidOrdersQuantity();

    this.orders = this.client.orders;
  },

  methods: {
    async getUnpaidOrdersQuantity() {
      try {
        const { data } = await this.$api.get(
          "/clients/orders/unpaid-quantity",
          {
            params: {
              client: this.client.email,
            },
          }
        );

        this.unpaidOrdersQuantity = data.unpaidQuantity;
      } catch (e) {
        this.unpaidOrdersQuantity = 0;
      }
    },

    checkRequiredFields() {
      if (this.isAdmin(this.$store.state.user)) {
        return true;
      }

      return (
        this.client.firstName &&
        this.client.lastName &&
        this.client.gender &&
        this.client.phone &&
        this.client.birthDate &&
        this.client.preferedPlace &&
        this.client.zipcode &&
        this.client.disponibility &&
        this.client.objectif &&
        this.client.clientNotes
      );
    },

    newOrder() {
      if (this.newOrderBlocked) {
        this.unpaidCommandModal = true;

        return;
      }

      this.openModal = true;
    },

    newDiscoveryOrder() {
      this.newDiscoveryOrderModal = true;
    },

    async createNewDiscoveryOrder() {
      if (this.createOrderLoader) {
        return;
      }

      this.createOrderLoader = true;

      const { data } = await this.$api.post("orders/discovery", {
        clientEmail: this.client.email,
        isAi: this.isDiscoverAi,
        type: this.discoveryType ? "COMP" : "VISIO",
      });

      const route = this.$router.resolve({
        name: "order-details",
        params: {
          id: data.id,
        },
      });

      this.createOrderLoader = true;
      window.open(route.href, "_blank");
      window.location.reload();
    },

    reloadOrders() {
      this.openModal = false;
      this.$emit("newOrder");
    },

    changeDiscoveryType(value) {
      if (value === false) {
        this.isDiscoverAi = false;
      }
    },
  },
};
</script>
