<template>
  <div>
    <table class="table table-condensed">
      <thead>
        <tr>
          <th>Prénom</th>
          <th>Nom de famille</th>
          <th>Identifiant</th>
          <th class="text-center">Rôle</th>
          <th class="text-center">Référent commercial</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.firstName }}</td>
          <td>{{ user.lastName }}</td>
          <td>
            <a href="#" @click="openMemberDetails(user)">{{ user.email }}</a>
          </td>
          <td class="text-center">
            <span style="margin-right: 20px">Équipier</span>
            <el-switch
              :value="hasRole(user, 'admin')"
              @change="toggleRole(user, $event)"
              :disabled="!hasRole($store.state.user, 'admin')"
            />
            <span style="margin-left: 20px">Admin</span>
          </td>
          <td class="text-center">
            <el-switch
              :value="user.isLeader"
              @change="toggleLeader(user, $event)"
              :disabled="!hasRole($store.state.user, 'admin')"
              v-if="user.commercial"
            />
          </td>
          <td>
            <button
              class="btn btn-primary btn-sm"
              @click="archived(user)"
              v-if="
                hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE') &&
                !user.archived
              "
            >
              Archiver
            </button>
            <button
              class="btn btn-primary btn-sm"
              @click="unarchived(user)"
              v-if="
                hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE') &&
                user.archived === true
              "
            >
              Désarchiver
            </button>
            <button
              class="btn btn-danger btn-sm ml-1"
              @click="setDefaultRole(user)"
              v-if="hasPermission($store.state.user, 'SETTINGS_TEAM_WRITE')"
            >
              Supprimer
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {
    setDefaultRole(user) {
      this.$confirm({
        message: "Attention, cela supprime un membre de l'équipe !",
        onConfirm: async () => {
          await this.$api.put("/users", {
            email: user.email,
            role: this.getRole("default"),
            permissions: [],
          });

          this.$emit("loadUser");
        },
      });
    },

    archived(user) {
      this.$confirm({
        message: "Archiver l'utilisateur ?",
        onConfirm: async () => {
          await this.$api.put("/users", {
            email: user.email,
            archived: true,
            permissions: [],
          });

          this.$emit("loadUser");
        },
      });
    },

    unarchived(user) {
      this.$confirm({
        message: "Désarchiver l'utilisateur ?",
        onConfirm: async () => {
          await this.$api.put("/users", {
            email: user.email,
            archived: false,
          });

          this.$emit("loadUser");
        },
      });
    },

    openMemberDetails(user) {
      this.$router.push({
        name: "teammembers-details",
        params: {
          id: user.id,
        },
      });
    },
  },
};
</script>
