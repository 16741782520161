<template>
  <ul v-click-outside="reset" class="nav navbar-nav ml-auto">
    <div class="search-container">
      <input
        v-on:click="onSearchDebounced"
        v-on:keyup="onSearchDebounced"
        v-on:keyup.enter="redirectToSearch"
        v-model="query"
        class="form-control form-search"
        type="text"
        placeholder="Rechercher ..."
      />
      <div class="search-results" v-if="displayResults">
        <div
          v-for="(item, index) of clients.slice(0, 5)"
          :key="`clients-${index}`"
          class="search-result clients search-result-client"
          @click="goToClient(item.client)"
        >
          <div v-html="item.label"></div>
          <div class="result-type result-type-client">Client</div>
        </div>
        <div
          v-for="(item, index) of coachs.slice(0, 5)"
          :key="`coachs-${index}`"
          class="search-result coachs search-result-coach"
          @click="goToCoach(item.coach)"
        >
          <div v-html="item.label"></div>
          <div class="result-type result-type-coach">Coach</div>
        </div>
        <div
          v-for="item of payers.slice(0, 5)"
          :key="`payers-${item.id}`"
          class="search-result payer search-result-payer"
          @click="goToOrder(item.orderId)"
        >
          <div v-html="item.label"></div>
          <div class="result-type result-type-payer">Payeur</div>
        </div>
      </div>
    </div>

    <multiselect
      v-model="searchCategorie"
      :options="searchCategories"
      :multiple="true"
      group-values="options"
      group-label="subcategory"
      :group-select="true"
      @input="searchChangeFilter"
      @select="(opt) => optionSelected(opt)"
      label="label"
      track-by="label"
      placeholder="Rechercher par"
      style="min-width: 300px; margin-left: 0.5rem"
      class="custom-multiselect d-none d-lg-block"
    >
    </multiselect>
  </ul>
</template>

<script>
import ClickOutside from "vue-click-outside";
import debounce from "lodash.debounce";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      query: "",
      terms: null,
      searchCategorie: [
        { label: "Prénom", value: "firstName" },
        { label: "Nom", value: "lastName" },
        { label: "Société", value: "company" },
        { label: "Email", value: "email" },
      ],
      clients: [],
      coachs: [],
      payers: [],
      onSearchDebounced: debounce(() => {
        if (this.query && this.query.length > 2) {
          this.terms = this.query.split(" ");
          this.terms = this.terms.filter((term) => term !== "");

          this.startSearch(this.terms);
        } else if (this.query && this.query.length < 2) {
          this.reset();
        }
      }, 300),
    };
  },

  created() {
    this.query = this.$route.query.q;

    if (this.$route.query.filterBy) {
      let filterBy = Array.isArray(this.$route.query.filterBy)
        ? this.$route.query.filterBy
        : [this.$route.query.filterBy];

      if (filterBy.length > 0) {
        this.searchCategorie = [];

        filterBy.map((searchCategorie) => {
          switch (searchCategorie) {
            case "firstName":
              this.searchCategorie.push({
                label: "Prénom",
                value: "firstName",
              });
              break;
            case "lastName":
              this.searchCategorie.push({ label: "Nom", value: "lastName" });
              break;
            case "company":
              this.searchCategorie.push({ label: "Société", value: "company" });
              break;
            case "address":
              this.searchCategorie.push({ label: "Adresse", value: "address" });
              break;
            case "zipcode":
              this.searchCategorie.push({
                label: "Code postal",
                value: "zipcode",
              });
              break;
            case "city":
              this.searchCategorie.push({ label: "Ville", value: "city" });
              break;
            case "phone":
              this.searchCategorie.push({ label: "Téléphone", value: "phone" });
              break;
            case "email":
              this.searchCategorie.push({ label: "Email", value: "email" });
              break;
            case "status":
              this.searchCategorie.push({ label: "Statut", value: "status" });
              break;
            case "subscription":
              this.searchCategorie.push({
                label: "Abonnement",
                value: "subscription",
              });
              break;
            case "engagement":
              this.searchCategorie.push({
                label: "Engagement",
                value: "engagement",
              });
              break;
            case "billingFirstName":
              this.searchCategorie.push({
                label: "Prénom (facturation)",
                value: "billingFirstName",
              });
              break;
            case "billingLastName":
              this.searchCategorie.push({
                label: "Nom (facturation)",
                value: "billingLastName",
              });
              break;
            case "billingCompany":
              this.searchCategorie.push({
                label: "Société (facturation)",
                value: "billingCompany",
              });
              break;
            case "billingAddress":
              this.searchCategorie.push({
                label: "Adresse (facturation)",
                value: "billingAddress",
              });
              break;
            case "billingZipcode":
              this.searchCategorie.push({
                label: "Code postal (facturation)",
                value: "billingZipcode",
              });
              break;
            case "billingCity":
              this.searchCategorie.push({
                label: "Ville (facturation)",
                value: "billingCity",
              });
              break;
            case "billingPhone":
              this.searchCategorie.push({
                label: "Téléphone (facturation)",
                value: "billingPhone",
              });
              break;
            case "billingEmail":
              this.searchCategorie.push({
                label: "Email (facturation)",
                value: "billingEmail",
              });
              break;
          }
        });
      }
    }
  },

  computed: {
    displayResults() {
      return (
        this.clients.length > 0 ||
        this.coachs.length > 0 ||
        this.payers.length > 0
      );
    },

    searchCategories() {
      return [
        {
          subcategory: "Utilisateur",
          options: [
            { label: "Prénom", value: "firstName" },
            { label: "Nom", value: "lastName" },
            { label: "Société", value: "company" },
            { label: "Adresse", value: "address" },
            { label: "Code postal", value: "zipcode" },
            { label: "Ville", value: "city" },
            { label: "Téléphone", value: "phone" },
            { label: "Email", value: "email" },
            { label: "Statut", value: "status" },
            { label: "Abonnement", value: "subscription" },
            { label: "Engagement", value: "engagement" },
          ],
        },
        {
          subcategory: "Facturation",
          options: [
            { label: "Prénom (facturation)", value: "billingFirstName" },
            { label: "Nom (facturation)", value: "billingLastName" },
            { label: "Société (facturation)", value: "billingCompany" },
            { label: "Adresse (facturation)", value: "billingAddress" },
            { label: "Code postal (facturation)", value: "billingZipcode" },
            { label: "Ville (facturation)", value: "billingCity" },
            { label: "Téléphone (facturation)", value: "billingPhone" },
            { label: "Email (facturation)", value: "billingEmail" },
          ],
        },
      ];
    },
  },

  methods: {
    redirectToSearch() {
      if (this.query && this.query.length > 2) {
        this.terms = this.query.split(" ");
        this.terms = this.terms.filter((term) => term !== "");

        this.$router.push({
          name: "search",
          query: {
            terms: this.terms,
            filterBy: this.searchCategorie.map((searchCategorie) => {
              return searchCategorie.value;
            }),
          },
        });

        window.location.reload(true);
      }
    },

    reset() {
      if (this.displayResults) {
        this.query = "";
        this.terms = null;
        this.clients = [];
        this.coachs = [];
        this.payers = [];
      }
    },

    async startSearch(terms) {
      if (this.hasPermission(this.$store.state.user, "CLIENTS_READ")) {
        this.searchClients(terms);
      }

      if (this.hasPermission(this.$store.state.user, "USERS_MENU_READ")) {
        this.searchCoachs(terms);
      }

      if (
        this.hasPermission(this.$store.state.user, "PAYMENTS_READ") ||
        this.hasPermission(this.$store.state.user, "ORDERS_READ")
      ) {
        this.searchPayers(terms);
      }
    },

    async searchClients(terms) {
      this.clients = [];

      const { data } = await this.$api.post("/clients/search", {
        terms,
        filterBy: this.searchCategorie.map((searchCategorie) => {
          return searchCategorie.value;
        }),
      });

      if (data.length) {
        this.clients = data.map((item) => {
          let label = `${item.lastName ? item.lastName : ""} ${
            item.firstName ? item.firstName : ""
          } ${item.email}`;

          this.terms.forEach((term) => {
            label = label
              .toLowerCase()
              .replace(term, `<strong>${term}</strong>`);
          });

          return { label, client: item };
        });
      }
    },

    async searchCoachs(terms) {
      this.coachs = [];

      const { data } = await this.$api.post("/users/coachs/search", {
        terms,
        filterBy: this.searchCategorie.map((searchCategorie) => {
          return searchCategorie.value;
        }),
      });

      if (data.length) {
        this.coachs = data.map((item) => {
          let label = `${item.coach ? `${item.coach.name}` : ""} ${item.email}`;

          this.terms.forEach((term) => {
            label = label
              .toLowerCase()
              .replace(term, `<strong>${term}</strong>`);
          });

          return { label, coach: item };
        });
      }
    },

    async searchPayers(terms) {
      this.payers = [];

      const { data } = await this.$api.get(
        `/payments/payers/search?terms=${terms}`
      );

      if (data.length) {
        for (const p of data) {
          if (
            this.payers.filter(
              (d) => d.payer === p.payer && d.client === p.client
            ).length !== 0
          ) {
            continue;
          }

          let label = `${p.payer} (${p.client})`;

          this.terms.forEach((term) => {
            label = label.replace(term, `<strong>${term}</strong>`);
          });

          this.payers.push({ ...p, label });
        }
      }
    },

    goToClient(client) {
      this.$router.push({
        name: "client-details",
        params: {
          id: client.id,
        },
      });

      this.reset();
    },

    goToCoach(coach) {
      this.$router.push({
        name: "user-details",
        params: {
          id: coach.id,
        },
      });

      this.reset();
    },

    goToOrder(id) {
      this.$router.push({
        name: "order-details",
        params: {
          id,
        },
      });

      this.reset();
    },

    searchChangeFilter() {
      if (this.terms && this.terms.length > 0) {
        this.startSearch(this.terms);
      } else {
        this.reset();
      }
    },

    optionSelected(option) {
      if (
        option.value === "address" ||
        option.value === "billingAddress" ||
        option.value === "phone" ||
        option.value === "billingPhone"
      ) {
        this.searchCategorie.length = 0;
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  position: relative;

  .form-search {
    height: 37px;
    min-width: 300px;
  }

  .search-results {
    position: absolute;
    background-color: white;
    min-width: 100%;
    border: 1px solid #e1e6ef;
    border-top: inherit;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  }

  .search-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
      background-color: #20a8d8;
      color: #fff;
    }

    .result-type {
      margin-left: 0.5rem;
      font-weight: bold;
    }
  }

  .search-result-client {
    background-color: #d1fae5;
    .result-type-client {
      color: #10b981;
    }
  }

  .search-result-coach {
    background-color: #fef3c7;
    .result-type-coach {
      color: #f59e0b;
    }
  }

  .search-result-payer {
    background-color: rgb(203, 213, 225);
    .result-type-payer {
      color: rgb(51, 65, 85);
    }
  }
}
</style>
