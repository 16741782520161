<template>
  <div>
    <ClientsProspectsFilters
      type="prospect"
      :commercials="commercials"
      :concepts="concepts"
      :form="searchForm"
      @applyFilters="applyFilters"
      @addNew="showNewClientModal = true"
    />

    <loader :isVisible="isLoading" />

    <div class="card">
      <div class="card-block">
        <tabs v-model="activeTab" @input="changeTab">
          <tab :header="headerAll">
            <Prospect
              :prospects="prospects.all"
              :prospectsTotal="totals.all"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerWaiting">
            <Prospect
              :prospects="prospects.enAttente"
              :prospectsTotal="totals.enAttente"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerLost">
            <Prospect
              :prospects="prospects.perdu"
              :prospectsTotal="totals.perdu"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerTreated">
            <Prospect
              :prospects="prospects.treated"
              :prospectsTotal="totals.treated"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerConverted">
            <Prospect
              :prospects="prospects.converti"
              :prospectsTotal="totals.converti"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
          <tab :header="headerAlreadyKnown">
            <Prospect
              :prospects="prospects.alreadyKnown"
              :prospectsTotal="totals.alreadyKnown"
              :page="page"
              :rows="limit"
              :reRender="paginationReRender"
              @changePage="changePage"
              @showComment="showCommentMethod"
              @changeProspectType="changeProspectType"
              @archiveProspect="archiveProspectModalShow"
              @editCommercials="editCommercials"
              @sortChange="sortChange"
            />
          </tab>
        </tabs>
      </div>
    </div>

    <modal
      v-if="modalUpdateProspectType.prospectInfos"
      :title="updateProspectTypeModalTitle"
      v-model="modalUpdateProspectType.show"
      :backdrop="false"
      cancelText="Annuler"
      okText="Convertir"
      @ok="updateProspect()"
      effect="fade/zoom"
    >
      Êtes vous sûr de vouloir convertir le statut en
      <span
        v-if="modalUpdateProspectType.prospectInfos.type === 'converti'"
        class="font-weight-bold"
        >'Converti'</span
      >
      <span
        v-if="modalUpdateProspectType.prospectInfos.type === 'treated'"
        class="font-weight-bold"
        >'Traité (en attente de paiement)'</span
      >
      <span
        v-else-if="modalUpdateProspectType.prospectInfos.type === 'perdu'"
        class="font-weight-bold"
        >'Perdu'</span
      >
      <span
        v-else-if="modalUpdateProspectType.prospectInfos.type === 'en-attente'"
        class="font-weight-bold"
        >'En attente'</span
      >
      <div
        class="row"
        v-if="modalUpdateProspectType.prospectInfos.type === 'perdu'"
      >
        <div class="col-12 mt-1">
          Raison :
          <v-select
            :options="prospectLostReasons"
            v-model="modalUpdateProspectType.prospectInfos.lostReason"
          >
          </v-select>
        </div>

        <div class="col-12 mt-1">
          Perdu.e le :
          <datepicker
            v-model="modalUpdateProspectType.prospectInfos.lostAt"
            format="dd.MM.yyyy"
            language="fr"
            input-class="form-control"
            :disabled="modalUpdateProspectType.disabledDates"
          />
        </div>
      </div>
    </modal>

    <modal
      title="Archiver un prospect"
      v-model="archiveProspectModal.display"
      cancelText="Fermer"
      okText="Archiver"
      @ok="archiveProspect()"
      effect="fade/zoom"
    >
      <div v-if="archiveProspectModal.prospect">
        <p>
          Voulez-vous vraiment archiver le prospect
          <strong
            >{{ archiveProspectModal.prospect.firstName }}
            {{ archiveProspectModal.prospect.lastName }}
          </strong>
          ?
        </p>
      </div>
    </modal>

    <NewClientModal
      :show="showNewClientModal"
      :default="form"
      :commercials="commercials"
      :concepts="concepts"
      @close="showNewClientModal = false"
    />

    <ClientNoteModal
      :show="modalUpdateNote.show"
      :client="modalUpdateNote.prospect"
      @close="modalUpdateNote.show = false"
    />

    <ClientCommercialEditModal
      :show="modalEditClientCommercial.show"
      :client="modalEditClientCommercial.prospect"
      :commercials="commercials"
      @close="modalEditClientCommercial.show = false"
    />
  </div>
</template>

<script>
import Tabs from "vue-strap/src/Tabs";
import Tab from "vue-strap/src/Tab";
import Modal from "vue-strap/src/Modal";
import Datepicker from "vuejs-datepicker";

import Prospect from "../components/Prospect";
import ClientsProspectsFilters from "../components/ClientsProspectsFilters";
import { mapGetters } from "vuex";
import Loader from "../components/Loader";
import NewClientModal from "../components/NewClientModal";
import ClientNoteModal from "../components/ClientNoteModal";
import ClientCommercialEditModal from "../components/ClientCommercialEditModal";

import moment from "moment-timezone";

export default {
  components: {
    Tabs,
    Tab,
    Modal,
    Prospect,
    ClientsProspectsFilters,
    Loader,
    Datepicker,
    NewClientModal,
    ClientNoteModal,
    ClientCommercialEditModal,
  },

  data() {
    return {
      isLoading: false,
      activeTab: 0,
      searchForm: {
        begin: undefined,
        end: undefined,
        archives: "hidden",
        name: undefined,
        concepts: [],
        commercials: [],
        sortBy: "createdAt",
        sortOrder: "descending",
        aiStatus: "all",
      },
      prospects: {
        all: [],
        enAttente: [],
        perdu: [],
        treated: [],
        converti: [],
        alreadyKnown: [],
      },
      totals: {
        all: 0,
        enAttente: 0,
        perdu: 0,
        treated: 0,
        converti: 0,
        alreadyKnown: 0,
      },
      page: 1,
      offset: 0,
      limit: 15,
      countProspects: true,
      getTotal: true,
      paginationReRender: true,
      form: {
        email: undefined,
        password: undefined,
        commercial: undefined,
        prospect: {
          isProspect: true,
          type: "en-attente",
        },
      },
      commercials: [],
      concepts: [],
      modalUpdateProspectType: {
        show: false,
        prospectInfos: null,
        disabledDates: {
          to: moment().toDate(),
          from: moment().toDate(),
        },
      },
      archiveProspectModal: {
        display: false,
        prospect: null,
      },
      showNewClientModal: false,
      modalUpdateNote: {
        show: false,
        prospect: null,
      },
      modalEditClientCommercial: {
        show: false,
        prospect: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      lostReasons: "getLostReasons",
    }),

    prospectLostReasons() {
      return this.lostReasons
        .filter((reason) => !reason.clientsOnly)
        .map(({ label, value }) => ({ label, value }));
    },

    prospectStatus() {
      switch (this.activeTab) {
        case 0:
          return "all";
        case 1:
          return "en-attente";
        case 2:
          return "perdu";
        case 3:
          return "treated";
        case 4:
          return "converti";
        case 5:
          return "already-known";
        default:
          return null;
      }
    },

    headerAll() {
      return `Tous ${
        this.totals.all !== 0 ? "(" + this.totals.all + ")" : ""
      }`;
    },

    headerWaiting() {
      return `En Attente ${this.totals.enAttente !== 0 ? "(" + this.totals.enAttente + ")" : ""}`;
    },

    headerLost() {
      return `Perdu ${
        this.totals.perdu !== 0 ? "(" + this.totals.perdu + ")" : ""
      }`;
    },

    headerTreated() {
      return `Traité (en attente de paiement) ${this.totals.treated !== 0 ? "(" + this.totals.treated + ")" : ""}`;
    },

    headerConverted() {
      return `Converti ${
        this.totals.converti !== 0
          ? "(" + this.totals.converti + ")"
          : ""
      }`;
    },

    headerAlreadyKnown() {
      return `Déjà connus ${
        this.totals.alreadyKnown !== 0
          ? "(" + this.totals.alreadyKnown + ")"
          : ""
      }`;
    },

    updateProspectTypeModalTitle() {
      switch (this.modalUpdateProspectType.prospectInfos.type) {
        case "converti":
          return "Passer le statut en converti";
        case "treated":
          return "Passer le statut en traité";
        case "perdu":
          return "Passer le statut en perdu";
        case "en-attente":
          return "Passer le statut en attente";
        default:
          return "";
      }
    },
  },

  async created() {
    await this.getCommercials();
    await this.getConcepts();

    let activeTab = this.$route.query.tab ?? sessionStorage.getItem("prospectsTab");

    if (activeTab && this.activeTab !== parseInt(activeTab)) {
      this.activeTab = parseInt(activeTab);
    }

    if (this.$route.query.page !== undefined) {
      this.page = parseInt(this.$route.query.page);
      this.offset = this.limit * this.page - this.limit;

      if (this.$route.query.count) {
        this.countProspects = true;
      }

      let commercials = []
      let concepts = []

      if (this.$route.query.commercials) {
        commercials = Array.isArray(this.$route.query.commercials) ? this.$route.query.commercials : [this.$route.query.commercials]
      }

      if (this.$route.query.concepts) {
        concepts = Array.isArray(this.$route.query.concepts) ? this.$route.query.concepts : [this.$route.query.concepts]
      }

      this.searchForm = {
        archives: this.$route.query.archives || 'hidden',
        concepts: concepts.map(c => this.concepts.find(concept => concept.name === c)).filter(c => c),
        commercials: commercials.map(c => this.commercials.find(commercial => commercial.value === c)).filter(c => c),
        sortBy: this.$route.query.sortBy || 'createdAt',
        sortOrder: this.$route.query.sortOrder || 'descending',
        name: this.$route.query.name || '',
        aiStatus: this.$route.query.aiStatus || 'all',
        prospectStatus: this.$route.query.prospectStatus || 'all',
        begin: this.$route.query.begin ? moment(this.$route.query.begin).toDate() : undefined,
        end: this.$route.query.end ? moment(this.$route.query.end).toDate() : undefined,
      };

      this.getProspect(this.offset)
    }
  },

  methods: {
    async changeTab(index) {
      if (this.$route.query.page !== undefined) {
        await this.togglePaginationReRender();

        this.checkCurrentSorting();
      }

      sessionStorage.setItem("prospectsTab", index);
    },

    async getProspect(offset) {
      this.isLoading = true;

      const { data } = await this.$api.get("/prospects", {
        params: {
          filter: {
            ...this.searchForm,
            commercials: this.searchForm.commercials.map((c) => c.value),
            concepts: this.searchForm.concepts.map((c) => c.name),
            prospectType: this.prospectStatus,
          },
          offset,
          limit: this.limit,
          count: this.countProspects,
          currentTotals: this.totals,
          getTotal: this.getTotal,
        },
      });

      this.totals = data.totals;

      switch (this.prospectStatus) {
        case "all":
          this.prospects.all = data.data;
          break;
        case "en-attente":
          this.prospects.enAttente = data.data;
          break;
        case "perdu":
          this.prospects.perdu = data.data;
          break;
        case "treated":
          this.prospects.treated = data.data;
          break;
        case "converti":
          this.prospects.converti = data.data;
          break;
        case "already-known":
          this.prospects.alreadyKnown = data.data;
          break;
      }

      this.countProspects = false;
      this.getTotal = false;

      this.isLoading = false;
    },

    async getCommercials() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get("/users/commercials/search");

        this.commercials = data.map((c) => ({
          value: c.email,
          label: c.commercial.pseudo,
          color: c.commercial.color,
          data: c,
        }));
      } catch (e) {
        this.commercials = [];
      } finally {
        this.isLoading = false;
      }
    },

    async getConcepts() {
      try {
        this.isLoading = true;

        const { data } = await this.$api.get(`/concepts`);

        this.concepts = data.map(({ name }) => ({ name }));
      } catch (e) {
        this.concepts = [];
      } finally {
        this.isLoading = false;
      }
    },

    showCommentMethod(prospect) {
      this.modalUpdateNote = {
        ...this.modalUpdateNote,
        prospect,
        show: true,
      };
    },

    editCommercials(prospect) {
      this.modalEditClientCommercial = {
        ...this.modalUpdateNote,
        prospect,
        show: true,
      };
    },

    changeProspectType(prospectInfos) {
      this.modalUpdateProspectType.show = true;
      this.modalUpdateProspectType.prospectInfos = prospectInfos;

      if (prospectInfos.type === "perdu") {
        this.modalUpdateProspectType.prospectInfos.lostAt = moment().toDate();

        if (this.isAdmin(this.$store.state.user)) {
          this.modalUpdateProspectType.disabledDates = {
            to: moment(prospectInfos.prospect.createdAt)
              .endOf("day")
              .subtract(1, "days")
              .toDate(),
            from: moment().endOf("day").toDate(),
          };
        }
      }
    },

    async updateProspect() {
      await this.$api
        .put("/clients", {
          email: this.modalUpdateProspectType.prospectInfos.prospect.email,
          prospect: {
            type: this.modalUpdateProspectType.prospectInfos.type,
            lostReason:
              this.modalUpdateProspectType.prospectInfos.lostReason &&
              this.modalUpdateProspectType.prospectInfos.lostReason.value,
            lostAt: this.modalUpdateProspectType.prospectInfos.lostAt,
          },
        })
        .catch((err) => {
          console.log(err);
        });

      this.filterProspectsAfterChangingStatus(
        this.modalUpdateProspectType.prospectInfos.prospect,
        this.modalUpdateProspectType.prospectInfos.type
      );

      this.modalUpdateProspectType.show = false;
    },

    filterProspectsAfterChangingStatus(updatedProspect, newStatus) {
      switch (this.prospectStatus) {
        case "all":
        case "already-known":
          switch (updatedProspect.prospect.type) {
            case "treated":
              this.totals.treated--;
              break;
            case "en-attente":
              this.totals.enAttente--;
              break;
          }

          updatedProspect.prospect.type = newStatus;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "en-attente":
          this.prospects.enAttente = this.prospects.enAttente.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.enAttente--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "perdu":
          this.prospects.perdu = this.prospects.perdu.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.perdu--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "treated":
          this.prospects.treated = this.prospects.treated.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.treated--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
        case "converti":
          this.prospects.converti = this.prospects.converti.filter(
            (prospect) => prospect.id !== updatedProspect.id
          );
          this.totals.converti--;

          switch (newStatus) {
            case "treated":
              this.totals.treated++;
              break;
            case "en-attente":
              this.totals.enAttente++;
              break;
          }

          break;
      }
    },

    archiveProspectModalShow(prospect) {
      this.archiveProspectModal.display = true;
      this.archiveProspectModal.prospect = prospect;
    },

    async archiveProspect() {
      await this.$api.put("/clients", {
        email: this.archiveProspectModal.prospect.email,
        archived: true,
      });

      this.archiveProspectModal.prospect.archived = true;
      this.archiveProspectModal.display = false;
      this.countProspects = true;
    },

    async applyFilters(form) {
      this.searchForm = {
        ...this.searchForm,
        ...form,
      };

      await this.changePage(1, true);
      await this.togglePaginationReRender();
    },

    changePage(page, count = false) {
      this.$router.push({ name: 'prospects', query: { 
        page, 
        count, 
        tab: this.activeTab, 
        ...this.searchForm,
        begin: this.searchForm.begin ? moment(this.searchForm.begin).format('YYYY-MM-DD') : undefined,
        end: this.searchForm.end ? moment(this.searchForm.end).format('YYYY-MM-DD') : undefined,
        concepts: this.searchForm.concepts.map((c) => c.name),
        commercials: this.searchForm.commercials.map((c) => c.value),
        prospectStatus: this.prospectStatus
      } })
    },

    togglePaginationReRender() {
      this.paginationReRender = !this.paginationReRender;
    },

    sortChange({ order, prop }) {
      this.searchForm.sortBy = prop;
      this.searchForm.sortOrder = order;

      this.changePage(1, true);
      this.togglePaginationReRender();
      this.checkCurrentSorting();
    },

    getTabHeaderText(prop) {
      switch (prop) {
        case "prospect.type":
          return "Statut";
        case "firstName":
          return "Prénom";
        case "lastName":
          return "Nom";
        case "email":
          return "Email";
        case "phone":
          return "Téléphone";
        case "zipcode":
          return "CP";
        case "city":
          return "Ville";
        case "createdAt":
          return "Créé le";
        case "prospect.concept":
          return "Concept";
        default:
          return null;
      }
    },

    checkCurrentSorting() {
      const currentSortingHeader = this.getTabHeaderText(
        this.searchForm.sortBy
      );
      const allSortingTabs = document.querySelectorAll(".tab-pane.active th");
      const currentSortingTab = Array.from(allSortingTabs).find(
        (sortingTab) => {
          return sortingTab.textContent === currentSortingHeader;
        }
      );

      if (document.querySelector(".tab-pane.active th.descending")) {
        const displayedSortingTab = document.querySelector(
          ".tab-pane.active th.descending"
        );

        displayedSortingTab?.classList.remove("descending");
      } else if (document.querySelector(".tab-pane.active th.ascending")) {
        const displayedSortingTab = document.querySelector(
          ".tab-pane.active th.ascending"
        );
        displayedSortingTab?.classList.remove("ascending");
      }

      if (this.searchForm.sortOrder) {
        currentSortingTab?.classList.add(this.searchForm.sortOrder);
      }
    },
  },
};
</script>

<style lang="scss">
.modal-dialog {
  margin-top: 5rem !important;
}
</style>
