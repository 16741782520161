<template>
  <div class="card">
    <div class="card-block">
      <div v-if="order.appNotes" v-html="nl2br(order.appNotes)" />
    </div>
    <div class="card-block card-inverse card-info">
      <div v-if="order.notes" v-html="nl2br(order.notes)" />
    </div>
    <div class="card-footer">
      <button
        v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
        type="button"
        class="btn btn-sm btn-secondary"
        @click="editAppNotes = true"
      >
        <i class="fa fa-pencil" /> Note application
      </button>
      <button
        v-if="hasPermission($store.state.user, 'ORDERS_WRITE')"
        type="button"
        class="btn btn-sm btn-info"
        @click="editNotes = true"
      >
        <i class="fa fa-pencil" /> Note manager
      </button>
    </div>

    <modal
      title="Notes sur l'application"
      v-model="editAppNotes"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditNotes"
      effect="fade/zoom"
    >
      <div class="form-group" v-if="order">
        <textarea
          v-model="order.appNotes"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
    </modal>

    <modal
      title="Notes sur le manager"
      v-model="editNotes"
      cancelText="Fermer"
      okText="Enregistrer"
      @ok="handleEditNotes"
      effect="fade/zoom"
    >
      <div class="form-group" v-if="order">
        <textarea
          v-model="order.notes"
          rows="4"
          class="form-control"
        ></textarea>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "vue-strap/src/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editAppNotes: false,
      editNotes: false,
    };
  },

  methods: {
    async handleEditNotes() {
      await this.$api.put(`/orders/${this.order.id}`, {
        notes: this.order.notes,
        appNotes: this.order.appNotes,
      });

      this.editNotes = false;
      this.editAppNotes = false;
    },
  },
};
</script>
