<template>
    <gmap-autocomplete
        id="geocoding-address" class="form-control" placeholder="Selectionner une adresse"
        :componentRestrictions="geocodeRestrictions"
        :selectFirstOnEnter="true" @place_changed="placeChangedHandler"
        :disabled="disableGmapfilter">
    </gmap-autocomplete>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBwheKRqS2Bm2c2dsbBl0KUX1f4CdybKgk',
    libraries: 'places'
  },
  installComponents: true
})

export default {
    props: {
        disableGmapfilter: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        markers () {
            return [{position: this.geoloc}]
        },
        geocodeRestrictions () {
            return {country: 'fr'}
        }
    },
    methods: {
        placeChangedHandler(place) {
            this.$emit('placeChangedHandler', place);
        }
    },
}
</script>
